<template>
  <div>
    <h2>{{ $t('camera') }}</h2>
      <v-container>
        <v-col cols="12" sm="12">
          <v-row>
            <v-col cols="12" sm="3">
              <date-time-picker :label="$t('from')" clearable="true" v-model="since"></date-time-picker>
            </v-col>
            <v-col cols="12" sm="3">
              <date-time-picker :label="$t('to')" clearable="true" v-model="until"></date-time-picker>
            </v-col>
            <v-col cols="12" sm="3">
              <input-with-sw-keyboard
                :label="$t('logs.filter')"
                :cleanAfterSent="cleanAfterSent"
                :uniqueInputName="logs.pathPattern"
                @onSendClick="getLog"
                layout="alphabetic"
                v-model='pathPattern'>
              </input-with-sw-keyboard>
            </v-col>
          </v-row>
        </v-col>
      </v-container>
      <v-container>
        <v-row>
          <v-col cols="12" sm="3">
            Start
          </v-col>
          <v-col cols="12" sm="3">
            Stop
          </v-col>
          <v-col cols="12" sm="2">
            Tram ID
          </v-col>
          <v-col cols="12" sm="2">
            Difference
          </v-col>
          <v-col cols="12" sm="2">
            Folder
          </v-col>
        </v-row>
        <v-row v-for="(row, index) of decoratedLogs" :key="index">
          <v-col cols="12" sm="3">
            {{ row[1] }}
          </v-col>
          <v-col cols="12" sm="3">
            {{ row[2] }}
          </v-col>
          <v-col cols="12" sm="2">
            {{ row[3] }}
          </v-col>
          <v-col cols="12" sm="2">
            <!-- {{ Math.round(median(row[4]) - Math.min(...row[4])) }} -->
            {{ Math.round(Math.max(...row[4]) - Math.min(...row[4])) }}
          </v-col>
          <v-col cols="12" sm="2">
            <span><a href="#" @click="showContent(row[5])">{{ row[5] }}</a></span>
          </v-col>
        </v-row>
      </v-container>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'

import DateTimePicker from '../components/date-time-picker.new.vue'

import InputWithSwKeyboard from '../../siteware-lib/src/components/input-with-sw-keyboard.vue'

// import { RpcMessage } from '../../../libshv-js/modules/rpcmessage'
import { RpcValue } from '../../libshv-js//modules/rpcvalue'
import moment from 'moment'
const readContentFull = (content) => {
  if (content instanceof RpcValue) {
    if (content.type === 10 || content.type === 11) {
      if (content.meta) {
        const convertedObject = readCponLikeObjectFull(content.value)
        return { meta: readCponLikeObjectFull(content.meta), ...convertedObject }
        // return readCponLikeObjectFull(content.value)
      } else {
        return readCponLikeObjectFull(content.value)
      }
    } else if (content.type === 7) {
      return content.value
    } else if (content.type === 9) {
      return content.value.map(value => readContentFull(value))
    } else if (content.type === 3 || content.type === 2 || content.type === 8 || content.type === 4 || content.type === 5) {
      return content.value
    } else if (content.type === 6) {
      return content.toInt()
    }
  } else if (typeof content === 'object') {
    return readCponLikeObjectFull(content)
  } else {
    return content
  }
}

const readCponLikeObjectFull = (cponObject) => {
  return Object.keys(cponObject).reduce((result, keyName) => {
    const content = readContentFull(cponObject[keyName])
    result[keyName] = content
    return result
  }, {})
}

export default Vue.extend({
  name: 'logs',
  props: {
    alarms: { type: Array },
    assetsData: { type: Object }
  },

  data () {
    return {
      headers: [
        { text: this.$i18n.t('logs.time'), value: 'timestamp' },
        { text: this.$i18n.t('logs.assetName'), value: 'assetName' },
        { text: this.$i18n.t('logs.name'), value: 'name' },
        { text: this.$i18n.t('logs.value'), value: 'value' },
        { text: this.$i18n.t('logs.domain'), value: 'domain' },
        { text: this.$i18n.t('logs.userId'), value: 'userId' }
      ],
      since: moment().subtract(1, 'hours'),
      until: moment(),
      pathPattern: '',
      cleanAfterSent: false,
      logs: [],
      folders: [],
      hiddenPagination: true
    }
  },

  computed: {
    ...mapState('tygroshka', [
      'shvPath'
    ]),
    decoratedLogs () {
      if (!this.logs) return []
      return this.logs.reduce((result, row) => {
        // console.log('row', row)
        const shvPath = row.value[1].value
        const shValue = row.value[2].value
        const sampleType = row.value[5].value
        if (shValue && (sampleType === 0 || sampleType === 2)) {
          const valuesArray = shValue.split(',')
          // timestamp: moment(row.value[0].value.epochMsec).format('DD.MM. YYYY - HH:mm:ss'),
          const startTimeValue = valuesArray[0] * 1000 - 60000
          const endTimeValue = valuesArray[valuesArray.length - 1] * 1000 + 60000
          // console.log('start time, end time', startTimeValue, endTimeValue)
          const foundFolder = this.folders.find(folder => {
            // console.log('comparing', startTimeValue, folder, endTimeValue)
            return folder > startTimeValue && folder < endTimeValue
          })
          // console.log('found folder', foundFolder)
          const startTime = moment(startTimeValue).utc().format('DD.MM YYYY - HH:mm:ss')
          const endTime = moment(endTimeValue).utc().format('DD.MM YYYY - HH:mm:ss')
          // console.log('start time, end time', startTime)
          const tramId = valuesArray[1]
          const measurements = valuesArray.slice(2, valuesArray.length - 1)

          // const convertedFolderTime = foundFolder ? moment(foundFolder).utc().format('DD.MM. YYYY - HH:mm:ss') : ''
          // const domain = row.value[4].value
          // const userId = row.value[6].value
          result.push([shvPath, startTime, endTime, tramId, measurements, foundFolder, sampleType])
        }
        return result
      }, [])
    }
  },

  components: {
    DateTimePicker,
    InputWithSwKeyboard
  },

  methods: {
    returnHeaders () {
      return this.headers
    },

    median (arr) {
      const mid = Math.floor(arr.length / 2)
      const nums = [...arr].sort((a, b) => a - b)
      return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2
    },

    // run ls on `shv/cze/prg/eline/camera/agent/sys/fs`
    // find proper folder depending on start time and end time.
    // read pictures and speedResult.json

    async getLog (pathPattern) {
      const systemRoot = this.shvPath.replace('shv', 'history')
      const formatDate = (time) => moment(time).utc().toISOString()
      console.log('trying to format since', formatDate(this.since))
      // const params = `{ "withPathsDict": false, "since":d"2020-04-01T06:12:21.380Z", "until":d"2020-04-01T07:05:21.380Z", "pathPattern": "" }`
      const params = `{
        "withPathsDict": false,
        "since":d"${formatDate(this.since)}",
        "until":d"${formatDate(this.until)}",
        "recordCountLimit": 1000,
        "pathPattern": "**/csvData/**"
      }`
      const cameraAddress = 'shv/agent/sys/fs'
      const result = await this.$tygroshka.service.sendMessage(systemRoot, 'getLog', params, 'gettingLog')
      const foldersList = await this.$tygroshka.service.sendMessage(cameraAddress, 'ls', undefined, 'gettingFolders')
      const folders = Object.values(readContentFull(foldersList.result().value))
        .reduce((result, folder) => {
          const reformatted = moment(folder, 'YYYY-MM-DD[T]HH-mm-ssZZ').unix()
          // console.log('check dates', reformatted, folder, moment.unix(reformatted).utc().format('YYYY-MM-DD[T]HH-mm-ssZZ'))
          result.push(reformatted * 1000)
          return result
        }, [])
      // console.log('final folders', folders)
      this.folders = folders
      this.logs = result.result().value
    },
    async showContent (unixTime) {
      const host = window.location.host
      const shvTimeFormat = moment(unixTime).utc().format('YYYY-MM-DD[T]HH-mm-ssZZ')
      // const url = 'https://www.seznam.cz'
      const url = `http://${host}/files/${shvTimeFormat}`
      console.log('link for this folder', url)
      window.open(url, '_blank', 'noreferrer')
    }
  }
})

</script>
