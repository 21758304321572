import * as moment from 'moment'
import { Asset } from '../types/general.types'
import store from '../services/tygroshka-store'

import { awaG3 } from './svg-behaviour/svg-g3-awa'
import { cabinetG3 } from './svg-behaviour/svg-g3-cabinet'
import { drrG3 } from './svg-behaviour/svg-g3-drr'
import { doorDisplay7G3 } from './svg-behaviour/svg-g3-door-display7'
import { gateG3 } from './svg-behaviour/svg-g3-gate'
import { heatingG3 } from './svg-behaviour/svg-g3-heating'
import { heatingRodG3 } from './svg-behaviour/svg-g3-heating-rod'
import { pdG3 } from './svg-behaviour/svg-g3-pd'
import { pmeG3 } from './svg-behaviour/svg-g3-pme'
import { pmmG3 } from './svg-behaviour/svg-g3-pmm'
import { routeG3 } from './svg-behaviour/svg-g3-route'
import { signalSymbolG3 } from './svg-behaviour/svg-g3-signal-symbol'
import { signalG3 } from './svg-behaviour/svg-g3-signal'
import { tcG3 } from './svg-behaviour/svg-g3-tc'
import { track } from './svg-behaviour/svg-g3-track'
import { vecomLoopG3 } from './svg-behaviour/svg-g3-vecom-loop'
import { vetraG3 } from './svg-behaviour/svg-g3-vetra'
import { zoneG3 } from './svg-behaviour/svg-g3-zone'
import { spieLoopG3 } from './svg-behaviour/svg-g3-spie-loop'
import { mpsG3 } from './svg-behaviour/svg-g3-mps'
import { requestorDigital } from './svg-behaviour/svg-g3-requestor-digital'
import { vecomControllerG3 } from './svg-behaviour/svg-g3-vecom-controller'
import { voltageFreeLamp } from './svg-behaviour/svg-g3-voltage-free-lamp'
import { disconRFID } from './svg-behaviour/svg-g3-discon-rfid'
import { disconDisconnector } from './svg-behaviour/svg-g3-discon-disconnector'
import { requestorData } from './svg-behaviour/svg-g3-requestor-data'
import { detector } from './svg-behaviour/svg-g3-detector'
import { cabinetRFID } from './svg-behaviour/svg-g3-cabinet-rfid'

import { zoneCha } from './svg-behaviour/cha/svg-zone'
import { heaterCha } from './svg-behaviour/cha/svg-heater'
import { pdCha } from './svg-behaviour/cha/svg-panto'
import { controlrelayCha } from './svg-behaviour/cha/svg-controlrelay'
import { elboxCha } from './svg-behaviour/cha/svg-elbox'
import { generalCha } from './svg-behaviour/cha/svg-general'
import { heatingCha } from './svg-behaviour/cha/svg-heating'
import { PMECha } from './svg-behaviour/cha/svg-pme'
import { kswCha } from './svg-behaviour/cha/svg-ksw'
import { routeCha } from './svg-behaviour/cha/svg-route'
import { ssCha } from './svg-behaviour/cha/svg-ss'
import { ppiCha } from './svg-behaviour/cha/svg-ppi'

export const updateSvg = (asset, assetsData?, alarms?, attribute?) => {
  const { shvPath, variables, deviceType } = asset

  console.log('ready to work', shvPath)
  // console.log('svgasset', asset)
  // console.log('svgassetsData', assetsData)
  // console.log('svgalarms', alarms)
  // console.log('svgattribute', attribute)
  // console.log('asset shvPath ', shvPath.replace(`/${attribute}`, ''))

  // const svg = document.getElementById('svg-container')
  console.log('updating SVG', variables)
  // const svgObject: any = document.getElementById('svg-object')
  const svg = document.getElementById('svg-container')
  if (!svg) {
    console.warn('[svg][missing container]')
    return
  }

  // DO NOT update SVG in case of N!A values from SHV
  const variableValue = variables.values().next().value
  if (variableValue === 'N!A') {
    return
  }

  // TODO solve replace for SHV v2
  const element = findElement(svg, shvPath.replace(`/${attribute}`, '')) // svg.querySelector(`[shvPath="${path}"]`)
  console.log('element', element, shvPath)
  if (!element) {
    console.warn('[svg][element not found]', shvPath)
    // const alarmIndicator = element.querySelector(`[chid="alarm"]`)
    return
  }

  let textNodes = []
  if (element.length > 0) {
    textNodes = Array.from(element).map(e => Array.from(e.getElementsByTagName('text'))).flat()
  } else {
    textNodes = Array.from(element.getElementsByTagName('text'))
  }

  textNodes.forEach(tn => {
    const tspan = tn.querySelector('tspan')
    if (tspan) {
      const translation = store.state.translations[`static.${tspan.textContent}`]
      tspan.textContent = translation || tspan.textContent
    }
  })

  const variablesWithoutParentType = {}
  variables.forEach((value, key) => {
    const newKey = key.replace(/.*\//, '')
    variablesWithoutParentType[newKey] = value
  })

  const boolVisibleElements = findVisibleBoolElement(element)
  solveVisibleBoolElement(boolVisibleElements, new Map(Object.entries(variablesWithoutParentType)))
  const regexDeviceType = /(?<deviceType>.*)@.*/
  const genericDeviceTypeResult = deviceType.match(regexDeviceType)
  const genericDeviceType = genericDeviceTypeResult ? genericDeviceTypeResult.groups.deviceType : deviceType
  console.log('deviceType', deviceType, genericDeviceTypeResult, genericDeviceType)

  const shvVisuType = getShvVisuType(element)

  const customProjectName = store.state.customProjectName

  console.log('custom project name', customProjectName)

  if (customProjectName === 'Cha') {
    const filteredElements = filterVisibleBoolElements(element)
    const chaDeviceTypesMap = {
      PPI: ppiCha,
      SS: ssCha,
      Route: routeCha,
      KSW: kswCha,
      PME: PMECha,
      Heating: heatingCha,
      General: generalCha,
      Elbox: elboxCha,
      Controlrelay: controlrelayCha,
      Zone: zoneCha,
      Heater: heaterCha,
      PD: pdCha
    }
    console.log('trying to find', genericDeviceType, chaDeviceTypesMap)
    const updates = [chaDeviceTypesMap[genericDeviceType]]

    console.log('cha special stuff', filteredElements, variablesWithoutParentType, assetsData, attribute, alarms, shvPath, updates)
    updates.forEach(update => {
      if (!update) {
        console.warn(`[svg][update function not implemented for ${genericDeviceType}]`)
        return
      }
      update(
        filteredElements,
        new Map(Object.entries(variablesWithoutParentType)),
        assetsData,
        { alarms: alarms, shvPath: shvPath, genericDeviceType },
        attribute
      )
    })
  } else {
    const updates = [deviceTypesMap[genericDeviceType]]

    const newUpdateFunctions = shvVisuType.map(visuType => {
      if (deviceTypesMap[visuType]) {
        return deviceTypesMap[visuType]
      }
      return null
    }).filter(u => u !== null)
    const newUpdates = newUpdateFunctions.length > 0 ? newUpdateFunctions : updates

    setAttribute(element, 'opacity', 1)
    if (variables.get('elementDisabled') === true) {
      setAttribute(element, 'opacity', 0.3)
    }
    // let systemPath = asset.systemPath
    // if (systemPath.slice(-1) === '/') {
    //   systemPath = systemPath.slice(0, -1)
    // }
    // TODO - solve with systemPathsRoots
    const systemAsset = assetsData.system
    const parentPlcDisconnected = systemAsset && systemAsset.variables.get('plcDisconnected')
    // console.log('system asset', systemAsset, parentPlcDisconnected)
    // console.log('solving element', shvPath)

    if (parentPlcDisconnected) {
      if (element.length > 0) {
        for (const e of element) {
          e.setAttribute('opacity', 0.1)
        }
      } else {
        element.setAttribute('opacity', 0.1)
      }
    } else {
      if (element.length > 0) {
        for (const e of element) {
          e.setAttribute('opacity', 1)
        }
      } else {
        element.setAttribute('opacity', 1)
      }
      // element.setAttribute('visibility', 'visible')
      const filteredElements = filterVisibleBoolElements(element)

      newUpdates.forEach(update => {
        if (!update) {
          console.warn(`[svg][update function not implemented for ${genericDeviceType}]`)
          return
        }
        update(
          filteredElements,
          new Map(Object.entries(variablesWithoutParentType)),
          assetsData,
          { alarms: alarms, shvPath: shvPath, genericDeviceType },
          attribute
        )
      })
    }
  }
}

const getShvVisuType = (element) => {
  if (element.length) {
    return Array.from(element).map((e: Element) => {
      return e.getAttribute('shvvisutype')
    })
  } else {
    return [element.getAttribute('shvvisutype')]
  }
}

const findVisibleBoolElement = (element) => {
  if (element.length) {
    return Array.from(element).filter((e: Element) => {
      return e.getAttribute('shvvisutype') === 'VisibleBoolProperty' // && e.getAttribute('shvpropertypath') === attribute
    })
  } else {
    return element.getAttribute('shvvisutype') === 'VisibleBoolProperty' ? [element] : null
  }
}

const filterVisibleBoolElements = (element) => {
  if (element.length) {
    const elements = Array.from(element).filter((e: Element) => {
      return e.getAttribute('shvvisutype') !== 'VisibleBoolProperty' // && e.getAttribute('shvpropertypath') === attribute
    })
    return elements.length === 1 ? elements[0] : elements
  } else {
    return element.getAttribute('shvvisutype') !== 'VisibleBoolProperty' ? element : null
  }
}

const solveVisibleBoolElement = (elements, variables) => {
  if (elements && elements.length >= 1) {
    // reads full shv property path - siteware is using only last part as a name of property
    // const shvPropertyPath = element.getAttribute('shvpropertypath')
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i]
      const trueLabel = getInnerElement(element, 'onTrue')
      const falseLabel = getInnerElement(element, 'onFalse')
      const attribute = element.getAttribute('shvpropertypath')
      const variableName = attribute.split('/').slice(-1)[0]
      const variableValue = variables.get(variableName)
      if (variableValue) {
        if (trueLabel) {
          setAttribute(trueLabel, 'visibility', 'visible')
        }
        if (falseLabel) {
          setAttribute(falseLabel, 'visibility', 'hidden')
        }
      } else {
        if (trueLabel) {
          setAttribute(trueLabel, 'visibility', 'hidden')
        }
        if (falseLabel) {
          setAttribute(falseLabel, 'visibility', 'visible')
        }
      }
    }
  }
}

const setAttribute = (element, attribute, value) => {
  if (element.length) {
    for (const el of element) {
      el.setAttribute(attribute, value)
    }
  } else {
    element.setAttribute(attribute, value)
  }
}

const findElement = (svg, shvPath) => {
  const path = shvPath.split('/')
  const chidCandidate: string[] = []

  while (path.length > 0) {
    const foundElement = svg.querySelectorAll(`[shvpath="${path.join('/')}"]`) // assetsByShvPath[shvPath.join('/')]

    if (foundElement.length > 1 && chidCandidate.length === 0) {
      return foundElement
    } else if (foundElement.length === 1 && chidCandidate.length === 0) {
      return foundElement[0]
    } else if (foundElement.length === 1 && chidCandidate.length > 0) {
      // console.log('WHAT TO DO HERE?', foundElement, chidCandidate)
      // TODO - solve how to do alarm on children and on /status like elements
      // return foundElement[0]
      const innerElement = getInnerElement(foundElement[0], chidCandidate.reverse().join('/'))
      return innerElement
    }
    const restOfPath: string = path.pop() || 'end'
    chidCandidate.push(restOfPath)
  }
  // client will get at least some info in attribute that device was not found
  return null
}

const requestMemory = (element, state, assetsData) => {
  const renderedHtml = generateGateTables(state, assetsData)
  const tablePlaceholder = getInnerElement(element, 'request-table')
  tablePlaceholder.innerHTML = renderedHtml // 'ahoj' // generateGateTables(assetsData).join('')
}

const generateGateTables = (state, assetsData) => {
  const rows = state.get('table').split('\n')
  const content = rows.slice(1, rows.length + 1)

  const gates: Asset[] = Object.values(assetsData).map(x => x as Asset).filter(asset => asset.deviceType === 'Gate')
  const routes: Asset[] = Object.values(assetsData).map(x => x as Asset).filter(asset => asset.deviceType === 'Route')

  let tableBody = ''
  const contentRowsNumber = content.length > 3 ? content.length : 3
  for (let i = 0; i < contentRowsNumber; i++) {
    const contentRow = content[i]
    if (contentRow) {
      const values = contentRow.split(',')
      const timestamp = moment(values[0] * 1000).format('DD.MM YYYY - HH:mm')
      const vehicleId = values[3] < 0 ? 'no ID' : values[3]
      // indexes for routes and gates are from 1
      const routeIndex = parseInt(values[5]) - 1
      const gateIndex = parseInt(values[6]) - 1
      tableBody += `<tr><td>${timestamp}</td><td>${vehicleId}</td><td>${routes[routeIndex].name}</td><td>${gates[gateIndex].name}</td></tr>`
    } else {
      tableBody += '<tr><td></td><td></td><td></td><td></td></tr>'
    }
  }

  return `
<table>
  <thead>
    <tr>
      <td>Time</td>
      <td>Tram ID</td>
      <td>Route</td>
      <td>Gate</td>
    </tr>
  </thead>
  <tbody>
    ${tableBody}
  </tbody>
</table>
  `
}

function svgPoint (element, x, y) {
  // const svgObject: any = document.getElementById('svg-object')
  const svg = document.getElementById('svg-container')
  const pt = svg.createSVGPoint()

  pt.x = x
  pt.y = y

  return pt.matrixTransform(element.getScreenCTM().inverse())
}

export const updateAlarmIndicator = (path, alarmsForAsset) => {
  const alarmStyle = store.state.styles.visuStyle.alarm
  const remove = alarmsForAsset.length === 0 ? 'remove' : false
  const severity = alarmsForAsset.filter(a => a.severity === 'error').length > 0 ? 'error' : 'warning'
  const alarmColors = severity === 'error' ? alarmStyle.error : alarmStyle.warning
  // const svgObject: any = document.getElementById('svg-object')
  const svg = document.getElementById('svg-container')
  if (!svg) {
    // console.warn('[svg][missing container]')
    return
  }

  const element = findElement(svg, path)
  if (!element) {
    // console.warn('[svg][element not found] for alarm', path)
    return
  }

  let alarmIndicators = []
  if (element.length > 1) {
    for (let i = 0; i < element.length; i++) {
      alarmIndicators.push(element[i].querySelector('[chid="alarm"]'))
    }
  } else {
    alarmIndicators = [element.querySelector('[chid="alarm"]')]
  }

  if (alarmIndicators.length > 0 && remove) {
    // alarmIndicator.setAttribute('visibility', 'hidden')
    for (const ai of alarmIndicators) {
      if (ai) {
        ai.remove()
      }
    }
  } else if (alarmIndicators.length > 0 && !remove) {
    for (const ai of alarmIndicators) {
      if (ai) {
        ai.remove()
      }
    }
    addingTriangle(svg, element, alarmColors, alarmStyle)
  } else if (!remove) {
    addingTriangle(svg, element, alarmColors, alarmStyle)
  }
}

const addingTriangle = (svg, element, alarmColors, alarmStyle) => {
  let moreElements
  if (element.length > 0) {
    moreElements = element
  } else {
    moreElements = [element]
  }

  for (const oneElement of moreElements) {
    const visibleBoolProperty = oneElement.getAttribute('shvvisutype') === 'VisibleBoolProperty'
    // do not show alarm triangles for visible bool properties
    if (visibleBoolProperty) {
      return
    }
    const labelAlarmPlaceholder = oneElement.querySelector('[shvalarmposition]')
    // console.log('alarm placeholder', labelAlarmPlaceholder)
    if (labelAlarmPlaceholder) {
      const direction = labelAlarmPlaceholder.getAttribute('shvalarmposition')
      const group = document.createElementNS('http://www.w3.org/2000/svg', 'g')
      const triangle = document.createElementNS('http://www.w3.org/2000/svg', 'polygon')
      const exclamationMark = document.createElementNS('http://www.w3.org/2000/svg', 'text')
      const boundingRect = labelAlarmPlaceholder.getBoundingClientRect()
      const originPoint = svgPoint(labelAlarmPlaceholder, boundingRect.x, boundingRect.bottom)
      const widthPoint = svgPoint(labelAlarmPlaceholder, boundingRect.x + boundingRect.width, boundingRect.bottom)
      const rescaledWidth = widthPoint.x - originPoint.x
      // console.log('calculated point', originPoint)
      // console.log('scale factor from width', rescaledWidth / boundingRect.width)
      const scaleFactor = rescaledWidth / boundingRect.width * alarmStyle.symbolScale

      const triangleSize = 15 * scaleFactor
      const triangleStrokeSize = 1 * scaleFactor
      const firstPointX = originPoint.x + rescaledWidth
      const firstPointY = originPoint.y - 2 * scaleFactor
      triangle.setAttribute('points', `${firstPointX}, ${firstPointY} ${firstPointX + triangleSize}, ${firstPointY} ${firstPointX + triangleSize / 2},${firstPointY - triangleSize}`)
      triangle.setAttribute('stroke-width', `${triangleStrokeSize}px`)
      triangle.setAttribute('stroke', alarmColors.outline)
      triangle.setAttribute('fill', alarmColors.background)
      const angle = labelAlarmPlaceholder.transform && labelAlarmPlaceholder.transform.baseVal[0] ? labelAlarmPlaceholder.transform.baseVal[0].angle : 0
      // if (labelAlarmPlaceholder.transform) {
      //   console.log('transform base val', labelAlarmPlaceholder.transform.baseVal[0])
      // }
      // const scale = labelAlarmPlaceholder.transform && labelAlarmPlaceholder.transform.baseVal[0] ? labelAlarmPlaceholder.transform.baseVal[0].scale : 0
      // exclamationMark.setAttribute('transform', `rotate(${angle})`)

      // console.log('scale factors in play', scaleFactor, alarmStyle.symbolScale, boundingRect.width)
      exclamationMark.setAttribute('x', firstPointX + 6 * scaleFactor)
      exclamationMark.setAttribute('y', firstPointY - 2 * scaleFactor)
      const fontSize = 10 * scaleFactor
      const strokeWidth = 0.6 * scaleFactor
      exclamationMark.setAttribute('font-size', `${fontSize}px`)
      exclamationMark.setAttribute('stroke-width', `${strokeWidth}px`)
      exclamationMark.setAttribute('stroke', 'black')
      exclamationMark.textContent = '!'
      group.setAttribute('chid', 'alarm')
      group.setAttribute('fill-opacity', 1)
      group.setAttribute('opacity', 1)
      group.appendChild(triangle)
      group.appendChild(exclamationMark)
      // group.setAttribute('transform', `rotate(${angle})`)
      const parentTextNode = labelAlarmPlaceholder.parentNode
      if (parentTextNode.transform && parentTextNode.transform.baseVal[0]) {
        const matrix = parentTextNode.transform.baseVal[0].matrix
        const transform = svg.createSVGTransformFromMatrix(matrix)
        group.transform.baseVal.initialize(transform)
      }
      if (labelAlarmPlaceholder.transform && labelAlarmPlaceholder.transform.baseVal[0]) {
        const matrix = labelAlarmPlaceholder.transform.baseVal[0].matrix
        const transform = svg.createSVGTransformFromMatrix(matrix)
        group.transform.baseVal.initialize(transform)
      }

      // const fullMatrix = labelAlarmPlaceholder.getScreenCTM()
      // console.log('full matrxi baby', fullMatrix)
      // const transformFromMatrix = svg.createSVGTransformFromMatrix(fullMatrix)
      // console.log('new transform', transformFromMatrix)
      // group.transform.baseVal.initialize(transformFromMatrix)
      // group.transform.baseVal[0].setMatrix(fullMatrix)
      // triangle.matrixTransform(labelAlarmPlaceholder.getScreenCTM().inverse())
      // return pt.matrixTransform(element.getScreenCTM().inverse())
      // svgLibGroup.scale(-1)
      // group.setAttribute('transform', labelAlarmPlaceholder.transform.baseVal)

      oneElement.prepend(group)
    } else {
      // console.log('no placeholder available for element', element)
    }
  }
}

const deviceTypesMap = {
  RequestMemory: requestMemory,
  MPS_G3: mpsG3,
  AWA_G3: awaG3,
  Route_G3: routeG3,
  DRRLoop_G3: drrG3,
  DRRTransceiver_G3: drrG3,
  Cabinet_G3: cabinetG3,
  DisconCabinet: cabinetG3,
  DoorDisplay7_G3: doorDisplay7G3,
  SignalSymbol_G3: signalSymbolG3,
  Signal_G3: signalG3,
  PD_G3: pdG3,
  TC_G3: tcG3,
  Gate_G3: gateG3,
  PME_G3: pmeG3,
  PMM_G3: pmmG3,
  RequestorDigital: requestorDigital,
  VecomController_G3: vecomControllerG3,
  VecomLoop_G3: vecomLoopG3,
  SPIELoop_G3: spieLoopG3,
  Vetra_G3: vetraG3,
  Zone_G3: zoneG3,
  Heating_G3: heatingG3,
  HeatingRod_G3: heatingRodG3,
  DisconVoltageFreeLamp: voltageFreeLamp,
  DisconRfId: disconRFID,
  DisconDisconnector: disconDisconnector,
  Track: track,
  RequestorData: requestorData,
  Detector: detector,
  CabinetRFID_G3: cabinetRFID
}

// helpers

const getInnerElement = (element: any, name: any) => {
  if (element.length) {
    return getInnerElementInArray([...element], name)
  } else {
    const path = `[chid='${name}']`
    const el = element.querySelector(path)
    if (!el) {
      // console.warn('[svg][missing element]', path)
    }
    return el
  }
}

const getInnerElementInArray = (elements: any, name: any) => {
  const elementsArray = Array.from(elements)
  const innerElement = elementsArray.map((e: Element) => e.querySelector(`[chid="${name}"]`)).filter(e => e)
  if (innerElement.length === 1) {
    return innerElement[0]
  } else if (innerElement.length > 1) {
    console.warn('[svg][more elements with same chid]', elements, name)
    return innerElement
  } else if (innerElement.length === 0) {
    // console.warn('[svg][no element found]', elements, name)
  }
}
