<template>
  <v-text-field
    :label="label"
    single-line
    :autofocus="autofocus"
    @input="onInputChange"
    @focus="activateKeyboard()"
    :type="computedInputType"
    :append-icon="appendIcon"
    @click:append="show = !show"
    :value="valueForActiveInput">
    <template slot="append-outer">
      <v-icon class="mr-1" v-if="inRowEdit" @click="onCancelClick()">mdi-window-close</v-icon>
      <v-icon @click="onSendClick()">mdi-send</v-icon>
    </template>
  </v-text-field>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'input-with-sw-keyboard',
  props: {
    uniqueInputName: String,
    cleanAfterSent: Boolean,
    label: String,
    inRowEdit: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    inputType: {
      type: String,
      default: 'text'
    },
    layout: String
  },
  data () {
    return {
      show: false
    }
  },

  mounted () {
    if (this.layout === 'alphabetic') {
      this.keyboard.setOptions({
        layout: {
          default: [
            '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
            '{tab} q w e r t y u i o p [ ] \\',
            '{lock} a s d f g h j k l ; \' {enter}',
            '{shift} z x c v b n m , . / {shift}',
            '.com @ {space}'
          ],
          shift: [
            '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
            '{tab} Q W E R T Y U I O P { } |',
            '{lock} A S D F G H J K L : " {enter}',
            '{shift} Z X C V B N M < > ? {shift}',
            '.com @ {space}'
          ]
        }
        // theme: 'hg-theme-default hg-layout-numeric numeric-theme'
      })
    } else {
      this.keyboard.setOptions({
        layout: {
          default: ['1 2 3', '4 5 6', '7 8 9', '{shift} 0 -', '{bksp}'],
          shift: ['! / #', '$ % ^', '& * (', '{shift} ) +', '{bksp}']
        }
        // theme: 'hg-theme-default hg-layout-numeric numeric-theme'
      })
    }
  },
  computed: {
    computedInputType () {
      if (this.inputType === 'password') {
        return this.show ? 'text' : 'password'
      } else {
        return this.inputType
      }
    },
    appendIcon () {
      if (this.inputType === 'password') {
        return this.show ? 'mdi-eye' : 'mdi-eye-off'
      } else {
        return null
      }
    },
    inputs () {
      return this.$store.state.inputs
    },
    keyboard () {
      return this.$store.state.keyboardInstance
    },
    valueForActiveInput () {
      return this.inputs[this.uniqueInputName]
    }
  },
  methods: {
    onSendClick () {
      this.$emit('onSendClick', this.valueForActiveInput)
      this.$store.commit('hideKeyboard')
      this.$store.commit('setActiveInput', '')
    },
    onCancelClick () {
      this.$store.commit('setActiveInput', '')
      this.$emit('onCancelClick')
    },
    activateKeyboard () {
      this.$store.commit('setKeyboard', { keyboardOn: true, inputName: this.uniqueInputName })
    },
    onInputChange (input) {
      this.$store.commit('setActiveInput', input)
    }
  }
})
</script>
