<template>
  <tr :key="name">
    <TooltipTd
      :variableName="name"
      :description="description"
      :deviceType="deviceType"
      :node="node"
      >
    </TooltipTd>
    <td v-if="nodeType" class="text-right">
     {{ resolvedValue }}
    </td>
    <td v-if="!nodeType" class="text-right">
      <!-- {{ this.node.value }} -->
      <span v-if="node.typeName === 'boolean' || node.typeName === 'Bool'">{{ resolvedValue ? $t('general.YES') : $t('general.NO') }}</span>
      <span v-else>{{ resolvedValue }}</span>
    </td>
  </tr>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import TooltipTd from '../../tooltip-td.vue'
import moment from 'moment'
import { readAttributeValue } from '../../../helpers/utils.ts'

export default Vue.extend({
  name: 'default-row',
  props: {
    name: {},
    node: {},
    variables: {},
    types: {},
    description: {},
    deviceType: {},
    unit: {}
  },

  components: {
    TooltipTd
  },

  computed: {
    nodeType () {
      return this.types[this.node.typeName]
    },

    ...mapState('tygroshka', [
      'translations'
    ]),

    resolvedValue () {
      const value = readAttributeValue(this.node, this.name, this.variables)
      if (this.node.typeName === 'DateTime') {
        return moment(value.epochMsec).format('DD.MM. YYYY - HH:mm:ss')
      }
      if (/Enum/.test(this.node.typeName)) {
        const transString = `type.${this.node.typeName}.fields.${value}.label`
        return this.translations[transString] || value
      }
      if (typeof value === 'object') {
        return value
      }
      if (this.unit) {
        return `${value} ${this.unit}`
      }
      return value
    }
  }
})
</script>
