<template>
  <div>
    <h2>{{ $t('logs') }}</h2>
    {{  bbb }}
      <v-container>
        <v-col cols="12" sm="12">
          <v-row>
            <v-col cols="12" sm="3">
              <date-time-picker :label="$t('from')" clearable="true" v-model="since"></date-time-picker>
            </v-col>
            <v-col cols="12" sm="3">
              <date-time-picker :label="$t('to')" clearable="true" v-model="until"></date-time-picker>
            </v-col>
            <v-col cols="12" sm="3">
              <input-with-sw-keyboard
                :label="$t('logs.filter')"
                :cleanAfterSent="cleanAfterSent"
                :uniqueInputName="logs.pathPattern"
                @onSendClick="getLog"
                layout="alphabetic"
                v-model='pathPattern'>
              </input-with-sw-keyboard>
            </v-col>
          </v-row>
        </v-col>
      </v-container>
      {{ aaa }}
    <div v-if=loading>
      <v-progress-circular
        :width="5"
        :size="60"
        color="green"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-data-table
      v-if=!loading
      :disable-pagination="true"
      :hide-default-footer="true"
      :headers="headers"
      :items="decoratedLogs"
      multi-sort
      class="elevation-1"
    ></v-data-table>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'

import DateTimePicker from '../components/date-time-picker.new.vue'

import InputWithSwKeyboard from '../../siteware-lib/src/components/input-with-sw-keyboard.vue'

import moment from 'moment'
import { parseStatusNode } from '../../siteware-lib/src/components/property-sheets/shv-3/utils.js'
import { getDeviceAndAttributePath } from '../../siteware-lib/src/services/shv-parser/device-parser'

export default Vue.extend({
  name: 'logs',
  props: {
    alarms: { type: Array },
    assetsData: { type: Object }
  },

  data () {
    return {
      headers: [
        { text: this.$i18n.t('logs.time'), value: 'timestamp' },
        { text: this.$i18n.t('logs.assetName'), value: 'assetName' },
        { text: this.$i18n.t('logs.name'), value: 'name' },
        { text: this.$i18n.t('logs.value'), value: 'value' },
        // { text: this.$i18n.t('logs.type'), value: 'type' },
        { text: this.$i18n.t('logs.domain'), value: 'domain' },
        { text: this.$i18n.t('logs.userId'), value: 'userId' }
      ],
      loading: false,
      since: moment().subtract(1, 'hours'),
      until: moment(),
      pathPattern: '',
      cleanAfterSent: false,
      logs: [],
      hiddenPagination: true,
      aaa: null,
      bbb: null
    }
  },

  computed: {
    ...mapState('tygroshka', [
      'shvPath',
      'translations',
      'shvData'
    ]),
    decoratedLogs () {
      if (!this.logs) return []
      console.log('decorate logs!!!!!!!!!!!!')
      return this.logs.reduce((result, row) => {
        const shvPath = row.value[1].value
        const fullShvPath = `${this.shvPath}/${shvPath}`
        const assetAndAttribute = getDeviceAndAttributePath(shvPath, this.shvData.assetsByPath)
        console.log('aaa', assetAndAttribute)
        this.aaa = assetAndAttribute
        if (assetAndAttribute) {
          const asset = assetAndAttribute[0]
          const attribute = assetAndAttribute[1]
          const shValue = row.value[2].value
          const domain = row.value[4].value
          const userId = row.value[6].value
          const assetNode = asset.nodes.find(n => n.name === attribute)
          if (!assetNode) {
            return result
          }
          const variables = parseStatusNode(attribute, { typeName: assetNode.typeName, value: shValue }, this.shvData.types, this.translations)
          // console.log('variables found', variables)
          this.bbb = variables
          if (variables.length > 0) {
            for (const [variableName, variableNode] of variables) {
              const readValue = variableNode.value
              const value = readValue
              // console.log('variable name', variableName, variableNode, asset, shValue, shvPath)
              const newRow = {
                assetName: asset.shvPath,
                name: this.getTranslation(asset, variableNode, variableName), // this.$i18n.t(`variable.${asset.assetType.deviceType}.${variableName}`),
                value: value === null ? 'null' : value,
                type: variableNode.typeName,
                timestamp: moment(row.value[0].value.epochMsec).format('DD.MM. YYYY - HH:mm:ss.SSS'),
                userId: userId,
                domain: domain
              }
              result.push(newRow)
            }
          } else {
            // console.log('not PARSED', fullShvPath, shValue)
            const newRow = {
              assetName: asset.name,
              name: fullShvPath,
              value: shValue,
              timestamp: moment(row.value[0].value.epochMsec).format('DD.MM. YYYY - HH:mm:ss.SSS'),
              userId: userId,
              domain: domain
            }
            result.push(newRow)
          }
        } else {
          console.log('no device with shv path', shvPath)
        }
        return result
      }, [])
    }
  },

  components: {
    DateTimePicker,
    InputWithSwKeyboard
  },

  methods: {
    returnHeaders () {
      return this.headers
    },
    getTranslation (asset, node, variableName) {
      // return { node: this.node, name: variableName, deviceType: this.deviceType }
      let translationString
      if (node.parentType) {
        translationString = `type.${node.parentType}.fields.${variableName}.label`
      } else {
        translationString = `device.${asset.assetType.deviceType}/${variableName}.label`
      }
      return this.translations[translationString] ? this.translations[translationString] : translationString
    },
    async getLog (pathPattern) {
      this.loading = true
      const systemRoot = this.shvPath.replace('shv', 'history')
      const formatDate = (time) => moment(time).utc().toISOString()
      const pattern = pathPattern ? `**/${pathPattern}/**` : ''
      console.log('trying to format since', formatDate(this.since))
      // const params = `{ "withPathsDict": false, "since":d"2020-04-01T06:12:21.380Z", "until":d"2020-04-01T07:05:21.380Z", "pathPattern": "" }`
      const params = `{
        "withPathsDict": false,
        "since":d"${formatDate(this.since)}",
        "until":d"${formatDate(this.until)}",
        "recordCountLimit": 5000,
        "pathPattern": "${pattern}"
      }`
      const result = await this.$tygroshka.service.sendMessage(systemRoot, 'getLog', params, 'gettingLog')
      console.log('returned logs', result.result().value, params)
      this.logs = result.result().value
      this.loading = false
    }
  }
})

</script>
