import { getInnerElement } from './tools'

export const cabinetRFID = (element, state, assetsData, options, attribute) => {
  const userIDEl = getInnerElement(element, 'userID')

  if (userIDEl) {
    userIDEl.setAttribute('visibility', 'hidden')
    userIDEl.textContent = state.get('userID')
    userIDEl.setAttribute('visibility', 'visible')
  }
}
