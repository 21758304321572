<template>
  <v-app>
    <v-navigation-drawer
      v-model="sidebar"
      v-if="!loginNeed()"
      :mini-variant-width="90"
      app
      touchless
      clipped
      :mini-variant="mini"
    >
      <v-list>
        <v-list-item
          :class="activeClass(menuObject.route)"
          v-for="menuObject in filteredMenuItems"
          :key="menuObject.name"
          @click="navigate(menuObject.route, menuObject.shvPath)"
        >
          <v-list-item-icon>
            <v-icon x-large>mdi-{{ menuObject.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content v-once>
            <v-list-item-title>{{ $t(`menu.${menuObject.name}`) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :class="activeClass('/devices')"
          @click="navigate({ name: 'asset-detail' })"
        >
          <v-list-item-icon>
            <v-icon x-large>mdi-animation</v-icon>
          </v-list-item-icon>
          <v-list-item-content v-once>
            <v-list-item-title>{{ $t(`menu.devices`) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :class="activeClass('/logs')"
          @click="navigate({ name: 'logs' })"
        >
          <v-list-item-icon>
            <v-icon x-large>mdi-history</v-icon>
          </v-list-item-icon>
          <v-list-item-content v-once>
            <v-list-item-title>{{ $t(`menu.logs`) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :class="activeClass('/alarms')"
          @click="navigate({ name: 'alarms' })"
        >
          <v-list-item-icon>
            <v-badge color="red" bottom :content="alarms.length" :value="alarms.length">
              <v-icon x-large>mdi-alert</v-icon>
            </v-badge>
          </v-list-item-icon>

          <v-list-item-content v-once>
            <v-list-item-title>{{ $t(`menu.alarms`) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      app
      clipped-left
      :color="loginNeed() ? 'black' : zoneColor"
      dark
    >
    <span v-if="showDebugPanel">DEBUG VERSION - OPC: {{ opcVersion }}</span>
      <v-app-bar-nav-icon v-once class="hidden-lg-and-up" @click="sidebar = !sidebar"></v-app-bar-nav-icon>
      <div v-once class="d-flex align-center">
      </div>
      <img v-if="customProjectName !== 'Cha'" class="hidden-sm-and-down" src="@/assets/logo2.png" width="150px">
      <v-spacer></v-spacer>
      <strong v-if="!loginNeed() && zones.length > 0">{{ $t(`zone-status`) }}:  {{ zonesStateTranslation }}</strong>
      <v-spacer></v-spacer>
      <span class="hidden-sm-and-down" v-if="!loginNeed()"><strong>{{ $t('shvConnection') }}: {{ $t(clientState()) }}</strong></span>
      <span class="hidden-sm-and-down" v-if="loginNeed()"><strong>{{ $t('shvConnection') }}: {{ $t('Disconnected') }}</strong></span>
      <v-spacer></v-spacer>
      <span class="hidden-sm-and-down" v-if="!loginNeed() && systems.length > 0"><strong>{{ $t('PLCConnection') }}: {{ $t(solveShvgateState()) }}</strong></span>
      <v-spacer></v-spacer>
      <v-btn v-once icon @click='toggleReplay()'><v-icon>mdi-history</v-icon></v-btn>
      <v-spacer></v-spacer>
      <!-- <strong>IDLE {{ idle }}</strong> -->
      <!-- <v-spacer></v-spacer> -->
      {{ timeToShow }}
      <v-btn v-once icon v-if="customProjectName !== 'Cha'" @click='toggleKeyboard()'><v-icon>mdi-keyboard</v-icon></v-btn>
      <v-btn v-once icon v-if="customProjectName !== 'Cha'" @click='toggleKeyboardPosition()'><v-icon>mdi-arrow-up-down</v-icon></v-btn>
      <v-menu v-once offset-y v-if=languagesEnabled>
        <template v-slot:activator="{ on }">
          <v-btn
            dark
            icon
            v-on="on"
          >
            <v-icon>mdi-web</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(language, index) in filteredLanguages"
            :key="index"
            @click="setLanguage(language)"
          >
            <v-list-item-title>{{ language.toUpperCase() }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <span v-if="customProjectName !== 'Cha'">
        <v-btn
          icon
          v-if="userRolesEnabled"
          @click='loginPanel=!loginPanel'
        >
          <v-icon>mdi-account</v-icon>
        </v-btn>
        <span v-if="userRolesEnabled">{{ displayedShvUser }}</span>
        <v-btn v-if="userRolesEnabled" icon @click='logout()'><v-icon>mdi-logout</v-icon></v-btn>
        <!-- <v-btn icon @click='bottomPanel=!bottomPanel'><v-icon>mdi-dock-bottom</v-icon></v-btn> -->
      </span>
      <v-btn
        icon
        v-if="shvSettingsEnabled"
        @click='shvSettings=!shvSettings'
      >
        <v-icon>mdi-cogs</v-icon>
      </v-btn>
      <v-btn icon @click="mini = !mini"><v-icon>mdi-menu-open</v-icon></v-btn>
      <strong>
        <span v-once @click="versionsInfo = !versionsInfo">
          {{ swVersion }}
        </span>
      </strong>
    </v-app-bar>

    <v-main v-if="!loginNeed()">
      <v-container fluid class="ma-1">
        <router-view
          :assetsData="assets"
          :alarms="alarms"
          :requests="requests">
        </router-view>
      </v-container>
    </v-main>

    <transition>
      <div v-show="keyboardOn" class="keyboard-panel keyboard-panel-bottom" id="keyboard-panel">
        <simple-keyboard />
      </div>
    </transition>
    <transition>
      <div v-show="externalWindow" class="external-window-panel external-window-panel-top" id="external-window-panel">
        <v-card>
          <v-toolbar class="primary">
            <v-toolbar-title style="color: white;"><strong></strong></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closeExternalWindow()"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar>
          <v-card-text class="py-0">
            <v-container>
              <external-window>
              </external-window>
            </v-container>
          </v-card-text>
        </v-card>
      </div>
    </transition>
    <transition v-if="loginPanel || loginNeed()">
      <div class="login-panel">
        <v-row>
        <v-card-title class="ret-card">
          <v-card-title>
            <h3 class="headline">{{ $t('sign-in-caps') }}</h3>
          </v-card-title>
          <v-card-text>
            <form>
              <input-with-sw-keyboard
                :label="$t('username')"
                :cleanAfterSent="cleanAfterSent"
                uniqueInputName="app.shvUsername"
                layout="alphabetic">
              </input-with-sw-keyboard>
              <input-with-sw-keyboard
                :label="$t('password')"
                :cleanAfterSent="cleanAfterSent"
                uniqueInputName="app.shvPassword"
                layout="alphabetic"
                :inputType="'password'">
              </input-with-sw-keyboard>
            </form>
            <v-btn
              @click="shvConnectWithUser()"
              color="primary"
            >{{ $t('sign-in') }}</v-btn>
          </v-card-text>
        </v-card-title>
        </v-row>
        <!-- <v-row class="mb-4" v-if="loginError.length > 0"> -->
        <!--   <v-card-title> -->
        <!--     <h3>Error message</h3> -->
        <!--   </v-card-title> -->
        <!--   <v-card-text> -->
        <!--     <div> -->
        <!--       {{ loginError }} -->
        <!--     </div> -->
        <!--   </v-card-text> -->
        <!-- </v-row> -->
      </div>
    </transition>
    <transition v-once v-if="versionsInfo">
      <div class="versions-info">
        <v-row>
          <v-card-title class="ret-card">
            <v-card-title>
              <h3 class="headline">{{ $t('versions') }}</h3>
            </v-card-title>
            <v-card-text>
              SHV version: <strong>{{ shvVersion }}</strong> <br />
              Siteware version: <strong>{{ swVersion }}</strong> <br />
              SHV Gate commit: <strong>{{ shorterSHVGateCommit }}</strong>
            </v-card-text>
          </v-card-title>
        </v-row>
      </div>
    </transition>
    <transition v-once v-if="shvSettings">
      <div class="installations-panel">
        <v-card class="ma-1" v-for="(installationGroupName, index) of Object.keys(finalInstallations)" :key="index">
          <v-card-title>
            {{ installationGroupName }}
          </v-card-title>
          <v-card-text>
            <v-btn
              v-for="(installation, index) of finalInstallations[installationGroupName]"
              :key="index"
              class="primary ma-1"
              @click="setInstallation(installation)"
            >
            {{ installation.name }}
            </v-btn>
          </v-card-text>
        </v-card>
        <v-card class="ma-1" v-if="showDebugPanel">
          <v-card-title>
            SHV settings
          </v-card-title>
          <v-card-text>
            <form>
              <v-text-field
                v-model="shvURI"
                :label="$t('shv-uri')"
              ></v-text-field>
              <v-text-field
                v-model="shvPath"
                :label="$t('shv-path')"
              ></v-text-field>
            </form>
            <v-btn
              @click="shvConnectWithUser()"
              color="primary"
            >
              {{ $t('shv-set') }}
            </v-btn>
            <v-btn
              @click="logout()"
              color="primary"
            >{{ $t('reset-settings') }}</v-btn>
            <br />
            <v-btn
              @click="setShowSHVPropertyName"
              color="primary"
            >
              {{ $t('toggle-shv-property-name') }}
            </v-btn>
          </v-card-text>
        </v-card>
      </div>
    </transition>
    <transition v-if="safetyPin">
      <div class="top-panel">
        <safety-pin></safety-pin>
      </div>
    </transition>
    <replay ref="replay">
    </replay>
  </v-app>
</template>

<style>
  .keyboard-panel {
    position: fixed;
    right: 70px;
    min-height: 103px;
    max-height: 85vh;
    width: 80%;
    background-color: white;
    overflow-y: auto;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  tbody tr:nth-of-type(odd) {
     background-color: rgba(0, 0, 0, .05);
   }
  .keyboard-panel-bottom {
    bottom: 3vh;
  }
  .keyboard-panel-top {
    top: 9vh;
  }
  .external-window-panel {
    position: fixed;
    right: 70px;
    min-height: 103px;
    max-height: 85vh;
    width: 80%;
    background-color: white;
    overflow-y: auto;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  .external-window-panel-bottom {
    bottom: 3vh;
  }
  .external-window-panel-top {
    top: 9vh;
  }
  .debug-panel {
    position: fixed;
    bottom: 10vh;
    right: 30px;
    min-height: 103px;
    max-height: 85vh;
    width: 75%;
    overflow-y: auto;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    opacity: 1;
  }
  .login-panel {
    position: fixed;
    top: 10vh;
    right: 30px;
    min-height: 20%;
    max-height: 60%;
    width: 40%;
    overflow-y: auto;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    opacity: 1;
  }
  .installations-panel {
    position: fixed;
    top: 10vh;
    right: 30px;
    min-height: 10%;
    max-height: 80%;
    width: 70%;
    overflow-y: auto;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    opacity: 1;
  }
  .versions-info {
    position: fixed;
    top: 10vh;
    right: 30px;
    min-height: 10%;
    max-height: 80%;
    width: 30%;
    overflow-y: auto;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    opacity: 1;
  }
  .top-panel {
    position: fixed;
    top: 10vh;
    right: 30px;
    min-height: 103px;
    max-height: 85vh;
    width: 50%;
    overflow-y: auto;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    opacity: 1;
  }
  .right {
    text-align: right;
  }
  .plc-disconnected {
    background-color: red;
  }
  .active-link {
    background-color: #12602C66;
    color: 'white';
  }
  .white-link {
    color: white !important;
  }
</style>

<script>
import Vue from 'vue'
import SimpleKeyboard from './components/simple-keyboard.vue'
import SafetyPin from './components/safety-pin.vue'
import Replay from './components/replay.vue'

import InputWithSwKeyboard from '../siteware-lib/src/components/input-with-sw-keyboard.vue'
import ExternalWindow from '../siteware-lib/src/components/external-window.vue'
import { zoneState } from '../siteware-lib/src/helpers/property-decorators'

import { mapState } from 'vuex'

export default Vue.extend({
  name: 'App',

  data () {
    return {
      tab: 0,
      shvUser: '',
      shvPassword: '',
      shvURI: '',
      shvPath: '',
      svgPath: '',
      shvSettings: '',
      debugPanel: false,
      bottomPanel: false,
      loginPanel: false,
      versionsInfo: false,
      keyboardPosition: 'bottom',
      cleanAfterSent: false,
      client: {},
      method: '',
      params: '',
      requests: {},
      mini: true,
      timeToShow: '',
      installations: [],
      admin: false,
      languages: [
        'en',
        'cs',
        'de',
        'fi',
        'hu',
        'nl',
        'sr',
        'nl_BE',
        'it',
        'pl'
      ],
      menuItems: [
        { name: 'visual', icon: 'home', route: '/visual' },
        { name: 'Heating', icon: 'thermometer', route: { name: 'asset-detail' }, shvPath: 'devices/heating' },
        { name: 'Cabinet', icon: 'wardrobe', route: { name: 'asset-detail' }, shvPath: 'devices/cabinet' },
        { name: 'Zone', icon: 'alpha-z-box', route: { name: 'asset-detail' }, shvPath: 'devices/zone' },
        { name: 'System', icon: 'chip', route: { name: 'asset-detail' }, shvPath: 'system' }
      ]
    }
  },

  components: {
    SimpleKeyboard,
    SafetyPin,
    ExternalWindow,
    InputWithSwKeyboard,
    Replay
  },

  computed: {
    ...mapState('tygroshka', [
      'assets',
      'safetyPin',
      'alarms',
      'shvVersion',
      'gitCommit',
      'shvData',
      'translations',
      'installationsFromShv',
      'showSHVPropertyName',
      'customProjectName',
      'shvErrorMsg'
    ]),
    shorterSHVGateCommit () {
      return this.gitCommit.slice(this.gitCommit.length - 8)
    },
    userMissing () {
      return !this.$config.shvUser && !localStorage.getItem('userName')
    },
    opcVersion () {
      return this.$store.state.opcVersion
    },

    clientStateComputed () {
      return this.$tygroshka.service.status
    },
    assetTypes () {
      return Object.values(this.shvData.devices)
    },

    finalInstallations () {
      if (Object.keys(this.installations).length === 0) {
        const startObj = { general: [] }
        const installationsArray = Object.keys(this.installationsFromShv).map(installationName => {
          const installationObject = this.installationsFromShv[installationName]
          return {
            name: installationObject._meta.name,
            shvURI: this.shvURI,
            shvPath: `shv/${installationName}`
          }
        })
        startObj.general = installationsArray
        return startObj
      }
      return this.installations
    },

    filteredLanguages () {
      if (this.$config.debugPanel) {
        return this.languages
      }
      const languageArray = JSON.parse(localStorage.getItem('languageArray'))
      return languageArray || this.languages
    },

    filteredMenuItems () {
      const defaultMenuItems = ['visual', 'device-types', 'logs']
      return this.menuItems.filter(menuItem => {
        const regexp = new RegExp(`${menuItem.name}.*`, 'g')
        return [...this.assetTypes, ...defaultMenuItems].some(e => {
          return regexp.test(e)
        })
      })
    },
    inputs () {
      return this.$store.state.inputs
    },
    idle () {
      return this.$store.state.idleVue.isIdle
    },
    showDebugPanel () {
      return this.$config.debugPanel
    },
    userRolesEnabled () {
      return this.$config.userRolesEnabled
    },
    shvSettingsEnabled () {
      return this.$config.shvSettingsEnabled || this.installations > 1
    },
    languagesEnabled () {
      return this.$config.languagesEnabled || true
    },
    swVersion () {
      return this.$store.state.swVersion
    },
    sidebar: {
      get () {
        return this.$store.state.sidebar
      },
      set (val) {
        this.$store.commit('sidebar', val)
      }
    },
    decoratedShvError () {
      return this.shvErrorMsg.map(errorMsg => {
        if (!errorMsg) {
          return ''
        }
        return errorMsg.requestId + ' ' + errorMsg.error.value['2'].value
      }).join('\n')
    },
    loginError () {
      return this.shvErrorMsg.map(errorMsg => {
        if (errorMsg.requestId === 2) {
          return errorMsg.error.value['2'].value
        } else {
          return ''
        }
      }).join('\n')
    },
    displayedShvUser () {
      const loginError = this.shvErrorMsg.find(m => m.requestId === 2)
      return loginError ? this.$i18n.t('general.nouser') : this.shvUser
    },
    systems () {
      const systems = Object.values(this.shvData.assetsByPath).filter(a => a.assetType.deviceType === 'System' || a.assetType.deviceType === 'System_G3')
      return systems
    },
    zones () {
      const zones = Object.values(this.shvData.assetsByPath).filter(a => a.assetType.deviceType === 'Zone_G3')
      return zones
    },
    zonesState () {
      // TODO add logic to decide state of multi zone
      const state = this.zones[0] ? this.solveZoneState(this.zones[0]) : 'unknown'
      return state
    },

    zonesStateTranslation () {
      const state = this.zones[0] ? this.solveZoneStateTranslation(this.zones[0]) : 'unknown'
      return state
    },

    zoneColor () {
      if (this.plcDisconnection) {
        return 'black'
      }
      if (this.zonesState.toLowerCase() === 'normal') {
        return 'primary'
      } else if (this.zonesState.toLowerCase() === 'allblocked') {
        return '#e8d64d'
      } else if (this.zonesState.toLowerCase() === 'emergencyinhibit') {
        return 'red'
      } else {
        return 'black'
      }
    },

    plcDisconnection () {
      const systems = Object.values(this.shvData.assetsByPath).filter(a => a.assetType.deviceType === 'System_G3')
      const plcDisconnected = systems.filter(s => {
        return s.variables.get('plcDisconnected') === true
      })

      if (this.clientState() !== 'CONNECTED') {
        return true
      }
      if (systems.length > 0) {
        if (plcDisconnected.length > 0) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    externalWindow () {
      return this.$store.state.externalWindow
    },
    keyboardOn () {
      return this.$store.state.keyboardOn
    }
  },

  async mounted () {
    fetch(process.env.BASE_URL + 'installations.json')
      .then(result => {
        return result.json()
      })
      .then(installations => {
        this.installations = installations
      })

    this.currentTime()
    this.shvUser = localStorage.getItem('userName') || ''
    this.shvPassword = localStorage.getItem('password') || ''
    this.shvURI = localStorage.getItem('shvURI') || this.$config.shvURI
    this.shvPath = localStorage.getItem('shvPath') || this.$config.shvSubscribePath
    this.$store.commit('tygroshka/svgPath', this.svgPath)
    if (this.plcDisconnection) {
      this.debugPanel = true
    }
  },

  methods: {
    toggleReplay () {
      if (this.$refs.replay.modalReplayShow) {
        this.$refs.replay.hide()
      } else {
        this.$refs.replay.show()
      }
    },
    toggleKeyboard () {
      this.$store.commit('toggleKeyboard')
    },

    closeDebugPanel () {
      this.debugPanel = false
    },

    closeExternalWindow () {
      this.$store.commit('setExternalWindow', false)
    },

    toggleKeyboardPosition () {
      const element = document.getElementById('keyboard-panel')
      if (this.keyboardPosition === 'bottom') {
        element.classList.remove('keyboard-panel-bottom')
        element.classList.add('keyboard-panel-top')
        this.keyboardPosition = 'top'
      } else {
        element.classList.remove('keyboard-panel-top')
        element.classList.add('keyboard-panel-bottom')
        this.keyboardPosition = 'bottom'
      }
    },

    clientState () {
      return this.$tygroshka.service.status
    },

    loginNeed () {
      // no user is yet defined
      if (this.userMissing) {
        return true
      } else if (this.clientState() !== 'CONNECTED') {
        // user may be defined but SHV is still not connected
        return true
      } else {
        return false
      }
    },

    solveShvgateState () {
      const systems = Object.values(this.assets).filter(a => a.deviceType === 'System' || a.deviceType === 'System_G3')
      if (this.plcDisconnection) {
        const plcDisconnected = systems.filter(s => s.variables.get('plcDisconnected') === true)
        if (systems.length > 0) {
          return plcDisconnected.map(p => `${p.name} disconnected`).join()
        } else {
          return 'DISCONNECTED'
        }
      } else {
        return 'CONNECTED'
      }
    },

    solveWarningColor () {
      return this.plcDisconnection ? 'plc-disconnected' : ''
    },

    solveZoneState (zone) {
      if (this.plcDisconnection) {
        return 'Unknown'
      }
      return zoneState(zone.variables, this.opcVersion, zone).text
    },

    solveZoneStateTranslation (zone) {
      if (this.plcDisconnection) {
        return 'Unknown'
      }
      const zoneStateText = zoneState(zone.variables, this.opcVersion, zone).text
      return this.translations[`type.EnumZoneState.fields.${zoneStateText}.label`]
    },

    setLanguage (lang) {
      localStorage.setItem('userLanguage', lang)
      localStorage.setItem('relogManually', 'true')
      this.reload()
    },

    reload () {
      document.location.reload(true)
    },

    currentTime () {
      const today = new Date()
      const h = today.getHours()
      let m = today.getMinutes()
      let s = today.getSeconds()
      m = this.checkTime(m)
      s = this.checkTime(s)
      this.timeToShow = h + ':' + m + ':' + s
      const t = setTimeout(this.currentTime, 500)
      return t
    },

    checkTime (i) {
      if (i < 10) { i = '0' + i } // add zero in front of numbers < 10
      return i
    },

    shvConnect () {
      return this.$tygroshka.service.connect(this.shvPath)
    },

    async shvConnectWithUser () {
      this.shvUser = this.inputs['app.shvUsername'] || localStorage.getItem('userName') || this.$config.shvUser
      this.shvPassword = this.inputs['app.shvPassword'] || localStorage.getItem('password') || this.$config.shvPassword
      this.$tygroshka.service.connect(
        this.shvPath,
        this.shvUser,
        this.shvPassword,
        this.shvURI
      )
      localStorage.setItem('userName', this.shvUser)
      localStorage.setItem('password', this.shvPassword)
      localStorage.setItem('shvURI', this.shvURI)
      localStorage.setItem('shvPath', this.shvPath)
      localStorage.setItem('svgPath', this.svgPath)
      localStorage.setItem('relogManually', 'true')
      this.$store.commit('tygroshka/svgPath', this.svgPath)
      this.shvUser = ''
      this.shvPassword = ''
      this.loginPanel = false
      location.reload()
    },

    logout () {
      localStorage.removeItem('userName')
      localStorage.removeItem('password')
      localStorage.removeItem('shvURI')
      localStorage.removeItem('shvPath')
      localStorage.removeItem('svgPath')
      location.reload()
    },

    async logoutUser () {
      const userRoles = await this.$tygroshka.service.sendMessage(
        '.broker/currentClient',
        'userRoles',
        undefined
      )
      const currentUser = localStorage.getItem('userName')
      const defaultUser = localStorage.getItem('defaultUser')
      const userRolesArray = userRoles.result().value
      if (userRolesArray.map(a => a.value).includes('viewer') || currentUser === defaultUser) {
        console.log('this is viewer do not log out')
      } else {
        localStorage.removeItem('userName')
        localStorage.removeItem('password')
        location.reload()
      }
    },

    setInstallation (installation) {
      this.shvURI = installation.shvURI
      this.shvPath = installation.shvPath
      this.svgPath = installation.svgPath
      this.shvConnectWithUser()
    },

    shvDisconnect () {
      return this.$tygroshka.service.disconnect()
    },

    setShowSHVPropertyName () {
      this.$store.commit('tygroshka/showSHVPropertyName', !this.showSHVPropertyName)
    },

    navigate (route, shvPath = '') {
      if (route.name === 'asset-detail') {
        this.$store.commit('tygroshka/currentAssetShvPath', shvPath)
      }
      if (this.$route.name !== route.name) {
        console.log('check duplication of routes', this.$route, route)
        // avoid NavigationDuplicated error
        this.$router.push(route)
      }
    },

    activeClass (...args) {
      const [route, ...rest] = args
      const routePath = this.$route.path || ''
      return rest.concat([routePath === route ? 'active-link' : ''])
    }
  },

  watch: {
    plcDisconnection (value) {
      if (value) {
        this.debugPanel = true
      } else {
        this.debugPanel = false
      }
    },
    idle (value) {
      if (value) {
        this.logoutUser()
      } else if (!value && !this.updateActive) {
        // do not start if update is already active
      }
    },
    $route (to, from) {
    }
  }

})
</script>
