import { getInnerElement, solveRadioBeamsG3, blinkOnVehicleDetected } from './tools'

export const spieLoopG3 = (element, state, assetsData, options, attribute) => {
  const lineNumberEl = getInnerElement(element, 'lineNumber')
  const tramIdEl = getInnerElement(element, 'tramId')
  const codeEl = getInnerElement(element, 'routeCode')
  solveRadioBeamsG3(element, state)
  blinkOnVehicleDetected(element, state, 'vehicleId', attribute)

  if (lineNumberEl && state.get('vehicleDetected')) {
    lineNumberEl.setAttribute('visibility', 'hidden')
    if (state.get('vehicleDetected').lineNumber) {
      lineNumberEl.textContent = state.get('vehicleDetected').lineNumber
      lineNumberEl.setAttribute('visibility', 'visible')
    }
  }
  if (tramIdEl && state.get('vehicleDetected')) {
    tramIdEl.setAttribute('visibility', 'hidden')
    if ((state.get('vehicleDetected') || state.get('vehicleDetected').vehicleId === 0) && tramIdEl) {
      tramIdEl.textContent = state.get('vehicleDetected').vehicleId
      tramIdEl.setAttribute('visibility', 'visible')
    }
  }

  if (codeEl && state.get('vehicleDetected')) {
    codeEl.setAttribute('visibility', 'hidden')
    if ((state.get('vehicleDetected') || state.get('vehicleDetected').routeCode === 0) && codeEl) {
      codeEl.textContent = state.get('vehicleDetected').routeCode
      codeEl.setAttribute('visibility', 'visible')
    }
  }
}
