<template>
  <div>
    <h2>{{ $t('alarms') }}</h2>
    <v-data-table
      :disable-pagination="true"
      :hide-default-footer="true"
      :headers="headers"
      :items="decoratedAlarms"
      :sort-by="['name']"
      :sort-desc="[false]"
      multi-sort
      class="elevation-1"
    >
    <template v-slot:[`item.assetName`]="{ item }">
        <a href="#" @click="navigate(item)">{{ item.assetName }}</a>
    </template>
    </v-data-table>
  </div>
</template>

<style scoped>
.active-link {
  background-color: #12602C
}
</style>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import { getTranslation } from '../../siteware-lib/src/components/property-sheets/shv-3/utils.js'

export default Vue.extend({
  name: 'alarms',
  props: {
    alarms: { type: Array },
    assetsData: { type: Object }
  },

  data () {
    return {
      headers: [
        { text: this.$i18n.t('alarms.assetName'), value: 'assetName' },
        { text: this.$i18n.t('alarms.name'), value: 'name' },
        { text: this.$i18n.t('alarms.severity'), value: 'severity' },
        { text: this.$i18n.t('alarms.time'), value: 'timestamp' }
      ],
      hiddenPagination: true
    }
  },

  computed: {
    ...mapState('tygroshka', [
      'shvPath',
      'translations'
    ]),
    decoratedAlarms () {
      return this.alarms.map(alarm => {
        const translatedSeverity = this.$i18n.t(`general.${alarm.severity}`)
        return {
          name: this.getTranslation(alarm.name, alarm.node),
          shvPath: alarm.shvPath,
          assetName: alarm.assetName,
          timestamp: alarm.timestamp,
          severity: translatedSeverity
        }
      })
    }
  },

  methods: {
    returnHeaders () {
      return this.headers
    },

    getTranslation (variableName, node) {
      return getTranslation(this.translations, variableName, 'label', node)
    },

    navigate (item) {
      this.$store.commit('tygroshka/currentAssetShvPath', item.shvPath)
      this.$router.push({ name: 'asset-detail' })
    }
  }
})

</script>
