import { getInnerElement } from './tools'

export const disconDisconnector = (element, state) => {
  const [positionOn, positionOff, positionError, positionMiddle] = [
    getInnerElement(element, 'positionOn'),
    getInnerElement(element, 'positionOff'),
    getInnerElement(element, 'positionError'),
    getInnerElement(element, 'positionMiddle')
  ]
  positionOn.setAttribute('visibility', 'hidden')
  positionOff.setAttribute('visibility', 'hidden')
  positionMiddle.setAttribute('visibility', 'hidden')
  positionError.setAttribute('visibility', 'hidden')
  if (state.get('badPosition') === true) {
    positionError.setAttribute('visibility', 'visible')
  }
  if (state.get('positionOn') === true) {
    positionOn.setAttribute('visibility', 'visible')
  } else if (state.get('positionOff') === true) {
    positionOff.setAttribute('visibility', 'visible')
  } else if (state.get('intermediatePosition') === true) {
    positionMiddle.setAttribute('visibility', 'visible')
  }
}
