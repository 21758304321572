import { getInnerElement } from './tools'
import store from '../../services/tygroshka-store'

export const tcG3 = (element, state) => {
  const tcStyle = store.state.styles.visuStyle.TC_G3
  const detector = getInnerElement(element, 'tc')
  detector.setAttribute('fill-opacity', 0.75)
  if (detector) {
    if (state.get('occupied')) {
      detector.setAttribute('fill', tcStyle.occupied.background)
    } else if (state.get('claimed')) {
      detector.setAttribute('fill', tcStyle.claimed.background)
    } else {
      detector.setAttribute('fill', tcStyle.free.background)
    }
  }
}
