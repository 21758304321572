import { getInnerElement } from '../tools'
import store from '../../../services/tygroshka-store'

// Function to update the text content of an element based on the state
export const kswCha = (element, state) => {
  // console.log('kswf', element, state)
  // Retrieve the element to update
  const chidksw = getInnerElement(element, 'kswtext')

  if (!chidksw) {
    console.log('Element not found: kswtext')
    return
  }

  const stateTextMappings = {
    '': '',
    allumage: 'ALLUMAGE',
    anulate: 'ANULATE',
    depot: 'DEPOT',
    'hors service': 'HORS SERVICE',
    junction: 'JUNCTION',
    manual: 'MANUAL',
    'non manual': 'NON MANUAL',
    norm: 'NORM',
    rebrousment: 'REBROUSMENT',
    surchiste: 'SURCHISTE'
  }

  const keys = Object.keys(stateTextMappings)
  let isStateFound = false
  for (let i = 0; i < keys.length; i++) {
    if (state.get(keys[i]) === true) {
      chidksw.textContent = stateTextMappings[keys[i]]
      isStateFound = true
      break
    }
  }

  if (!isStateFound) {
    chidksw.textContent = ''
  }
}
