import { render, staticRenderFns } from "./gpio-shv3-property-sheet.vue?vue&type=template&id=54a6eafa&scoped=true"
import script from "./gpio-shv3-property-sheet.vue?vue&type=script&lang=js"
export * from "./gpio-shv3-property-sheet.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54a6eafa",
  null
  
)

export default component.exports