import { getInnerElement } from '../tools'

export const heaterCha = (element, state) => {
  const rod = getInnerElement(element, 'rod')
  // console.log('heaterf', element, state)
  if (rod) {
    if (state.get('heating') === true) {
      rod.setAttribute('fill', 'red')
      rod.setAttribute('stroke', 'red')
    } else if (state.get('heating') === false) {
      rod.setAttribute('fill', 'black')
      rod.setAttribute('stroke', 'black')
    }
  }
}
