<template>
  <tr :key="name">
    <TooltipTd
      :variableName="name"
      :description="description"
      :deviceType="deviceType"
      :node="node"
      >
    </TooltipTd>
    <td align="right">
      <table>
        <tr v-for="[name, value] in resolvedValue()" :key="name">
          <td>{{ getTranslation(name) }}</td>
          <td class="text-right">{{ value }}</td>
        </tr>
      </table>
    </td>
  </tr>
</template>

<script>
import Vue from 'vue'
import TooltipTd from '../../tooltip-td.vue'
import { mapState } from 'vuex'
import { readAttributeValue } from '../../../helpers/utils.ts'

export default Vue.extend({
  name: 'default-row',
  props: {
    name: {},
    node: {},
    types: {},
    variables: {},
    description: {},
    deviceType: {},
    unit: {}
  },

  components: {
    TooltipTd
  },

  methods: {
    getTranslation (variableName) {
      const translationString = `type.${this.node.typeName}.fields.${variableName}.label`
      return this.translations[translationString] ? this.translations[translationString] : variableName
    },

    resolvedValue () {
      const value = readAttributeValue(this.node, this.name, this.variables)
      return value ? Object.entries(value) : value
    }
  },

  computed: {
    ...mapState('tygroshka', [
      'translations'
    ])
  }
})
</script>
