<template>
  <div>
    <div>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <component
              v-for="([name, node], index) in relevantNodes"
              :key="index"
              :name="name"
              :description="name"
              :deviceType="currentObject.assetInfo.deviceType"
              :node="node"
              :variables="variables"
              :fullShvPath="fullShvPath"
              :types="types"
              :is="chooseComponent(node)"
            >
            </component>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div v-if="gpioInputs.length > 0">
      {{ inputsTranslation }}
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <component
              v-for="([name, node], index) in gpioInputs"
              :key="index"
              :name="name"
              :description="name"
              :deviceType="currentObject.assetInfo.deviceType"
              :node="node"
              :variables="variables"
              :fullShvPath="fullShvPath"
              :types="types"
              :is="chooseComponent(node)"
            >
            </component>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div v-if="gpioOutputs.length > 0">
      {{ outputsTranslation }}
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <component
              v-for="([name, node], index) in gpioOutputs"
              :key="index"
              :name="name"
              :description="name"
              :deviceType="currentObject.assetInfo.deviceType"
              :node="node"
              :variables="variables"
              :fullShvPath="fullShvPath"
              :types="types"
              :is="chooseComponent(node)"
            >
            </component>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
import Vue from 'vue'
import NumberRow from './shv-3/number-row.vue'
import DefaultRow from './shv-3/default-row.vue'
import AlarmRow from './shv-3/alarm-row.vue'
import SetRow from './shv-3/set-row.vue'
import { mapState } from 'vuex'
import { parseStatusNode } from './shv-3/utils.js'

export default Vue.extend({
  name: 'default-property-sheet',
  props: {
    assetNodes: {},
    currentObject: {},
    variables: {},
    currentAssetPath: {},
    fullShvPath: undefined,
    types: {}
  },

  data () {
    return {
      showDebugDetail: false
    }
  },

  updated () {
    console.log('UPDATED!!!!!!!!!!')
  },

  computed: {
    ...mapState('tygroshka', [
      'shvData',
      'translations'
    ]),

    inputsTranslation () {
      return this.translations[`device.${this.currentObject.assetInfo.deviceType}/inputs.label`]
    },

    outputsTranslation () {
      return this.translations[`device.${this.currentObject.assetInfo.deviceType}/outputs.label`]
    },

    relevantNodes () {
      const blacklistedPaths = this.shvData.blacklistedPaths
      return this.parsedNodes.filter(([_name, n]) => {
        const fullNodePath = `${this.currentAssetPath}/${n.fullNodePath}`
        const blacklisted = blacklistedPaths.includes(fullNodePath)
        const gpioBits = /GPIOInputs.*/.test(n.parentType) || /GPIOOutputs.*/.test(n.parentType)
        return (n.typeName || n.unit) && !blacklisted && !gpioBits
      })
    },

    gpioInputs () {
      return this.parsedNodes.filter(([_name, n]) => {
        return /GPIOInputs.*/.test(n.parentType)
      })
    },

    gpioOutputs () {
      return this.parsedNodes.filter(([_name, n]) => {
        return /GPIOOutputs.*/.test(n.parentType)
      })
    },

    // transform all status nodes to normal nodes through bitfield
    parsedNodes () {
      // console.log('ASSET NODES', this.assetNodes)
      return this.assetNodes.flatMap(node => {
        return parseStatusNode(node.name, node, this.types, this.translations)
      })
    }
  },

  methods: {
    splitAndSlice (nodePath) {
      const splittedPath = nodePath.split('/')
      return splittedPath.slice(1, splittedPath.length).join('/')
    },

    chooseComponent (node) {
      const isNumber = /Decimal/.test(node.typeName) || node.typeName === 'Int' || node.typeName === 'UInt'
      const hasSetMethod = node.methods.filter(m => m.name === 'set').length > 0
      // console.log('decide which component to use', node)
      // return 'number-row'
      if (hasSetMethod) {
        return 'set-row'
      } else if (node.alarm) {
        return 'alarm-row'
      } else if (isNumber) {
        return 'number-row'
      } else {
        return 'default-row'
      }
    }
  },

  components: {
    NumberRow,
    DefaultRow,
    AlarmRow,
    SetRow
  }
})
</script>
