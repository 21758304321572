import { getInnerElement } from '../tools'
import store from '../../../services/tygroshka-store'

export const PMECha = (element, state) => {
  // console.log('pmef', element, state)
  const elements = {
    posLeft: getInnerElement(element, 'posLeft'),
    posRight: getInnerElement(element, 'posRight'),
    leverInSocket: getInnerElement(element, 'leverInSocket'),
    motorMoving: getInnerElement(element, 'motorMoving')
  }
  element.setAttribute('visibility', 'hidden')
  const box = getInnerElement(element, 'box')
  box.setAttribute('visibility', 'visible')
  const label = getInnerElement(element, 'label')
  label.setAttribute('visibility', 'visible')
  //  const text = getInnerElement(element, 'line')
  //  text.setAttribute('visibility', 'visible')

  Object.keys(elements).forEach(key => {
    const elem = elements[key]
    if (elem) {
      const visibility = state.get(key) === true ? 'visible' : 'hidden'
      elem.setAttribute('visibility', visibility)
    }
  })
}
