<template>
  <div :class='keyboardClass'></div>
</template>

<script>
import Keyboard from 'simple-keyboard'
import 'simple-keyboard/build/css/index.css'

export default {
  name: 'simple-keyboard',
  props: {
    keyboardClass: {
      default: 'simple-keyboard',
      type: String
    }
  },
  data: () => ({

  }),
  mounted () {
    const keyboardInstance = new Keyboard({
      onChange: this.onChange,
      onKeyPress: this.onKeyPress
    })
    this.keyboard = keyboardInstance
  },

  computed: {
    keyboard: {
      get: function () {
        return this.$store.state.keyboardInstance
      },
      set: function (newInstance) {
        this.$store.commit('setKeyboardInstance', newInstance)
      }
    },
    input () {
      return this.$store.state.inputs[this.$store.state.activeInputName]
    }
  },
  methods: {
    onChange (input) {
      // this.$emit('onChange', input)
      this.$store.commit('setActiveInput', input)
    },
    onKeyPress (button) {
      this.$emit('onKeyPress', button)

      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === '{shift}' || button === '{lock}') this.handleShift()
    },
    handleShift () {
      const currentLayout = this.keyboard.options.layoutName
      const shiftToggle = currentLayout === 'default' ? 'shift' : 'default'

      this.keyboard.setOptions({
        layoutName: shiftToggle
      })
    }
  },
  watch: {
    input (input) {
      // this.$store.commit('setKeyboardProperty', { propertyName: 'setInput', input: input })
      this.keyboard.setInput(input)
    }
  }
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
</style>
