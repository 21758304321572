import { Store } from 'vuex'
import Vue from 'vue'
import { SHVClient } from './shv-client'
import { RpcMessage } from '../../../libshv-js/modules/rpcmessage'
import { RpcValue } from '../../../libshv-js/modules/rpcvalue'
import { Cpon } from '../../../libshv-js/modules/cpon'
import { getDeviceAndAttributePath } from './shv-parser/device-parser'
import { EventEmitter } from '../helpers/event-emitter'
import styles from '../../../sites/visu/visuStyle.json'

import { parseStatusNode } from '../../src/components/property-sheets/shv-3/utils.js'

import moment from 'moment'
// @ts-ignore: Unreachable code error
import translations from '../../../sites/visu/SystemG3-l10n.csv'

import { objectSet, filterKeys } from '../helpers/utils'

const readContentFull = (content) => {
  if (content instanceof RpcValue) {
    if (content.type === 10 || content.type === 11) {
      if (content.meta) {
        const convertedObject = readCponLikeObjectFull(content.value)
        return { meta: readCponLikeObjectFull(content.meta), ...convertedObject }
        // return readCponLikeObjectFull(content.value)
      } else {
        return readCponLikeObjectFull(content.value)
      }
    } else if (content.type === 7) {
      return content.value
    } else if (content.type === 9) {
      return content.value.map(value => readContentFull(value))
    } else if (content.type === 3 || content.type === 2 || content.type === 8 || content.type === 4 || content.type === 5) {
      return content.value
    } else if (content.type === 6) {
      return content.toInt()
    }
  } else if (typeof content === 'object') {
    return readCponLikeObjectFull(content)
  } else {
    return content
  }
}

const readCponLikeObjectFull = (cponObject: any) => {
  return Object.keys(cponObject).reduce((result, keyName) => {
    const content = readContentFull(cponObject[keyName])
    result[keyName] = content
    return result
  }, {})
}

const convertRpcToObject = (cponObject: any) => {
  let result = {}
  if (cponObject instanceof RpcValue) {
    let content = readContentFull(cponObject.value)
    if (Array.isArray(content)) {
      return content.map(value => convertRpcToObject(value))
    }
    if (typeof content === 'object') {
      // transform all RPC values
      content = Object.keys(content).reduce((result, keyName) => {
        result[keyName] = convertRpcToObject(content[keyName])
        return result
      }, {})
    }
    result = content
  }
  return result
}

const filterNodesByDeviceType = (nodes, deviceType: string) => {
  const assetNodes = Object.keys(nodes).reduce((result, nodePath) => {
    const deviceTypeFromPath = nodePath.split('/')[0]
    if (deviceType === deviceTypeFromPath) {
      // result[nodePath] = nodes[nodePath]
      const nodeProperties = nodes[nodePath]
      if (nodeProperties.properties) {
        result[nodePath] = nodeProperties.properties
      } else {
        result[nodePath] = Object.keys(nodeProperties).map(nodeName => {
          return { name: nodeName, ...nodeProperties[nodeName] }
        })
      }
    }
    return result
  }, {})
  return {
    nodes: assetNodes
  }
}

export class SHVService {
  private client: SHVClient
  private path: string = ''

  public assetChange = new EventEmitter()

  constructor (private store: Store<any>, path: string) {
    this.connect(path)
  }

  public get status (): string {
    return this.client ? this.client.state : 'UNKNOWN'
  }

  public connect (path?: string, userName?: string, password?: string, wsUri?: string): void {
    const config = Vue.prototype.$config
    const user = userName || localStorage.getItem('userName') || config.shvUser
    const pass = password || localStorage.getItem('password') || config.shvPassword
    const shvURI = wsUri || localStorage.getItem('shvURI') || config.shvURI
    const shvPath = path || localStorage.getItem('shvPath') || config.shvSubscribePath
    const defaultLanguage = localStorage.getItem('defaultLanguage') || config.defaultLanguage

    const customProjectName = config.customProjectName || ''
    const isCha = customProjectName === 'Cha'
    const sitesLocation = isCha ? 'shv' : 'sites'
    this.store.commit('tygroshka/customProjectName', customProjectName)

    if (user && pass) {
      localStorage.setItem('userName', user)
      localStorage.setItem('password', pass)
    } else {
      localStorage.setItem('userName', '')
      localStorage.setItem('password', '')
    }

    this.path = shvPath
    // console.log('current shv PATH', this.path, user, pass, shvPath)
    this.client = new SHVClient(
      shvURI as string,
      user as string,
      pass as string,
      shvPath as string,
      this.store
    )
    this.store.commit('tygroshka/shvPath', this.path)

    this.client.connected.subscribe(async () => {
      const sitesInfo = await this.client.sendMessage(
        'sites',
        'getSites',
        undefined
      )
      const converted: any = readCponLikeObjectFull(sitesInfo.result().value)
      const relogManually = localStorage.getItem('relogManually')
      if (converted._meta && converted._meta.siteware) {
        const installationsFromShv = filterKeys(['_meta'], converted, true)
        this.store.commit('tygroshka/installationsFromShv', installationsFromShv)
      }
      if (converted._meta && converted._meta.siteware && relogManually !== 'true') {
        const installationsFromShv = this.store.state.tygroshka.installationsFromShv
        const installationShvPath = `shv/${Object.keys(installationsFromShv)[0]}`

        const newUser = converted._meta.siteware.shvUser
        const newPassword = converted._meta.siteware.shvPassword
        const defaultLanguageFromSites = converted._meta.siteware.defaultLanguage
        const languageArray = JSON.stringify(converted._meta.siteware.languageArray)
        localStorage.setItem('defaultUser', newUser)
        if (newUser !== user) {
          localStorage.setItem('userName', newUser)
          localStorage.setItem('shvPath', installationShvPath)
          localStorage.setItem('password', newPassword)
          this.disconnect()
          location.reload()
        }
        if (defaultLanguage !== defaultLanguageFromSites) {
          localStorage.setItem('defaultLanguage', defaultLanguageFromSites)
          localStorage.setItem('languageArray', languageArray)
          localStorage.setItem('userLanguage', defaultLanguageFromSites)
          this.disconnect()
          location.reload()
        }
      } else if (!localStorage.getItem('userLanguage')) {
        localStorage.setItem('userLanguage', defaultLanguage)
      }
      localStorage.setItem('relogManually', 'false')

      const svgPath = isCha ? 'sites/test/bel/cha/tram/anderlues/_files/cha_all_zones.svg' : `${this.path.replace('shv', sitesLocation)}/_files/site.svg`
      const svg = await this.client.sendMessage(
        svgPath,
        'read',
        undefined
      ).catch(error => {
        console.log('no sites svg', error)
      })

      console.log('show me svg', svg)
      // console.log('SVG received?', svg.result().value, svg.result().toString())
      // console.log('asking for shv version')
      const shvVersion = await this.client.sendMessage(
        this.path,
        'shvVersion',
        undefined
      )
      // console.log('asking for git version')
      const gitCommit = await this.client.sendMessage(
        this.path,
        'gitCommit',
        undefined
      )
      // console.log('asking for git versio')
      this.store.commit('tygroshka/shvVersion', shvVersion.result().value.length > 0 ? shvVersion.result().value : '3')
      this.store.commit('tygroshka/gitCommit', gitCommit.result().value)
      let usedStyles = styles
      await this.client.sendMessage(
        `${this.path.replace('shv', sitesLocation)}/_files/visuStyle.json`,
        'read',
        undefined
      ).then(result => {
        usedStyles = result
        this.readStyles(usedStyles, 'shv')
      }).catch(error => {
        this.readStyles(usedStyles, 'file')
        console.log('error reading SHV styles', error)
      })
      const translationsObject = translations.reduce((result, translationPiece) => {
        const lang = localStorage.getItem('userLanguage') || 'en'
        result[translationPiece.key] = translationPiece[lang]
        return result
      }, {})
      await this.client.sendMessage(
        `${this.path.replace('shv', sitesLocation)}/_files/site-l10n.csv`,
        'read',
        undefined
      ).then(result => {
        let csvString = '' // this.readFileOrString((result))
        if (result.result().type === 12) { // type BLOB
          const binaryData = result.result().value
          const decoder = new TextDecoder('utf-8')
          const decodedBinaryData = decoder.decode(binaryData)
          csvString = decodedBinaryData
        } else {
          csvString = result.result().value
        }
        const header = csvString.split('\n')[0]
        const lang = localStorage.getItem('userLanguage') || 'en'
        const currentLangColumn = header.split(';').findIndex(c => new RegExp(`${lang}.*`).test(c))
        csvString.split('\n').forEach(csvRow => {
          const transArray = csvRow.split(';')
          const transKey = transArray[0]
          const translationValue = transArray[currentLangColumn]
          translationsObject[transKey] = translationValue
        })
      }).catch(error => {
        console.log('NO CUSTOM TRANSLATIONS!!!!!!!!!', error)
      })
      this.store.commit('tygroshka/translations', translationsObject)
      // console.log('translation object', translationsObject)
      if (isCha) {
        const fetchedSvg = await fetch('/cha-all.svg')
        const svgResult = await fetchedSvg.text()
        this.store.commit('tygroshka/svgImage', svgResult)
      } else if (svg) {
        if (svg.result().type === 12) { // type BLOB
          this.store.commit('tygroshka/svgImage', svg.result().toString())
        } else {
          this.store.commit('tygroshka/svgImage', svg.result().value)
        }
      }

      // console.log('returning svg', svg.result())
      // const opcVersion = await this.client.sendMessage(`${this.path.replace('shv', 'sites')}/_meta/type`, 'get', undefined)
      this.store.commit('setOpcVersion', 'DepotG3')

      // console.log('trying to get type info', moment().format('MMMM Do YYYY, h:mm:ss a'), path)
      const typeInfo = await this.client.sendMessage(`${shvPath.replace('shv', sitesLocation)}/_files/typeInfo.cpon`, 'read', undefined)
      const finalTypeInfo = this.readFileOrString((typeInfo))

      console.log('type info', typeInfo.result().type, typeInfo.result().toCpon(), RpcValue.fromCpon(typeInfo.result().value))
      const devices = readContentFull(finalTypeInfo.devicePaths)
      const nodes = readContentFull(finalTypeInfo.deviceProperties || finalTypeInfo.deviceDescriptions)
      const types = readContentFull(finalTypeInfo.types)
      const blacklistedPaths = readContentFull(finalTypeInfo.blacklistedPaths)
      const sortedKeys = Object.keys(devices).sort((a, b) => a.length - b.length)
      const assetsByPath = Object.keys(devices).reduce((result, path) => {
        const deviceType = devices[path]
        const nodesForAsset = filterNodesByDeviceType(nodes, deviceType)
        const deviceTypeNodes = nodesForAsset.nodes[deviceType]
        // set device type to super device type when necessary
        result[path] = {
          variables: new Map(),
          nodes: deviceTypeNodes,
          shvPath: path,
          assetType: {
            deviceType: nodes[deviceType]?.restrictionOfDevice ? nodes[deviceType].restrictionOfDevice : deviceType,
            methods: deviceTypeNodes[0].methods
          }
        }
        return result
        // add nodes to every asset path
      }, {})
      const assetsTree = sortedKeys.reduce((result, path) => {
        // replace deviceType with proper object created from nodes and types
        const deviceType = devices[path]
        // ignore empty keys, like "", empty key means root element
        if (path.length > 0) {
          result = objectSet(result, path, {
            assetInfo: {
              deviceType: deviceType
            }
          })
        }
        return result
      }, {})
      const shvData = {
        devices,
        assetsTree,
        assetsByPath,
        types,
        blacklistedPaths: Object.keys(blacklistedPaths)
      }
      this.store.commit('tygroshka/shvData', shvData)
      // console.log('shvData commited', shvData)
      console.log('getting lgos')
      await this.getAllValues()
      this.store.commit('tygroshka/shvData', shvData)
      this.store.commit('tygroshka/assetsLoaded', true)
      this.checkDisconnectedSystemsSHVv3()

      // console.log('shv data', shvData)
    })

    this.client.received.subscribe((message: RpcMessage) => {
      // console.log('message params', message.params(), message.params().meta)
      if (!message.params()) {
        // console.log('WHAT???? no META?', message)
        return
      }
      // console.log('message passed', message)

      if (message.shvPath()) {
        // console.log('solving standard message', message)
        const shvPath: string = message.shvPath().toString().replace(/"/g, '')
        if (message.params().meta && message.params().meta[8]) {
          this.resolveResponse(shvPath, readContentFull(message.params().value), message.params().meta[8].value.epochMsec)
        } else {
          this.resolveResponse(shvPath, readContentFull(message.params().value), Date.now())
          // console.log('message', message.params())
        }
      } else {
        console.log('MSG without shv path???????', message)
      }
    })
  }

  public async getAllValues () {
    const shvPath = this.store.state.tygroshka.shvPath
    const getSnapshotPath = `${shvPath}/.app/history`
    const existingMethods: any = await this.client.sendMessage(getSnapshotPath, 'dir', undefined)
      .then(result => result)
      .catch(error => {
        console.log('error reading getSnapshot methods', error)
        return undefined
      })
    console.log('existing methods', existingMethods)
    const readMethods: any = existingMethods ? Object.values(readContentFull(existingMethods.result().value)) : []
    const getSnapshotExist = readMethods.some(a => a[1] === 'getSnapshot')
    const historyLocation = 'shv'

    const params = `{
        "withPathsDict": false,
        "since":"last",
        "recordCountLimit": 10000,
        "withSnapshot": true
    }`
    let logs: any
    if (getSnapshotExist) {
      logs = await this.client.sendMessage(getSnapshotPath, 'getSnapshot', undefined)
    } else {
      console.log('history location', historyLocation, shvPath)
      logs = await this.client.sendMessage(shvPath.replace('shv', historyLocation), 'getLog', params)
    }

    const parsedLogs = readContentFull(logs.result().value)
    console.log('logs from shv', parsedLogs)
    if (getSnapshotExist) {
      for await (const logRow of Object.entries(parsedLogs)) {
        // console.log('log row', logRow)
        const time = 'snapshot'
        const shvPath = logRow[0]
        const value = logRow[1]
        await this.resolveResponse(shvPath, value, time)
        // const [time, shvPath, value] = logRow
      }
    } else {
      for await (const logRow of Object.values(parsedLogs)) {
        // console.log('log row', logRow)
        // const [time, shvPath, value] = logRow
        const time = logRow[0]
        const shvPath = logRow[1]
        const value = logRow[2]
        await this.resolveResponse(shvPath, value, time.epochMsec)
      }
    }
  }

  public async checkDisconnectedSystemsSHVv3 (reloadValues?) {
    const assets: any = this.store.state.tygroshka.shvData.assetsByPath
    // @ts-ignore
    const systems: any = Object.values(assets).filter(a => a.assetType.deviceType === 'System_G3')

    for (const system of systems) {
      const disconnected = system.variables.get('plcDisconnected')
      if (system.variables.get('plcDisconnected')) {
        // set all assets under system to N/Array
        // @ts-ignore
        // TODO !!!! check system children in case there are more PLCs
        const systemChildren = Object.values(assets) // .filter(a => a.systemPath === `${system.shvPath}/`)
        console.log('system children', systemChildren)
        for (const asset of systemChildren) {
          // @ts-ignore
          if (asset.assetType.deviceType !== 'System_G3') {
            // @ts-ignore
            asset.variables.forEach((value, key, map) => map.set(key, 'N/A'))
          }
        }
        this.store.commit('tygroshka/plcDisconnected', true)
      } else {
        if (reloadValues) {
          this.getAllValues()
        }
      }
    }
  }

  public sendMessage (path: string, method: string, options: string) {
    // console.log('sending to SHV', path, method, options)
    return this.client.sendMessage(path, method, options)
  }

  public disconnect (): void {
    if (this.client) {
      this.client.closeSocket()
    }
  }

  private readFileOrString (value) {
    let finalValue: any
    if (value.result().type === 12) {
      const binaryData = value.result().value
      const decoder = new TextDecoder('utf-8')
      const decodedBinaryData = decoder.decode(binaryData) // .replace((/|\r\n|\n|\r/gm), '')
      finalValue = RpcValue.fromCpon(value.result().value).value
    } else {
      finalValue = RpcValue.fromCpon(value.result().value).value
    }
    return finalValue
  }

  private async readStyles (value: RpcMessage, source: string): Promise<any> {
    if (source === 'file') {
      console.log('styles from file', value)
      this.store.commit('tygroshka/styles', value)
    } else if (source === 'shv') {
      const finalStyles = this.readFileOrString((value))
      const convertedStyles = readCponLikeObjectFull(finalStyles)
      this.store.commit('tygroshka/styles', convertedStyles)
    }
  }

  private async getRoles () {
    const roles = await this.sendMessage('.broker/currentClient', 'userRoles', '')
    const remappedRoles = roles.result().value.map(role => role.toString().replace(/"/g, ''))
    this.store.commit('tygroshka/userRoles', remappedRoles)
  }

  public resolveResponseOutside (shvPath, message) {
    const readValue = readContentFull(message.result().value)
    this.resolveResponse(shvPath, readContentFull(message.result().value), Date.now())
  }

  private async resolveResponse (shvPath: string, shvValue: any, time: any) {
    // console.log('incoming value', shvPath, shvValue, time)
    const blacklistedPaths = this.store.state.tygroshka.shvData.blacklistedPaths
    if (!shvPath) { return }

    const subscribePath = this.store.state.tygroshka.shvPath

    // console.log('subscribe path', subscribePath, shvPath.replace(`${subscribePath}/`, ''))
    const relativeAssetPath = shvPath.replace(`${subscribePath}/`, '')

    const foundAsset = getDeviceAndAttributePath(relativeAssetPath, this.store.state.tygroshka.shvData.assetsByPath)
    // console.log('found asset', foundAsset, shvPath)

    if (foundAsset) {
      const [asset, attribute] = foundAsset
      // find in array of nodes
      // const deviceType = asset.assetType.deviceType
      const assetNodeIndex = asset.nodes.findIndex(a => a.name === attribute)
      const assetNode = asset.nodes[assetNodeIndex]
      // logs can return non existing nodes for historing reasons or because they return all values in bitfield
      if (assetNode) {
        // IMPORTANT - setting value in assetsByPath!!!! - saving new value incomging from SHV
        // convert asset to have shvPath, variables Map {"variableName": value} and deviceType
        // convert bitfields to variables
        const types = this.store.state.tygroshka.shvData.types
        const translations: any = this.store.state.tygroshka.translations
        console.log('shv path before parsing', shvPath, assetNode)
        const parsedNode = parseStatusNode(attribute, {
          typeName: assetNode.typeName,
          alarm: assetNode.alarm,
          value: shvValue
        }, types, translations)
        // call resolveAlarms with proper parameteres
        //
        const variables = parsedNode
          .reduce((result, [name, node]) => {
            const attributePath = node.parentType ? `${node.parentType}/${name}` : name
            result[attributePath] = node.value
            return result
          }, {})

        for await (const [name, info] of parsedNode) {
          // console.log('checking property for alarm', info, name)
          const blacklisted = blacklistedPaths.includes(`${relativeAssetPath}/${name}`)
          if (blacklisted) {
            continue
          }
          const updatedVariables = { [name]: info.value }
          // console.log('variable is alarm!!!', asset, relativeAssetPath, name, info, new Map(Object.entries(updatedVariables)))
          const shvPathArray = relativeAssetPath.split('/')
          await this.resolveAlarms(
            info.value,
            time,
            {
              name: shvPathArray[shvPathArray.length - 2],
              deviceType: asset.assetType.deviceType,
              shvPath: relativeAssetPath,
              ...asset
            },
            new Map(Object.entries(updatedVariables)),
            info.alarm,
            info
          )
        }
        // console.log('ready to emit', { variables, shvPath, deviceType: asset.assetType.deviceType })
        const mapVariables = new Map(Object.entries(variables))
        // Vue.set(asset, 'variables', { ...asset.variables, ...variables })
        // console.log('asset variables and new ones', asset.variables, variables)
        const newVariables = new Map([...asset.variables, ...mapVariables])
        // console.log('variables change', shvPath, asset, mapVariables, newVariables, asset.variables)
        // console.log('variables', newVariables)
        const shvValues = { [attribute]: shvValue }
        const newShvValues = { ...asset.shvValues, ...shvValues }

        const assetResult = {
          variables: newVariables,
          shvValues: newShvValues,
          shvPath: asset.shvPath, // .replace(`${subscribePath}/`, ''),
          deviceType: asset.assetType.deviceType
        }

        if (assetResult.deviceType === 'System' || assetResult.deviceType === 'System_G3') {
          const plcConnected = asset.variables.get('plcDisconnected') === true && newVariables.get('plcDisconnected') === false
          const plcDisconnected = asset.variables.get('plcDisconnected') === false && newVariables.get('plcDisconnected') === true
          Vue.set(asset, 'variables', newVariables)
          if (plcConnected || plcDisconnected) {
            this.checkDisconnectedSystemsSHVv3('reloadValues')
          }
        }
        Vue.set(asset, 'variables', newVariables)
        Vue.set(asset, 'shvValues', newShvValues)

        this.assetChange.emit({ asset: assetResult, attribute: attribute })
        // console.log('path and value', shvPath, shvValue, assetResult, attribute)
      }
    }
    // console.log('shv data', this.store.state.tygroshka.shvData)
    // this.store.commit('tygroshka/nodesInAsset', { shvPath: shvPath, shvValue: shvValue })
  }

  private async resolveAlarms (shvValue, time, asset, updatedVariable, alarm, node) {
    // TODO how to deal with `status` part???
    // add alarm
    const variableName = Array.from(updatedVariable.keys())[0]
    const allAlarms = this.store.getters['tygroshka/alarms']
    const existingAlarm = allAlarms.find(alarm => {
      return alarm.name === variableName && new RegExp(`${alarm.shvPath}.*`).test(asset.shvPath) // alarm.shvPath === asset.shvPath
    })
    if (shvValue && alarm) {
      // avoid duplication of alarms
      if (!existingAlarm) {
        await this.store.dispatch('tygroshka/addAlarm', {
          asset: asset,
          shvPath: asset.shvPath,
          assetName: asset.name,
          name: variableName,
          severity: alarm,
          node: node,
          timestamp: typeof time === 'string' ? time : new Date(time).toLocaleString()
        })
      }
      // remove alarm
    } else {
      await this.store.dispatch('tygroshka/removeAlarm', { asset: asset, variable: variableName })
    }
    // this.assetChange.emit(asset)
  }

  private fullShvPath (relativePath: string): string {
    return `${this.path}/${relativePath}`
  }
}
