import { getInnerElement, solveRadioBeamsG3 } from './tools'

export const requestorData = (element, state, assetsData, options, attribute) => {
  const properties = [
    'vehicleId',
    'lineNumber',
    'routeCode',
    'serviceNumber',
    'vehicleType',
    'direction',
    'category',
    'stand',
    'side',
    'remoteCommand',
    'readyToStart'
  ]

  properties.forEach(property => {
    const innerElement = getInnerElement(element, property)
    if (innerElement && state.get('vehicleDetected')) {
      innerElement.setAttribute('visibility', 'hidden')
      const textToAdd = state.get('vehicleDetected')[property]
      innerElement.textContent = state.get('vehicleDetected')[property]
      innerElement.setAttribute('visibility', 'visible')
    }
  })

  // blinkOnVehicleDetected(element, state, 'vehicleId', attribute)
  solveRadioBeamsG3(element, state)
}
