import Vue from 'vue'
import App from './App.vue'
import IdleVue from 'idle-vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

// Tygroshka module
import Tygroshka from '../siteware-lib/src/tygroshka'

declare module 'vue/types/options' {
  interface ComponentOptions<V extends Vue> {
    i18n?: string | string[],
    vuetify?: string | string[]
  }
}

Vue.config.productionTip = false

fetch(process.env.BASE_URL + 'config.json')
  .then(result => result.json())
  .then(config => {
    Vue.prototype.$config = config
    Vue.use(IdleVue, {
      store,
      idleTime: config.idleTime || 60 * 60 * 1000,
      events: ['mousemove', 'keydown', 'mousedown', 'touchstart', 'click'],
      startAtIdle: false
    })
    Vue.use(Tygroshka, { store })
    new Vue({
      router,
      i18n,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  })
