import { getInnerElement } from '../tools'
import store from '../../../services/tygroshka-store'

export const ppiCha = (element, state) => {
  // console.log('ppif', element, state)
  const getShvPath = (element) => {
    if (element.length) {
      return Array.from(element).map((e: Element) => {
        return e.getAttribute('shvpath')
      })
    } else {
      return [element.getAttribute('shvpath')]
    }
  }
  const shvpatharray = getShvPath(element)
  const chidtext = getInnerElement(element, 'ppitext')
  chidtext.textContent = ''
  const pathstring = shvpatharray.toString()
  const tid11 = pathstring.includes('tid01.1')
  const tid12 = pathstring.includes('tid01.2')
  if (pathstring.includes('tid01.1')) {
    if (state.get('firstText') === true) {
      chidtext.textContent = 'JNCT'
    } else if (state.get('secondText') === true) {
      chidtext.textContent = 'DEPT'
    } else if (state.get('thirdText') === true) {
      chidtext.textContent = 'REBR'
    } else {
      chidtext.textContent = ''
    }
  }
  if (pathstring.includes('tid01.2')) {
    if (state.get('firstText') === true) {
      chidtext.textContent = 'REBR'
    } else if (state.get('secondText') === true) {
      chidtext.textContent = 'SURC'
    } else if (state.get('thirdText') === true) {
      chidtext.textContent = 'TXT3'
    } else {
      chidtext.textContent = ''
    }
  }
}
