import { getInnerElement, checkElementsNumber } from './tools'

export const pmmG3 = (element, state) => {
  checkElementsNumber(element)
  const [right, left, leverInSocket] = [
    getInnerElement(element, 'posRight'),
    getInnerElement(element, 'posLeft'),
    getInnerElement(element, 'leverInSocket')
  ]
  right.setAttribute('visibility', 'hidden')
  left.setAttribute('visibility', 'hidden')

  if (leverInSocket) {
    leverInSocket.setAttribute('visibility', 'hidden')

    if (state.get('leverInSocket') === true) {
      leverInSocket.setAttribute('visibility', 'visible')
    }
  }

  if (state.get('posLeft') === true) {
    left.setAttribute('visibility', 'visible')
  } else if (state.get('posRight') === true) {
    right.setAttribute('visibility', 'visible')
  }
}
