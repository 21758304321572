export const zoneState = (state, opcVersion?, zone?) => {
  if (opcVersion && opcVersion === 'DepotG3') {
    const zoneState = state.get('StatusZone/zoneState') || state.get('zoneState')
    let color = ''
    if (zoneState === 'N/A') {
      color = 'purple'
    } else if (zoneState?.toLowerCase() === 'emergencyinhibit') {
      color = '#d40000'
    } else if (zoneState?.toLowerCase() === 'allblocked') {
      color = '#ffcc00'
    } else if (zoneState?.toLowerCase() === 'normal') {
      color = 'green'
    } else {
      color = 'black'
    }
    return { text: zoneState || 'Unknown', color: color }
  } else {
    const emergencyInhibit = state.get('emergencyInhibit') ? 'emergencyInhibit' : ''
    const allBlocked = state.get('allBlocked') ? 'allBlocked' : ''
    const normal = state.get('normal') ? 'normal' : ''
    const degraded = state.get('degraded') === true ? 'degraded' : null
    let text = `${emergencyInhibit}${allBlocked}${normal}`
    if (state.get('normal') === 'N/A') {
      text = 'N/A'
    }

    let color = ''
    if (state.get('emergencyInhibit')) {
      color = 'red'
    } else if (state.get('allBlocked')) {
      color = '#ffcc00'
    } else if (state.get('normal')) {
      color = 'black'
    } else {
      color = 'black'
      text = 'unknown'
    }
    return { text: text, color: color, degraded: degraded ? 'degraded' : '' }
  }
}
