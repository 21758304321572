<template>
  <div>
    <div v-if="externalWindowContent.type === 'requestMemory'">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th v-for="(value, index) in tableHead" :key="index">
                {{ translations[`static.requestMemory.${value}`] }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(line, index) in tableBody" :key="index">
              <td v-for="(value, index) in line" :key="index">
                {{ formatValue(index, value) }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div v-if="externalWindowContent.type === 'matrix'">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th>{{ translations['static.Align'] ? translations['static.Align'] : 'Align' }}</th>
              <th>{{ translations['static.Text'] ? translations['static.Text'] : 'Text' }}</th>
              <th>{{ translations['static.Blinking'] ? translations['static.Blinking'] : 'Blinking' }}</th>
              <th>{{ translations['static.Color'] ? translations['static.Color'] : 'Color' }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(line, index) in content" :key="index">
              <td>
                {{ line.align }}
              </td>
              <td>
                {{ line.text }}
              </td>
              <td>
                {{ line.blinking }}
              </td>
              <td>
                {{ line.color }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import { getTranslation } from '../components/property-sheets/shv-3/utils'
import moment from 'moment'

export default Vue.extend({
  name: 'external-window',

  data () {
    return {
    }
  },

  mounted () {
  },

  methods: {
    getTranslation (variableName, translationType = 'label') {
      return getTranslation(this.translations, variableName, translationType, this.node, this.currentAsset)
    },
    deviceByPath (path) {
      if (path.length > 0) {
        return this.getPath(this.assetsObject, path)
      } else {
        return this.assetsObject
      }
    },
    formatValue (index, value) {
      if (value === 'timeCreated') {
        return value
      }
      return index === 0 ? moment(value * 1000).utc().format('DD.MM. YYYY - HH:mm:ss') : value
    },
    getPath (obj, props) {
      if (props.length === 0) return obj
      return this.getPath(obj[props.shift()], props)
    }
  },

  computed: {
    ...mapState('tygroshka', [
      'showSHVPropertyName',
      'shvData',
      'currentAssetShvPath',
      'translations'
    ]),
    externalWindowContent () {
      return this.$store.state.externalWindowContent
    },
    content () {
      return this.externalWindowContent.content
    },
    tableHead () {
      return this.content ? this.content[0] : []
    },
    tableBody () {
      return this.content ? this.content.slice(1, this.content.length) : []
    },
    currentAsset () {
      return this.shvData.assetsByPath[this.path.join('/')]
    }
  }
})
</script>
