import { getInnerElement } from './tools'

export const vecomControllerG3 = (element, state) => {
  const rack = getInnerElement(element, 'rack')
  if (rack) {
    if (state.get('errorCommon') || state.get('errorCommunication')) {
      rack.setAttribute('fill', 'red')
    } else {
      rack.setAttribute('fill', '#E1E1E1')
    }
  }
}
