<template>
  <v-tooltip
    :disabled="description.length === 0"
    top
    v-model="showTooltip"
  >
    <template v-slot:activator="{ on, attrs }">
      <td v-on="on" v-bind="attrs">
        {{ getTranslation(variableName, 'label') }}
        <v-icon v-if="description.length > 0" small @click="showTooltip = !showTooltip">mdi-help-circle</v-icon>
      </td>
    </template>
    <span>
      {{ getTranslation(variableName, 'description') }}
    </span>
  </v-tooltip>
  <!-- <td v-if="(activeAsset && assetsObject) || currentAssetShvPath"> -->
  <!--   {{ getTranslation(variableName) }} -->
    <!-- {{ $t(`variable.${deviceType}.${variableName}`) }} <strong>{{ shvPropertyName }}</strong> -->
    <!-- {{ getTranslation(variableName) }} <strong>{{ shvPropertyName }}</strong> -->
    <!-- <v-icon v-if="description.length > 0" small @click="showTooltip = !showTooltip">mdi-help-circle</v-icon> -->
  <!-- </td> -->
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import propertySheetFunctions from './property-sheets/property-sheet-functions.es6'
import { getTranslation } from '../components/property-sheets/shv-3/utils'

export default Vue.extend({
  name: 'tooltip-td',
  props: {
    variableName: {
      default: ''
    },
    deviceType: {
      default: ''
    },
    node: {
      default: ''
    },
    description: {
      default: ''
    }
  },

  mixins: [propertySheetFunctions],

  data () {
    return {
      showTooltip: false
    }
  },

  mounted () {
  },

  methods: {
    getTranslation (variableName, translationType = 'label') {
      return getTranslation(this.translations, variableName, translationType, this.node, this.currentAsset)
    },
    deviceByPath (path) {
      if (path.length > 0) {
        return this.getPath(this.assetsObject, path)
      } else {
        return this.assetsObject
      }
    },
    getPath (obj, props) {
      if (props.length === 0) return obj
      return this.getPath(obj[props.shift()], props)
    }
  },

  computed: {
    ...mapState('tygroshka', [
      'showSHVPropertyName',
      'shvData',
      'currentAssetShvPath',
      'translations'
    ]),
    path () {
      return this.currentAssetShvPath ? this.currentAssetShvPath.split('/') : []
    },
    currentAsset () {
      return this.shvData.assetsByPath[this.path.join('/')]
    },
    shvPropertyName () {
      if (this.showSHVPropertyName) {
        return `[${this.variableName}]`
      } else {
        return ''
      }
    }
  },

  watch: {
    showTooltip (value) {
      if (value) {
        // setTimeout(() => {
        // this.showTooltip = false
        // }, 3000)
      }
    }
  }
})
</script>
