<template>
  <visual-panel></visual-panel>
</template>

<style scoped>

</style>

<script>
import Vue from 'vue'
import VisualPanel from '../../siteware-lib/src/components/visual-panel'

export default Vue.extend({
  name: 'visual',
  components: {
    VisualPanel
  }
})
</script>
