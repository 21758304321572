<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <component
            v-for="([name, node], index) in relevantNodes"
            :key="index"
            :name="name"
            :description="name"
            :variables="variables"
            :deviceType="currentObject.assetInfo.deviceType"
            :node="node"
            :fullShvPath="fullShvPath"
            :types="types"
            :is="chooseComponent(node)"
          >
          </component>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<style scoped>

</style>

<script>
import Vue from 'vue'
import NumberRow from './shv-3/number-row.vue'
import DefaultRow from './shv-3/default-row.vue'
import AlarmRow from './shv-3/alarm-row.vue'
import MapRow from './shv-3/map-row.vue'
import StringRow from './shv-3/string-row.vue'
import RequestMemoryRow from './shv-3/request-memory-row.vue'
import SetRow from './shv-3/set-row.vue'
import Matrix from './shv-3/matrix.vue'
import { mapState } from 'vuex'
import { parseStatusNode } from './shv-3/utils.js'

export default Vue.extend({
  name: 'default-property-sheet',
  props: {
    assetNodes: {},
    currentObject: {},
    variables: {},
    currentAssetPath: {},
    fullShvPath: undefined,
    types: {}
  },

  data () {
    return {
      showDebugDetail: false
    }
  },

  updated () {
    console.log('UPDATED!!!!!!!!!!')
  },

  computed: {
    ...mapState('tygroshka', [
      'translations',
      'shvData'
    ]),

    relevantNodes () {
      const blacklistedPaths = this.shvData.blacklistedPaths
      return this.parsedNodes.filter(([_name, n]) => {
        const fullNodePath = `${this.currentAssetPath}/${n.fullNodePath}`
        const blacklisted = blacklistedPaths.includes(fullNodePath)
        // console.log('blacklist', blacklisted, fullNodePath, n)
        return (n.typeName || n.unit) && !blacklisted
      })
    },

    // transform all status nodes to normal nodes through bitfield
    parsedNodes () {
      // console.log('ASSET NODES', this.assetNodes, this.variables)
      return this.assetNodes.flatMap(node => {
        return parseStatusNode(node.name, node, this.types, this.translations)
      })
    }
  },

  methods: {
    splitAndSlice (nodePath) {
      const splittedPath = nodePath.split('/')
      return splittedPath.slice(1, splittedPath.length).join('/')
    },

    chooseComponent (node) {
      const blacklistedPaths = this.shvData.blacklistedPaths
      const isNumber = /Decimal/.test(node.typeName) || node.typeName === 'Int' || node.typeName === 'UInt'
      const hasSetMethod = node.methods.filter(m => m.name === 'set').length > 0
      const setMethodPath = `${this.currentAssetPath}/${node.fullNodePath}/method/set`
      const blacklistedSetPath = blacklistedPaths.includes(setMethodPath)
      const nodeTypeName = node.nodeType?.name || node.nodeType?.type || node.typeName
      // return 'number-row'
      console.log('node name', node.name, node)
      if (node.name === 'requestMemory') {
        return 'request-memory-row'
      } else if (node.label === 'Matrix data') {
        return 'matrix'
      } else if (nodeTypeName === 'String') {
        return 'default-row' // return 'string-row'
      } else if (hasSetMethod && !blacklistedSetPath) {
        return 'set-row'
      } else if (node.alarm) {
        return 'alarm-row'
      } else if (isNumber) {
        return 'number-row'
      } else if (node.nodeType && (nodeTypeName === 'VehicleData')) {
        return 'map-row'
      } else {
        return 'default-row'
      }
    }
  },

  components: {
    NumberRow,
    DefaultRow,
    AlarmRow,
    StringRow,
    MapRow,
    RequestMemoryRow,
    Matrix,
    SetRow
  }
})
</script>
