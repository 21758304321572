import { getInnerElement } from '../tools'

export const pdCha = (element, state) => {
  // console.log('solving panto', element, state)
  const setAttributes = (elem, attributes) => {
    if (elem) {
      Object.entries(attributes).forEach(([key, value]) => {
        elem.setAttribute(key, value)
      })
    }
  }

  const defaultAttributes = {
    fill: '#000000',
    'fill-opacity': '0.241265',
    stroke: '#8e8e8e',
    'stroke-width': '3',
    'stroke-linejoin': 'bevel',
    'stroke-miterlimit': '4',
    'stroke-dasharray': 'none',
    'stroke-opacity': '1'
  }

  const detectorA = getInnerElement(element, 'detectorA')
  const detectorB = getInnerElement(element, 'detectorB')

  setAttributes(detectorA, defaultAttributes)
  setAttributes(detectorB, defaultAttributes)

  if (state.get('pantographDetectedA') === true || state.get('errorContactA') === true || state.get('sequenceError') === true) {
    const color = state.get('errorContactA') || state.get('sequenceError') ? 'red' : 'orange'
    setAttributes(detectorA, { fill: color, 'fill-opacity': '1', stroke: 'black' })
  }

  if (state.get('pantographDetectedB') === true || state.get('errorContactB') === true || state.get('sequenceError') === true) {
    const color = state.get('errorContactB') || state.get('sequenceError') ? 'red' : 'orange'
    setAttributes(detectorB, { fill: color, 'fill-opacity': '1', stroke: 'black' })
  }
}
