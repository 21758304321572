import { getInnerElement, solveRadioBeamsG3 } from './tools'

export const vecomLoopG3 = (element, state) => {
  const lineNumberEl = getInnerElement(element, 'lineNumber')
  const tramIdEl = getInnerElement(element, 'tramId')
  const remoteCmdEl = getInnerElement(element, 'remoteCmd')
  solveRadioBeamsG3(element, state)

  if (lineNumberEl && state.get('vehicleDetected')) {
    lineNumberEl.setAttribute('visibility', 'hidden')
    if ((state.get('vehicleDetected').lineNumber)) {
      lineNumberEl.textContent = state.get('vehicleDetected').lineNumber
      lineNumberEl.setAttribute('visibility', 'visible')
    }
  }
  if (tramIdEl && state.get('vehicleDetected')) {
    tramIdEl.setAttribute('visibility', 'hidden')
    if ((state.get('vehicleDetected') || state.get('vehicleDetected').vehicleId === 0) && tramIdEl) {
      tramIdEl.textContent = state.get('vehicleDetected').vehicleId
      tramIdEl.setAttribute('visibility', 'visible')
    }
  }
  if (remoteCmdEl && state.get('vehicleDetected')) {
    remoteCmdEl.setAttribute('visibility', 'hidden')
    if ((state.get('vehicleDetected') || state.get('vehicleDetected').remoteCommand === 0) && remoteCmdEl) {
      remoteCmdEl.textContent = state.get('vehicleDetected').remoteCommand
      remoteCmdEl.setAttribute('visibility', 'visible')
    }
  }
}
