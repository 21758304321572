
export const setVisibility = (elements, visibility) => {
  for (const el of elements) {
    el.setAttribute('visibility', visibility)
  }
}

const hideAll = (elements) => {
  setVisibility(elements, 'hidden')
}

export const getInnerElement = (element, name) => {
  if (element.length) {
    return getInnerElementInArray([...element], name)
  } else {
    const path = `[chid='${name}']`
    const el = element.querySelector(path)
    if (!el) {
      // console.warn('[svg][missing element]', path)
    }
    return el
  }
}

export const blinkOnVehicleDetected = (element, state, checkAttribute, attribute) => {
  const transceiver = getInnerElement(element, 'transceiver')
  transceiver.setAttribute('fill', '#FFFFFFFF')
  if (attribute !== 'vehicleDetected') return

  const vehicleDetectedObj = state.get('vehicleDetected')
  const vehicleDetected = vehicleDetectedObj && (vehicleDetectedObj[checkAttribute] || vehicleDetectedObj[checkAttribute] === 0)

  if (transceiver) {
    if (vehicleDetected) {
      transceiver.setAttribute('fill', 'yellow')
      setTimeout(function () {
        transceiver.setAttribute('fill', '#FFFFFFFF')
      }, 1000)
    }
  }
}

export const solveRadioBeams = (element, state) => {
  const radioBeamsEl = getInnerElement(element, 'radioBeams')
  if (radioBeamsEl) {
    radioBeamsEl.setAttribute('visibility', 'hidden')
    if (state.get('activeTransceiversCommunication')) {
      radioBeamsEl.setAttribute('visibility', 'visible')
    }
  }
}

export const solveRadioBeamsG3 = (element, state) => {
  const radioBeamsEl = getInnerElement(element, 'radioBeams')
  if (radioBeamsEl) {
    radioBeamsEl.setAttribute('visibility', 'hidden')
    if (state.get('activeTramCommunication')) {
      radioBeamsEl.setAttribute('visibility', 'visible')
    }
  }
}

export const checkElementsNumber = (element) => {
  if (element.length > 0) {
    console.log('More than 1 element with the same SHV PATH', element)
  }
}

export const getInnerElementInArray = (elements, name) => {
  const elementsArray = Array.from(elements)
  const innerElement = elementsArray.map((e: Element) => e.querySelector(`[chid="${name}"]`)).filter(e => e)
  if (innerElement.length === 1) {
    return innerElement[0]
  } else if (innerElement.length > 1) {
    console.warn('[svg][more elements with same chid]', elements, name)
    return innerElement
  } else if (innerElement.length === 0) {
    // console.warn('[svg][no element found]', elements, name)
  }
}
