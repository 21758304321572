// importing main configs for reading from SHV

export const getDeviceAndAttributePath = (pathFromShv: string, assetsByShvPath: any) => {
  const shvPath = pathFromShv.split('/')
  const attributeCandidate: string[] = []

  while (shvPath.length > 0) {
    const foundDevice = assetsByShvPath[shvPath.join('/')]

    if (foundDevice) return [foundDevice, attributeCandidate.reverse().join('/')]
    const restOfPath: string = shvPath.pop() || 'end'
    attributeCandidate.push(restOfPath)
  }
  // client will get at least some info in attribute that device was not found
  return null
}
