<template>
  <tr :key="name">
    <TooltipTd
      :variableName="name"
      :description="description"
      :deviceType="deviceType"
      :node="node"
      >
    </TooltipTd>
    <td align="right">
      <a href="#" @click="setExternal()"><v-icon>mdi-eye</v-icon></a>
    </td>
  </tr>
</template>

<script>
import Vue from 'vue'
import TooltipTd from '../../tooltip-td.vue'
import { readAttributeValue } from '../../../helpers/utils.ts'

export default Vue.extend({
  name: 'request-memory',
  props: {
    name: {},
    node: {},
    types: {},
    variables: {},
    description: {},
    deviceType: {},
    unit: {}
  },

  components: {
    TooltipTd
  },

  methods: {
    setExternal () {
      this.$store.commit('setExternalWindow', true)
      this.$store.commit('setExternalWindowContent', {
        content: this.resolvedValue(), type: 'requestMemory'
      })
    },

    resolvedValue () {
      const value = readAttributeValue(this.node, this.name, this.variables)
      const csvArray = value.split('\n').filter(line => line.length > 1).map(line => {
        return line.split(',')
      })
      return csvArray
    }
  },

  watch: {
    variables (value) {
      this.$store.commit('setExternalWindowContent', {
        content: this.resolvedValue(), type: 'requestMemory'
      })
    }
  }
})
</script>
