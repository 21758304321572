import { getInnerElement } from './tools'

export const disconRFID = (element, state) => {
  const detectedId = getInnerElement(element, 'detectedId')
  if (detectedId) {
    if (state) {
      detectedId.textContent = state.get('detectedId')
    }
  }
}
