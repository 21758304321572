import { getInnerElement } from './tools'

export const cabinetG3 = (element, state) => {
  const openedDoor = getInnerElement(element, 'doors')
  if (openedDoor) {
    openedDoor.setAttribute('visibility', 'hidden')
    if (state.get('warningDoorOpened') === true) {
      openedDoor.setAttribute('visibility', 'visible')
    }
  }
}
