import { getInnerElement } from '../tools'
import store from '../../../services/tygroshka-store'

export const elboxCha = (element, state) => {
  // console.log('elboxf', element, state)
  const doors = getInnerElement(element, 'doors')
  doors.setAttribute('visibility', 'hidden')
  if (state.get('doorOpened') === true) {
    doors.setAttribute('visibility', 'visible')
  }
}
