import store from './services/tygroshka-store'
import { SHVService } from './services/shv-service'

class Tygroshka {
  install (Vue, options: any) {
    if (!options.store) { throw new Error('Vuex store is not provided') }

    // Register vuex module
    options.store.registerModule('tygroshka', store)

    // Add $tygroshka to Vue.prototype to use module globally
    const shvPath = localStorage.getItem('shvPath') // || Vue.prototype.$config.shvSubscribePath
    Vue.prototype.$tygroshka = {
      service: new SHVService(options.store, shvPath as string)
    }
    // console.log('tygroshak service', Vue.prototype.$tygroshka)
    // if (localStorage.getItem('sitesInfo')) {
    //   console.log('try to connect proper stuff')
    //   Vue.prototype.$tygroshka.service.connect()
    // } else {
    //   console.log('getting sites info')
    //   const sitesInfo = Vue.prototype.$tygroshka.service.getSitesInfo()
    //   console.log('sites info', sitesInfo)
    // }
  }
}

export default new Tygroshka()
