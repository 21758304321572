import { Alarm, Asset, AssetByShvPath } from '../types/general.types'

export default {
  namespaced: true,

  state: {
    assets: {} as AssetByShvPath,
    shvData: { nodes: {}, devices: {}, assetsTree: {}, assetsByPath: {} },
    styles: {},
    defaultChamberColors: {},
    assetsTree: {},
    assetsTypes: [],
    assetsObject: {},
    alarms: [] as Alarm[],
    unsubscribed: [] as string[],
    userRoles: [] as string[],
    assetsLoaded: false,
    plcDisconnected: false,
    svgPath: '',
    deviceType: undefined as string,
    assetParent: undefined,
    shvPath: undefined,
    currentAssetShvPath: undefined,
    safetyPin: undefined,
    activeSafetyCommand: undefined,
    svgImage: '',
    activeAsset: undefined,
    shvErrorMsg: [],
    shvVersion: '',
    gitCommit: '',
    translations: '',
    customProjectName: '',
    installationsFromShv: {},
    showSHVPropertyName: false
  },

  getters: {
    alarms (state) {
      return state.alarms
    } // -> getters['account/isAdmin']
  },

  mutations: {
    assets (state, assets: AssetByShvPath): void {
      state.assets = assets
    },
    shvData (state, shvData): void {
      state.shvData = shvData
    },
    customProjectName (state, customProjectName): void {
      state.customProjectName = customProjectName
    },
    assetsTree (state, assetsTree): void {
      state.assetsTree = assetsTree
    },
    defaultChamberColors (state, newValue): void {
      state.defaultChamberColors = { ...state.defaultChamberColors, ...newValue }
    },
    assetsTypes (state, assetsTypes): void {
      state.assetsTypes = assetsTypes
    },
    assetsObject (state, assetsObject): void {
      state.assetsObject = assetsObject
    },
    styles (state, styles): void {
      state.styles = styles
    },
    shvPath (state, shvPath: string): void {
      state.shvPath = shvPath
    },
    currentAssetShvPath (state, currentAssetShvPath: string): void {
      state.currentAssetShvPath = currentAssetShvPath
    },
    safetyPin (state, safetyPin: string): void {
      state.safetyPin = safetyPin
    },
    shvVersion (state, shvVersion: string): void {
      state.shvVersion = shvVersion
    },
    gitCommit (state, gitCommit: string): void {
      state.gitCommit = gitCommit
    },
    activeSafetyCommand (state, activeSafetyCommand) {
      state.activeSafetyCommand = activeSafetyCommand
    },
    svgImage (state, svgImage): void {
      state.svgImage = svgImage
    },
    svgPath (state, svgPath: string): void {
      console.log('setting svg Path in store', svgPath)
      state.svgPath = svgPath
    },
    alarms (state, alarms: Alarm[]): void {
      state.alarms = alarms
    },
    unsubscribed (state, properties: string[]): void {
      state.unsubscribed = properties
    },
    userRoles (state, roles: string[]): void {
      state.userRoles = roles
    },
    assetsLoaded (state, value: boolean): void {
      state.assetsLoaded = value
    },
    plcDisconnected (state, value: boolean): void {
      state.plcDisconnected = value
    },
    deviceType (state, value: string): void {
      state.deviceType = value
    },
    assetParent (state, value): void {
      state.assetParent = value
    },
    activeAsset (state, value): void {
      state.activeAsset = value
    },
    translations (state, value): void {
      state.translations = value
    },
    installationsFromShv (state, value): void {
      state.installationsFromShv = value
    },
    shvErrorMsg (state, value): void {
      state.shvErrorMsg = [value, ...state.shvErrorMsg]
    },
    showSHVPropertyName (state, value): void {
      state.showSHVPropertyName = value
    }
  },

  actions: {
    addAlarm (context, alarm: Alarm): void {
      if (!context.state.alarms.includes(a => a.shvPath === alarm.shvPath && a.name === alarm.name)) {
        context.state.alarms.push(alarm)
      }
    },

    removeAlarm (context, params: { asset: Asset, variable: string }): void {
      const index: number = context.state.alarms.findIndex(a => a.shvPath === params.asset.shvPath && a.name === params.variable)

      if (index >= 0) { context.state.alarms.splice(index, 1) }
    }
  }
}
