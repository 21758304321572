<template>
  <div>
    <v-row>
      <v-col>
        <span><a href="#" @click="setBreadcrumb(0)">
          <v-icon>mdi-home</v-icon>
        </a> / </span>
        <span
          v-for="(entry, index) in path"
          :key="index"
        >
          <a href="#" @click="setBreadcrumb(index + 1)">{{ staticTranslation(entry) }}</a> /
        </span>
      </v-col>
    </v-row>
    <v-row v-if="this.currentAsset">
      <v-col>
        <v-toolbar class="primary">
          <v-toolbar-title style="color: white;"><strong>{{ deviceTypeAndName() }}</strong></v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row v-if="!currentObject.assetInfo">
      <v-col>
        <v-container fluid grid-list-xl>
          <v-layout wrap justify-space-around>
            <v-flex
              v-for="(pathEntry, index) in currentPath"
              :key="index"
              @click="setPath(pathEntry)"
            >
              <v-card
              color="#12602C66"
              outlined
              min-width="230px"
              min-height="130px"
              elevation="1"
              href="#"
            >
                <v-card-text>
                  <p class="display-1 text--primary">
                    {{ staticTranslation(pathEntry)}}
                  <v-badge v-if="hasAlarms(pathEntry)" color="red" bottom :content="assetAlarms(pathEntry)">
                    <v-icon small></v-icon>
                      <!-- TODO based on alarms for asset see device index file -->
                  </v-badge>
                  </p>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-col>
    </v-row>
    <v-row v-if="currentObject.assetInfo">
      <v-col cols="12" :sm="existingCommands.length > 0 || currentPath.length > 0 ? '6' : '12'">
        <component
          :assetNodes="assetNodes"
          :variables="variables"
          :currentObject="currentObject"
          :fullShvPath="fullShvPath"
          :currentAssetPath="currentAssetShvPath"
          :types="shvData.types"
          :is="propertySheetSwitcher">
        </component>
        <!-- {{ currentObject.assetInfo.deviceType }} -->
      </v-col>
      <v-col cols="12" sm="6">
        <v-row>
          <v-tabs
            v-model="activeTab" align-with-title>
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#commands" v-if="existingCommands.length > 0">
              {{ $t('assetDetail.commands') }}
            </v-tab>
            <v-tab href="#children" v-if="currentPath.length > 0">
              {{ $t('assetDetail.components') }}
            </v-tab>
          </v-tabs>
        </v-row>
        <v-row>
          <v-tabs-items v-model="activeTab">
            <v-tab-item value="commands" v-if="existingCommands.length > 0">
              <v-list>
                <v-list-item v-for="method in existingCommands" :key="method.name">
                  <v-list-item-content>
                    <v-container>
                      <v-row no-gutters>
                        <v-col cols="12" sm="12">
                          <v-btn
                            color="primary"
                            large
                            block
                            :disabled="!enabledCommands.includes(method.name)"
                            @click="sendCommand(method)"
                            elevation="10">
                            {{ getMethodTranslation(method.name) }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="currentAsset.assetType.deviceType === 'Gate_G3'">
                  <v-list-item-content>
                    <v-container>
                      <v-row no-gutters>
                        <v-col cols="12" sm="12">
                          <v-btn
                            color="primary"
                            large
                            block
                            @click="showRequestMemory()"
                            elevation="10">
                            {{ staticTranslation('showRequestMemory') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-text-field
                v-if="assetType.deviceType === 'DisconDisconnector'"
                label="PIN"
                :autofocus="autofocus"
                v-model="disconPin">
              </v-text-field>
            </v-tab-item>
            <v-tab-item value="children" v-if="currentPath.length > 0">
              <v-container fluid grid-list-xl>
                <v-layout wrap justify-space-around>
                  <v-flex
                    v-for="(pathEntry, index) in currentPath"
                    :key="index"
                    @click="setPath(pathEntry)"
                  >
                    <v-card
                    color="#12602C66"
                    outlined
                    min-width="230px"
                    min-height="130px"
                    elevation="1"
                    href="#"
                  >
                      <v-card-text>
                        <p class="display-1 text--primary">
                        <span>
                          {{ staticTranslation(pathEntry) }}
                        <i
                          v-show="hasAlarms(pathEntry)"
                          :style="{ fontSize: '25px', color: assetAlarmColor()}"
                          class="v-icon mdi mdi-alert-outline">
                        </i>
                        </span>
                        </p>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import { sleep } from '../helpers/utils'
import shv3PropertySheet from '../components/property-sheets/shv-3-property-sheet.vue'
import zoneShv3PropertySheet from '../components/property-sheets/zone-shv3-property-sheet.vue'
import gpioShv3PropertySheet from '../components/property-sheets/gpio-shv3-property-sheet.vue'
// import { objectSet } from '../helpers/utils'

export default Vue.extend({
  name: 'asset-detail',
  components: {
    shv3PropertySheet,
    gpioShv3PropertySheet,
    zoneShv3PropertySheet
  },

  data () {
    return {
      activeTab: 'commands',
      methodsList: [],
      requestMemoryArray: [],
      disconPin: '',
      solvedMethodsDescription: [],
      defaultFilteredMethods: ['deviceType', 'tags']
    }
  },

  // updated () {
  //   console.log('SHV DATA UPDATED', this.shvData, this.variables)
  // },

  mounted () {
    if (this.$config.userRolesEnabled) {
      this.getRolesFromSHV()
    }
    this.updateAllValuesFromShv()
  },

  computed: {
    ...mapState('tygroshka', [
      'shvData',
      'alarms',
      'styles',
      'shvPath',
      'translations',
      'currentAssetShvPath'
    ]),

    path () {
      return this.currentAssetShvPath ? this.currentAssetShvPath.split('/') : []
    },

    propertySheetSwitcher () {
      const deviceType = this.currentObject.assetInfo.deviceType
      if (deviceType === 'Zone' || deviceType === 'Zone_G3') {
        return 'zone-shv3-property-sheet'
      } else if (/GPIO_G3.*/.test(deviceType)) {
        return 'gpio-shv3-property-sheet'
      } else {
        return 'shv-3-property-sheet'
      }
    },

    currentObject () {
      return this.getPath(this.shvData.assetsTree, this.path)
    },

    currentPath () {
      return Object.keys(this.currentObject)
        .filter(pathEntry => pathEntry !== 'assetInfo')
        .sort((a, b) => a.localeCompare(b))
    },
    fullShvPath () {
      return `${this.shvPath}/${this.currentAssetShvPath}`
    },
    enabledCommands () {
      if (!this.$config.userRolesEnabled) {
        return this.assetType.methods
      }
      return this.solvedMethodsDescription
        .filter(methodDescription => {
          return methodDescription.solvedAccess
        })
        .map(methodDescription => methodDescription.name)
    },
    existingCommands () {
      const blacklistedPaths = this.shvData.blacklistedPaths
      return this.assetType.methods.filter(command => {
        const methodPath = `${this.currentAssetShvPath}/method/${command.name}`
        // filter blacklisted methods and allow only methods that have signature (void):void
        return !blacklistedPaths.includes(methodPath) && command.signature === 0
      })
    },

    assetNodes () {
      if (!this.shvData.assetsByPath) {
        return {}
      }
      const currentAsset = this.shvData.assetsByPath[this.path.join('/')]
      if (currentAsset) {
        return currentAsset.nodes
      } else {
        return {}
      }
    },
    currentAsset () {
      return this.shvData.assetsByPath[this.path.join('/')]
    },
    variables () {
      if (!this.shvData.assetsByPath) {
        return {}
      }
      const currentAsset = this.shvData.assetsByPath[this.path.join('/')]
      if (currentAsset) {
        return currentAsset.variables
      } else {
        return {}
      }
    },
    assetType () {
      const currentAsset = this.shvData.assetsByPath[this.path.join('/')]
      if (currentAsset) {
        return currentAsset.assetType
      } else {
        return {}
      }
    }
  },

  watch: {
    currentPath: {
      handler: function (val, oldVal) {
        if (this.$config.userRolesEnabled) {
          this.getRolesFromSHV()
          // Reread all values for case of unmonitored but needed values (like time)
          this.updateAllValuesFromShv()
        }
      }
      /* deep: true */
    },
    variables (value) {
      this.$store.commit('setExternalWindowContent', {
        content: this.requestMemoryArray, type: 'requestMemory'
      })
    }
  },

  methods: {
    updateAllValuesFromShv () {
      if (Object.keys(this.assetNodes).length === 0) return
      for (const node of this.assetNodes) {
        if (node.name.length === 0) continue
        const nodePathWithoutDeviceType = node.name // node.name.split('/').slice(1, node.name.split('/').length).join('/')
        const nodeShvPath = `${this.shvPath}/${this.currentAssetShvPath}/${nodePathWithoutDeviceType}`
        this.$tygroshka.service.sendMessage(nodeShvPath, 'get', '0').then(result => {
          this.$tygroshka.service.resolveResponseOutside(nodeShvPath, result)
        }).catch(error => {
          console.log('error updating value for', nodeShvPath, error)
        })
      }
    },

    showRequestMemory () {
      const requestMemory = this.variables.get('requestMemory')
      this.requestMemoryArray = requestMemory.split('\n').filter(line => line.length > 1).map(line => {
        return line.split(',')
      })
      this.$store.commit('setExternalWindow', true)
      this.$store.commit('setExternalWindowContent', {
        content: this.requestMemoryArray, type: 'requestMemory'
      })
    },
    staticTranslation (entry) {
      const translation = this.translations[`static.${entry}`]
      return translation || entry
    },

    deviceTypeAndName () {
      const deviceType = this.currentAsset.assetType.deviceType
      const shvPath = this.currentAsset.shvPath.split('/')
      const name = shvPath[shvPath.length - 1]
      return `${this.staticTranslation(deviceType)}: ${name}`
    },

    assetAlarms (pathEntry = '') {
      const rootPath = this.path.length > 0 ? `${this.path.join('/')}/` : ''
      const path = `${rootPath}${pathEntry}`
      const regexx = new RegExp(`${path}.*`)
      const assetAlarms = this.alarms.filter(a => regexx.test(a.shvPath))
      // console.log('asset alarms', assetAlarms, path, this.alarms)
      return assetAlarms.length
    },
    getMethodTranslation (methodName) {
      const deviceType = this.currentObject.assetInfo.deviceType
      const methodInfo = this.assetType.methods.find(m => m.name === methodName)
      const foundTranslation = this.translations[`device.${deviceType}.method.${methodName}.label`]
      return foundTranslation || methodInfo.label || methodName
    },
    hasAlarms (pathEntry = '') {
      return this.assetAlarms(pathEntry) > 0
    },
    assetAlarmColor () {
      const alarmStyle = this.styles.visuStyle.alarm
      const regexx = new RegExp(`${this.path.join('/')}.*`)
      const alarms = this.alarms.filter(a => regexx.test(a.shvPath))
      // console.log('checking for alarm color', alarms, this.path.join('/'), this.alarms)
      const severity = alarms.filter(a => a.severity === 'error').length > 0 ? 'error' : 'warning'
      const alarmColors = severity === 'error' ? alarmStyle.error : alarmStyle.warning
      return alarmColors.background
    },
    setPath (pathEntry) {
      this.$store.commit('tygroshka/currentAssetShvPath', [...this.path, pathEntry].join('/'))
    },

    setBreadcrumb (index) {
      // this.path = this.path.slice(0, index)
      this.$store.commit('tygroshka/currentAssetShvPath', this.path.slice(0, index).join('/'))
    },

    getPath (obj, props) {
      if (props.length === 0) return obj
      const [firstEntry, ...rest] = props
      return this.getPath(obj[firstEntry], rest)
    },

    async getRolesFromSHV () {
      let solvedMethodsDescription = []
      const accessConverter = {
        bws: 0,
        rd: 1,
        wr: 2,
        cmd: 3,
        cfg: 4,
        srv: 5,
        ssrv: 6,
        dev: 7,
        su: 8
      }
      // console.log('trying to solve roles', this.assetType)
      if (this.assetType.methods) {
        solvedMethodsDescription = this.assetType.methods.map(async (methodDescription) => {
          const methodName = methodDescription.name
          const requiredAccess = methodDescription.accessGrant
          const currentAccess = await this.$tygroshka.service.sendMessage(
            '.broker/currentClient',
            'accessLevelForMethodCall',
            `["${this.shvPath}/${this.currentAssetShvPath}",
            "${methodName}"]`
          )
          const convertedRequiredAccess = accessConverter[requiredAccess]
          const convertedCurrentAccess = accessConverter[currentAccess.result().toString().replace(/"/g, '')]
          const solvedAccess = convertedCurrentAccess >= convertedRequiredAccess
          return { ...methodDescription, solvedAccess: solvedAccess }
        })
      }
      const resolved = await Promise.all(solvedMethodsDescription)
      this.solvedMethodsDescription = resolved
    },

    async sendCommand (method) {
      const { name, safetyManager } = method
      let result
      if (name === 'cmdConnect') {
        result = await this.$tygroshka.service.sendMessage(this.fullShvPath, name, this.disconPin)
        console.log('sending connect with pin', name, this.disconPin)
      } else {
        result = await this.$tygroshka.service.sendMessage(this.fullShvPath, name)
      }
      await sleep(500)
      if (safetyManager) {
        const getSafetyPinPath = `${this.shvPath}/${safetyManager}/authorization/authKeyGenerated`
        const result = await this.$tygroshka.service.sendMessage(getSafetyPinPath, 'get')
        this.$store.commit('tygroshka/safetyPin', result.result().value)
        this.$store.commit('tygroshka/activeSafetyCommand', method)
      }
      if (name === 'cmdDisconnect') {
        this.disconPin = result.result().value
      }
      console.log('result', result)
    }
  }
})
</script>
