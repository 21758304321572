import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

// Ready translated locale messages
const messages = {
  en: require('./locales/en.json'),
  de: require('./locales/de.json'),
  cs: require('./locales/cs.json'),
  hu: require('./locales/hu.json'),
  fi: require('./locales/fi.json'),
  it: require('./locales/it.json'),
  nl: require('./locales/nl.json'),
  nl_BE: require('./locales/nl_BE.json'),
  // cs: require('./locales/cs.json'),
  // fr: require('./locales/fr.json'),
  pl: require('./locales/pl.json')
}

const language = window.localStorage.getItem('userLanguage')

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: language, // set locale
  fallbackLocale: 'en',
  silentFallbackWarn: true, // suppress warnings
  silentTranslationWarn: true,
  messages // set locale messages
})

export default i18n
