import { getInnerElement } from './tools'
import * as moment from 'moment'

export const gateG3 = (element, state) => {
  const requestInhibitedEl = getInnerElement(element, 'requestInhibited')
  if (requestInhibitedEl) {
    if (state.get('routeRequestInhibited')) {
      requestInhibitedEl.setAttribute('visibility', 'visible')
    } else {
      requestInhibitedEl.setAttribute('visibility', 'hidden')
    }
  }

  if (state.get('requestMemory')) {
    const rows = state.get('requestMemory').split('\n')
    const content = rows.slice(1, rows.length + 1) // remove first header row
    for (let i = 0; i < 10; i++) {
      const values = content[i] ? content[i].split(',') : []
      const [time, vehicleId, route, lineNumber, requestSourced] = values

      const [timeEl, vehicleIdEl, routeEl, lineNumberEl] = [
        getInnerElement(element, `row${i}/timeCreated`),
        getInnerElement(element, `row${i}/vehicleId`),
        getInnerElement(element, `row${i}/route`),
        getInnerElement(element, `row${i}/lineNumber`)
      ]

      if (timeEl && time) {
        const timestamp = moment.unix(time).format('HH:mm:ss')
        timeEl.textContent = timestamp
        timeEl.setAttribute('visibility', 'visible')
      } else if (timeEl) {
        timeEl.setAttribute('visibility', 'hidden')
      }

      if (vehicleIdEl && vehicleId) {
        vehicleIdEl.textContent = vehicleId
        vehicleIdEl.setAttribute('visibility', 'visible')
      } else if (vehicleIdEl) {
        vehicleIdEl.setAttribute('visibility', 'hidden')
      }

      if (lineNumberEl && lineNumber) {
        lineNumberEl.textContent = lineNumber
        lineNumberEl.setAttribute('visibility', 'visible')
      } else if (lineNumberEl) {
        lineNumberEl.setAttribute('visibility', 'hidden')
      }

      if (routeEl && route) {
        routeEl.textContent = route
        routeEl.setAttribute('visibility', 'visible')
      } else if (routeEl) {
        routeEl.setAttribute('visibility', 'hidden')
      }
    }
  }
  // 10 is arbitary number (if gate can have more than 10 rows it has to be changed)
}
