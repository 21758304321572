import { getInnerElement } from '../tools'
import store from '../../../services/tygroshka-store'

export const controlrelayCha = (element, state) => {
  // console.log('ctrlrelayf', element, state)
  const chidctrlrelay = getInnerElement(element, 'ctrlrelay')
  if (state.get('active') === true) {
    chidctrlrelay.setAttribute('fill', 'green')
  } else if (state.get('active') === false) {
    chidctrlrelay.setAttribute('fill', 'red')
  } else {
    chidctrlrelay.setAttribute('fill', 'grey')
  }
}
