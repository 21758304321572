import { getInnerElement } from './tools'
import { zoneState } from '../../helpers/property-decorators'
import store from '../../services/tygroshka-store'

const setAttributeOnElement = (innerElement, attributeName, attributeValue) => {
  if (!innerElement) return false
  innerElement.setAttribute(attributeName, attributeValue)
}

export const zoneG3 = (element, state) => {
  const translations = store.state.translations
  element.setAttribute('visibility', 'visible')
  const textElement = getInnerElement(element, 'statusText')
  const { text, color } = zoneState(state, 'DepotG3')
  const translatedZoneState = translations[`type.EnumZoneState.fields.${text}.label`]
  textElement.textContent = `${translatedZoneState}`
  setAttributeOnElement(textElement, 'fill', color)
}
