import { getInnerElement, solveRadioBeamsG3, blinkOnVehicleDetected } from './tools'

export const drrG3 = (element, state, assetsData, options, attribute) => {
  const tramIdEl = getInnerElement(element, 'tramId')
  const directionEl = getInnerElement(element, 'direction')
  const sideEl = getInnerElement(element, 'side')

  blinkOnVehicleDetected(element, state, 'vehicleId', attribute)
  solveRadioBeamsG3(element, state)

  if (tramIdEl) {
    tramIdEl.setAttribute('visibility', 'hidden')
    if ((state.get('vehicleDetected') && tramIdEl)) {
      tramIdEl.textContent = state.get('vehicleDetected').vehicleId
      tramIdEl.setAttribute('visibility', 'visible')
    }
  }

  if (directionEl) {
    directionEl.setAttribute('visibility', 'hidden')
    if (state.get('vehicleDetected') && directionEl) {
      directionEl.textContent = state.get('vehicleDetected').direction
      directionEl.setAttribute('visibility', 'visible')
    }
  }

  if (sideEl) {
    sideEl.setAttribute('visibility', 'hidden')
    if (state.get('vehicleDetected') && sideEl) {
      sideEl.textContent = state.get('vehicleDetected').side
      sideEl.setAttribute('visibility', 'visible')
    }
  }
}
