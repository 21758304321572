import { getInnerElement } from './tools'

export const routeG3 = (element, state) => {
  const route = getInnerElement(element, 'route')
  const routeState = state.get('routeState')?.toUpperCase()
  route.setAttribute('opacity', 1)
  const textNode = route.parentNode.querySelector('tspan')
  textNode.setAttribute('font-weight', 'bold')
  if (routeState === 'ERROR') {
    route.setAttribute('visibility', 'visible')
    route.setAttribute('stroke', 'red')
  } else if (routeState === 'OCCUPIED') {
    route.setAttribute('visibility', 'visible')
    route.setAttribute('stroke', 'orange')
  } else if (routeState === 'READY') {
    route.setAttribute('stroke', '#6beb34')
    route.setAttribute('visibility', 'visible')
  } else if (routeState === 'BUILD') {
    route.setAttribute('visibility', 'visible')
    route.setAttribute('opacity', 0.7)
    route.setAttribute('stroke', 'magenta')
  } else if (routeState === 'RELEASE') {
    route.setAttribute('visibility', 'visible')
    route.setAttribute('opacity', 0.7)
    route.setAttribute('stroke', 'magenta')
  } else if (routeState === 'FREE') {
    route.setAttribute('visibility', 'hidden')
    textNode.setAttribute('font-weight', 'normal')
  } else {
    route.setAttribute('stroke', 'black')
    route.setAttribute('visibility', 'visible')
    textNode.setAttribute('font-weight', 'normal')
  }
}
