import { getInnerElement } from './tools'

export const mpsG3 = (element, state) => {
  const rows = 3
  const mps = getInnerElement(element, 'mps')
  if (state.get('turnedOnManually')) {
    mps.setAttribute('fill', 'white')
  } else {
    mps.setAttribute('fill', 'gray')
  }

  try {
    const mpsData = state.get('data') ? JSON.parse(state.get('data')) : undefined
    if (mpsData) {
      for (let i = 0; i < rows; i++) {
        const rowData = state.get('turnedOnManually') ? '' : mpsData[i]
        const rowElement = getInnerElement(element, `row${i}`)
        rowElement.setAttribute('visibility', 'hidden')
        if (!rowData) {
          continue
        }
        const { text, color, align, blinking } = rowData
        const offset = 0
        const mpsWidth = parseInt(mps.getAttribute('width'))
        let xOffset = mpsWidth / 2
        if (align === 'left') {
          xOffset = 0
        } else if (align === 'right') {
          xOffset = mpsWidth
        }
        const correction = align === 'right' ? -2 : 2
        if (rowElement && text) {
          rowElement.textContent = text.toString()
          const anchor = align === 'center' || !align ? 'middle' : (align === 'right' ? 'end' : 'start')
          rowElement.setAttribute('text-anchor', anchor)
          rowElement.setAttribute('x', parseInt(mps.getAttribute('x')) + xOffset + correction)
          rowElement.setAttribute('class', '')
          if (color) {
            rowElement.setAttribute('fill', color)
            if (blinking) {
              rowElement.setAttribute('class', 'symbol-blinking')
            }
          } else {
            rowElement.setAttribute('fill', 'black')
          }
          rowElement.setAttribute('visibility', 'visible')
        } else if (rowElement) {
          rowElement.setAttribute('visibility', 'hidden')
        }
      }
    }
  } catch (error) {
    console.log('PROBLEM WITH MPS DATA', error)
  }
}
