import { getInnerElement } from './tools'

export const doorDisplay7G3 = (element, state) => {
  const color = state.get('color')?.toLowerCase()
  const number = getInnerElement(element, 'number')
  number.setAttribute('class', '')
  number.setAttribute('fill', 'black')
  switch (color) {
    case 'red_yellow':
      number.setAttribute('class', 'blinking-red-yellow')
      break
    case 'red_green':
      number.setAttribute('class', 'blinking-red-green')
      break
    case 'yellow_green':
      number.setAttribute('class', 'blinking-yellow-green')
      break
    case 'red_yellow_green':
      number.setAttribute('class', 'blinking-red-yellow-green')
      break
    default:
      number.setAttribute('fill', color)
  }
}
