import { getInnerElement } from '../tools'
import store from '../../../services/tygroshka-store'

export const heatingCha = (element, state) => {
  const tempAir = getInnerElement(element, 'tempAir')
  const tempRail = getInnerElement(element, 'tempRail')
  const heatingState = getInnerElement(element, 'heatingState')
  if (heatingState) {
    if (heatingState.textContent === 'heatingState') {
      heatingState.textContent = ''
    }
  }
  const stateTextMappings = {
    auto: 'auto',
    emergency: 'emergency',
    errorAuto: 'errorAuto',
    errorHeating: 'errorHeating',
    errorTempAir: 'errorTempAir',
    errorTempRail: 'errorTempRail',
    heating: 'heating',
    off: 'off',
    on: 'on'
  }
  const keys = Object.keys(stateTextMappings)
  let msg = ''
  for (let i = 0; i < keys.length; i++) {
    if (state.get(keys[i]) === true) {
      if (msg === '') {
        msg = stateTextMappings[keys[i]]
      } else {
        msg = msg + ' ' + stateTextMappings[keys[i]]
      }
    }
  }
  if (heatingState) {
    if (msg === '') {
      heatingState.textContent = heatingState.textContent !== 'NO INPUT' ? heatingState.textContent : 'NO INPUT'
      heatingState.setAttribute('fill', 'black')
    } else {
      heatingState.textContent = msg
    }
  }
  if (tempAir) {
    tempAir.textContent = state.get('tempAir') !== undefined ? `${state.get('tempAir')} °C` : tempAir.textContent
  }
  if (tempRail) {
    tempRail.textContent = state.get('tempRail') !== undefined ? `${state.get('tempRail')} °C` : tempRail.textContent
  }
}
