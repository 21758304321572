import { getInnerElement } from './tools'
import store from '../../services/tygroshka-store'

export const detector = (element, state, assetsData, options) => {
  const deviceTypeStyle = store.state.styles.visuStyle[options.genericDeviceType]
  const detectorStyle = deviceTypeStyle || store.state.styles.visuStyle.Detector
  const detector = getInnerElement(element, 'detector')
  if (detector) {
    detector.removeAttribute('fill-opacity')
    if (state.get('occupied')) {
      detector.setAttribute('fill', detectorStyle.occupied.background)
    } else if (state.get('claimed')) {
      detector.setAttribute('fill', detectorStyle.claimed.background)
    } else {
      console.log('setting detector back to ', detectorStyle)
      detector.setAttribute('fill', detectorStyle.free.background)
    }
  }
}
