import { getInnerElement, checkElementsNumber } from './tools'

export const pmeG3 = (element, state) => {
  checkElementsNumber(element)
  const [right, left, leverInSocket, motorMoving] = [
    getInnerElement(element, 'posRight'),
    getInnerElement(element, 'posLeft'),
    getInnerElement(element, 'leverInSocket'),
    getInnerElement(element, 'motorMoving')
  ]
  right.setAttribute('visibility', 'hidden')
  left.setAttribute('visibility', 'hidden')

  if (leverInSocket) {
    leverInSocket.setAttribute('visibility', 'hidden')

    if (state.get('leverInSocket') === true) {
      leverInSocket.setAttribute('visibility', 'visible')
    }
  }

  if (state.get('posLeft') === true) {
    left.setAttribute('visibility', 'visible')
  } else if (state.get('posRight') === true) {
    right.setAttribute('visibility', 'visible')
  }
  if (motorMoving) {
    motorMoving.setAttribute('visibility', 'hidden')
    if (state.get('motorMoving') === true) {
      motorMoving.setAttribute('visibility', 'visible')
    }
  }
}
