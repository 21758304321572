import { mapState } from 'vuex'
import Vue from 'vue'

const propertySheetFunctions = {
  computed: {
    ...mapState('tygroshka', [
      'userRoles'
    ]),

    availableRoles () {
      const existingRoles = ['su', 'dev', 'srv', 'cfg', 'cmd', 'wr', 'rd', 'bws']
      // find intersection of all roles with user roles from SHV
      const currentRole = existingRoles.filter(x => this.userRoles.includes(x))[0]
      const currentRoleIndex = existingRoles.findIndex(r => r === currentRole)
      return existingRoles.slice(currentRoleIndex, existingRoles.length)
    }
  },
  methods: {
    getDescription (propertyName) {
      try {
        return this.getPropertyConfig(propertyName).description
      } catch {
        console.log('no property config for', propertyName, this.activeAsset)
        return ''
      }
    },
    async writableRole (shvPath) {
      // TODO !!! unite with method in asset-detail `getRolesFromSHV`
      if (Vue.prototype.$config.userRolesEnabled) {
        const accessConverter = {
          bws: 0,
          rd: 1,
          wr: 2,
          cmd: 3,
          cfg: 4,
          srv: 5,
          ssrv: 6,
          dev: 7,
          su: 8
        }
        const currentAccess = await this.$tygroshka.service.sendMessage(
          '.broker/currentClient',
          'accessLevelForMethodCall',
          `["${this.fullShvPath(shvPath)}",
          "set"]`
        )
        return accessConverter[currentAccess] >= 2
      } else {
        return true
      }
    },
    getPropertySettings (propertyName) {
      // console.log('trying to get settings', this.activeAsset)
      if (!this.activeAsset) {
        console.log('no active asset')
        return
      }
      const deviceConfig = getConfig(this.activeAsset.deviceType)
      return deviceConfig.observerVariables()[propertyName]
    },
    getPropertyConfig (propertyName) {
      const deviceConfig = getConfig(this.activeAsset.deviceType)
      try {
        const propertyPath = deviceConfig.observerVariables()[propertyName].propertyName
        return this.activeAsset.propertiesConfig[`${this.activeAsset.shvPath}/${propertyPath}`]
      } catch {
        console.log('property is probably missing')
      }
    },
    generateSwitch (background, text) {
      return `<div style="float: right; background: ${background}; border: 2px solid black; color: white; text-align: center; border-radius: 15px; width: 100px; height: 30px;">${text.toUpperCase()}</div>`
    },

    getValue (variableName) {
      const variablePair = Array.from(this.activeAsset.variables).find((variableInfo) => {
        return variableInfo[0] === variableName
      })
      return variablePair[1]
    },

    resolveValue (value, name) {
      if (!this.getPropertyConfig(name)) {
        return 'N/A'
      }
      const propertyConfig = this.getPropertyConfig(name)
      const unit = propertyConfig.unit
      const visualStyle = this.getPropertyConfig(name).visualStyle
      const deviceTypeSettings = this.getPropertySettings(name)
      if (visualStyle) {
        const state = visualStyle.states.find(state => state.value === value)
        if (visualStyle.type === 'text') {
          return `<span style="color: ${state.style.background};">${state.style.text}</span>`
        } else if (visualStyle.type === 'switch') {
          return this.generateSwitch(state.style.background, state.style.text)
        }
      } else if (deviceTypeSettings && deviceTypeSettings.settable) {
        console.log('settable property', name)
        return 'settable'
      } else {
        const deviceConfig = getConfig(this.activeAsset.deviceType)
        const valueType = deviceConfig.observerVariables()[name].type
        const alarm = this.getPropertyConfig(name).alarm

        // replace with proper reading of variable definition of its type
        if (valueType === 'boolean') {
          if (alarm) {
            // if there is active alarm
            if (value) {
              return `<span style="color: red;">YES</span><i style="font-size: 30px; color: ${alarm === 'error' ? 'red' : 'orange'};" class="v-icon mdi mdi-alert-outline"></i>`
            } else {
              return '<span style="color: black;">NO</span>'
            }
          } else {
            if (value) {
              return '<span style="color: black;">YES</span>'
            } else {
              return '<span style="color: black;">NO</span>'
            }
          }
        } else {
          return unit ? `${value} ${unit}` : value
        }
      }
    }
  }
}
export default propertySheetFunctions
