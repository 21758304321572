export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const sleep = (ms) => {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}

export const getAllowedKeys = (keys, object, remove) => {
  if (remove) {
    return Object.keys(object).filter(key => !keys.includes(key))
  } else {
    return keys
  }
}

export const readAttributeValue = (node, name, variables) => {
  const attributePath = node.parentType ? `${node.parentType}/${name}` : name
  return variables.get(attributePath)
}

// create new object without defined keys
// remove true will remove filteredKeys from object; remove false will keep only filteredKeys in object
export const filterKeys = (filteredKeys, object, remove = false) => {
  const allowedKeys = getAllowedKeys(filteredKeys, object, remove)
  return Object.keys(object)
    .filter(key => allowedKeys.includes(key))
    .reduce((obj, key) => {
      return { ...obj, [key]: object[key] }
    }, {})
}

/**
 * recursion function that called in main function
 * @param obj initial JSON
 * @param keysList array of keys
 * @param value value that you want to set
 * @returns final JSON
 */
export const recursionSet = (obj, keysList, value) => {
  const key = keysList[0]
  if (keysList.length === 1) return { ...obj, [key]: value }
  return { ...obj, [key]: (recursionSet(obj?.[key] || {}, keysList.slice(1), value)) }
}

/**
 * main function that you can call for set a value in an object by nested keys
 * @param obj initial JSON
 * @param keysString nested keys that seprated by "."
 * @param value value that you want to set
 * @returns final JSON
 */
export const objectSet = (obj, keysString, value) => {
  return recursionSet(obj, keysString.split('/'), value)
}
