<template>
  <div class="date-time-picker">
    <v-menu
      ref="menu"
      :close-on-content-click="false"
      v-model="menu"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="330px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-on="on"
          :value="time + '  ' + date"
          mask="time"
          :label="label"
          return-masked-value
          readonly
        ></v-text-field>
      </template>
      <v-container v-if="menu">
        <v-layout row wrap>
          <v-flex>
            <v-time-picker :landscape="true" full-width format="24hr" v-model="dialogData.time" color="#1E88E5"></v-time-picker>
          </v-flex>

          <v-flex>
            <v-date-picker v-model="dialogData.date" no-title :locale="language" color="#1E88E5"></v-date-picker>
          </v-flex>
        </v-layout>

        <v-layout row wrap class="controls">
          <v-flex>
            <v-btn block @click="menu = false">{{ $t('ok') }}</v-btn>
          </v-flex>

          <v-flex v-if="clearable">
            <v-btn block @click="clear()">{{ $t('clear') }}</v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-menu>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'date-time-picker',
  props: ['value', 'label', 'clearable'],
  data () {
    return {
      menu: false,
      language: null,
      dialogData: {
        date: '',
        time: ''
      }
    }
  },
  created () {
    this.language = window.localStorage.getItem('userLanguage')
  },
  computed: {
    date () {
      return this.formated('DD.MM.YYYY')
    },
    time () {
      return this.formated('HH:mm')
    }
  },
  methods: {
    merge (date, time) {
      const result = (date && time) ? moment(date + ' ' + time).valueOf() : ''

      this.$emit('input', result)

      this.menu = false
    },
    formated (format) {
      return this.value ? moment(this.value).format(format) : ''
    },
    clear () {
      this.dialogData.date = ''
      this.dialogData.time = ''

      this.menu = false
    }
  },
  watch: {
    menu (value) {
      if (value) {
        this.dialogData = { date: this.formated('YYYY-MM-DD'), time: this.time }
      } else {
        this.merge(this.dialogData.date, this.dialogData.time)
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .container {
    padding: 15px;
  }

  .v-picker {
    width: 100%;

    border-radius: 0px;
  }

  .v-picker--time {
    width: 400px;
    height: 100%;

    .v-time-picker-title__time {
      text-align: center;
    }

    .v-picker__body {
      display: flex;
      align-items: center;
      flex-direction: row;
    }
  }

  .controls {
    .v-btn {
      margin: 0;
      border-radius: 0;
      box-shadow: none !important;
      border-left: 1px solid #ddd;
      border-top: 1px solid #ddd;
    }

    .v-flex:first-child {
      .v-btn:first-child {
        border: none;
      }
    }
  }
}
</style>
