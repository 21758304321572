import { getInnerElement } from './tools'
import store from '../../services/tygroshka-store'

export const signalG3 = (element, state, assets, options) => {
  // so far only used for GHE019
  const signalStyle = store.state.styles.visuStyle.Signal_G3
  if (signalStyle) {
    let severity = ''
    if (state.get('errorCurrentGeneral') === true) {
      severity = 'error'
    } else if (state.get('warningCurrentGeneral')) {
      severity = 'warning'
    }

    const chambers = getInnerElement(element, 'chambers')
    const elementShvPath = element.getAttribute('shvpath')
    const chambersFillColor = chambers.getAttribute('fill')
    if (!store.state.defaultChamberColors[elementShvPath]) {
      store.mutations.defaultChamberColors(store.state, { [elementShvPath]: chambersFillColor })
    }
    const warningColor = signalStyle.warning.chambers.background
    const errorColor = signalStyle.error.chambers.background

    if (chambers) {
      chambers.setAttribute('fill', store.state.defaultChamberColors[elementShvPath])
      chambers.setAttribute('class', '')
    }
    if (chambers && severity === 'warning') {
      chambers.setAttribute('stroke', 'black')
      chambers.setAttribute('fill', warningColor)
      if (signalStyle.warning.chambers.blinking) {
        chambers.setAttribute('class', 'symbol-blinking-black-blue')
      }
    }
    if (chambers && severity === 'error') {
      chambers.setAttribute('fill', errorColor)
      chambers.setAttribute('stroke', 'black')
    }
  }
}
