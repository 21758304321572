import { getInnerElement } from './tools'

export const requestorDigital = (element, state, assetsData, options) => {
  const box = getInnerElement(element, 'box')
  const buttonsStatus = assetsData[options.shvPath]?.shvValues?.buttonsStatus
  if (state.get('resetPressed')) {
    box.setAttribute('fill', 'yellow')
  } else if (buttonsStatus > 0) {
    box.setAttribute('fill', 'blue')
  } else {
    box.setAttribute('fill', 'white')
  }
}
