import { getInnerElement, solveRadioBeamsG3 } from './tools'

export const vetraG3 = (element, state, assetsData, options, attribute) => {
  const routeCodeEl = getInnerElement(element, 'routeCode')
  const tramIdEl = getInnerElement(element, 'tramId')
  const directionEl = getInnerElement(element, 'direction')

  // blinkOnVehicleDetected(element, state, 'vehicleId', attribute)
  solveRadioBeamsG3(element, state)

  if (routeCodeEl && state.get('vehicleDetected')) {
    routeCodeEl.setAttribute('visibility', 'hidden')
    if ((state.get('vehicleDetected').routeCode || state.get('vehicleDetected').routeCode === 0)) {
      routeCodeEl.textContent = state.get('vehicleDetected').routeCode
      routeCodeEl.setAttribute('visibility', 'visible')
    }
  }
  if (tramIdEl) {
    tramIdEl.setAttribute('visibility', 'hidden')
    if ((state.get('vehicleDetected') && tramIdEl)) {
      tramIdEl.textContent = state.get('vehicleDetected').vehicleId
      tramIdEl.setAttribute('visibility', 'visible')
    }
  }

  if (directionEl && state.get('vehicleDetected')) {
    directionEl.setAttribute('visibility', 'hidden')
    if (state.get('vehicleDetected').direction && directionEl) {
      const direction = state.get('vehicleDetected').direction.length > 0 ? state.get('vehicleDetected').direction : 'NONE'
      directionEl.textContent = direction
      directionEl.setAttribute('visibility', 'visible')
    }
  }
}
