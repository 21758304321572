import Vue from 'vue'
import VueRouter from 'vue-router'
import Visual from '../views/visual.vue'
import Login from '../views/login.vue'
import Alarms from '../views/alarms.vue'
import Logs from '../views/logs.vue'
import Camera from '../views/camera.vue'
import AssetDetail from '../../siteware-lib/src/assets-tree/asset-detail.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/visual'
  },

  {
    path: '/visual',
    name: 'visual',
    component: Visual
  },

  {
    path: '/login',
    name: 'login',
    component: Login
  },

  {
    path: '/asset-detail',
    name: 'asset-detail',
    component: AssetDetail
  },

  {
    path: '/alarms',
    name: 'alarms',
    component: Alarms
  },

  {
    path: '/camera',
    name: 'camere',
    component: Camera
  },

  {
    path: '/logs',
    name: 'logs',
    component: Logs
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const vm = router.app
  if (vm.$store) {
    vm.$store.commit('hideKeyboard')
  }
  const siteName = 'Siteware'
  window.document.title = to.meta && to.meta.title ? to.meta.title : siteName
  next()
})

export default router
