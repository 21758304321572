import { getInnerElement } from './tools'

export const voltageFreeLamp = (element, state) => {
  const voltageIndicator = getInnerElement(element, 'voltageIndicator')
  if (voltageIndicator) {
    if (state.get('isActive') === true) {
      voltageIndicator.setAttribute('fill', 'green')
    } else {
      voltageIndicator.setAttribute('fill', 'white')
    }
  }
}
