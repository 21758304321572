import { getInnerElement } from './tools'

export const heatingRodG3 = (element, state) => {
  const rod = getInnerElement(element, 'rod')
  if (rod) {
    if (state.get('heatingOn') === true) {
      rod.setAttribute('fill', 'red')
      rod.setAttribute('stroke', 'red')
    } else if (state.get('heatingOn') === false) {
      rod.setAttribute('fill', 'black')
      rod.setAttribute('stroke', 'black')
    }
  }
}
