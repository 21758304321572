import i18n from '../../../../../src/i18n'

export const parseStatusNode = (name, node, types, translations) => {
  const statusValue = node.value
  // console.log('node type from UTILS', node)
  const nodeType = types[node.typeName]
  if (!nodeType) {
    // console.log('node type not found', node.typeName)
  }
  const nodeTypeName = nodeType?.typeName ? nodeType.typeName : nodeType?.type
  if (nodeType && nodeTypeName === 'BitField') {
    return nodeType.fields.map(field => {
      const valueExists = statusValue || statusValue === 0
      const { value, alarm } = valueExists ? readBit(statusValue, field.value, field.typeName, types, translations) : { value: 'Unknown' }
      const typeName = field.typeName || 'boolean'
      const fullNodePath = `${name}/${field.name}`
      return [
        field.name,
        {
          value: typeName === 'boolean' ? value === 1 : value,
          typeName,
          label: field.label,
          description: field.description,
          alarm: alarm || field.alarm,
          nodeType,
          parentType: nodeType.restrictionOfType || node.typeName,
          fullNodePath: fullNodePath,
          methods: []
        }
      ]
    })
  }
  if (nodeType && nodeTypeName === 'Map' && (node.value || node.value === 0)) {
    const newValue = Object.entries(node.value).reduce((result, [key, val]) => {
      const fieldDef = nodeType.fields.find(f => f.name === key)
      const enumType = types[fieldDef.typeName]
      if (enumType) {
        const foundField = enumType.fields.find(def => def.value === val)
        const value = foundField ? foundField.name : `not found ${val}`
        result[key] = translations[`type.${fieldDef.typeName}.fields.${value}.label`]
      } else if (fieldDef.typeName === 'Bool') {
        result[key] = val === 1 ? i18n.t('general.YES') : i18n.t('general.NO')
      } else {
        result[key] = val
      }
      return result
    }, {})

    return [[
      name,
      {
        nodeType,
        fullNodePath: name,
        ...node,
        value: newValue
      }
    ]]
  }
  if (/Decimal/.test(node.typeName) || node.typeName === 'Int') {
    const value = node.value && (node.value.mantisa || node.value.mantisa === 0) ? node.value.mantisa * 10 ** node.value.exponent : node.value
    return [[
      name,
      {
        nodeType,
        fullNodePath: name,
        ...node,
        value: Math.round(value * 100) / 100
      }
    ]]
  }
  return [[
    name,
    { nodeType, fullNodePath: name, ...node }
  ]]
}

export const readBit = (num, bit, fieldType, types, translations) => {
  if (/Enum/.test(fieldType)) {
    const bitArray = num.toString(2).padStart(32, '0').split('').reverse()
    const slicedPart = bitArray.slice(bit[0], bit[1] + 1)
    // console.log('sliced part', slicedPart, this.types[fieldType])
    const parsedValue = parseInt(slicedPart.reverse().join(''), 2)
    const foundField = types[fieldType].fields.find(f => f.value === parsedValue)
    return {
      value: foundField?.name,
      alarm: foundField?.alarm
    }
  }
  if (fieldType === 'Int' || fieldType === 'UInt') {
    const bitArray = num.toString(2).padStart(32, '0').split('').reverse()
    const slicedPart = bitArray.slice(bit[0], bit[1] + 1)
    const parsedValue = parseInt(slicedPart.reverse().join(''), 2)
    return { value: Math.round(parsedValue * 100) / 100 }
  }
  return { value: (num >> bit) % 2 }
}

export const getTranslation = (translations, variableName, translationType = 'label', node, activeAsset) => {
  let translationString
  if (node.parentType) {
    translationString = `type.${node.parentType}.fields.${variableName}.${translationType}`
  } else {
    const variablePath = activeAsset ? `${activeAsset.assetType.deviceType}/${variableName}` : variableName
    translationString = `device.${variablePath}.${translationType}`
  }
  const missingTranslation = node[translationType] ? node[translationType] : variableName
  return translations[translationString] ? translations[translationString] : missingTranslation
}
