import { getInnerElement } from '../tools'
import store from '../../../services/tygroshka-store'

// Function to update the state of signal lights for a railway or traffic system
export const ssCha = (element, state) => {
  // console.log('ssf', element, state)
  const getShvPath = (element) => {
    if (element.length) {
      return Array.from(element).map((e: Element) => {
        return e.getAttribute('shvpath')
      })
    } else {
      return [element.getAttribute('shvpath')]
    }
  }
  const shvpatharray = getShvPath(element)

  const go = getInnerElement(element, 'go')
  const stop = getInnerElement(element, 'stop')
  const cross = getInnerElement(element, 'cross')
  const circle = getInnerElement(element, 'circle')
  const turn = getInnerElement(element, 'turn')

  const lights = state.get('symbolState')
  if (cross) {
    cross.setAttribute('visibility', 'hidden')
  }
  if (circle) {
    circle.setAttribute('fill', 'grey')
  }

  const setColorfrfp = (goColor, stopColor) => {
    go.setAttribute('fill', goColor)
    stop.setAttribute('fill', stopColor)
  }

  const setColorfafx = (stopColorfa) => {
    circle.setAttribute('fill', stopColorfa)
  }
  const pathstring = shvpatharray.toString()
  const fr = pathstring.includes('fr')
  const fp = pathstring.includes('fp')
  const fa = pathstring.includes('fa')
  const fx = pathstring.includes('fx')
  const fb = pathstring.includes('fb')
  const fp02 = pathstring.includes('fp02')
  const tip = pathstring.includes('tip')
  const f01 = pathstring.includes('f01')

  if (fp02 === true) {
    switch (lights) {
      case 'OFF':
        setColorfrfp('grey', 'grey')
        break
      case 'STOP':
        setColorfrfp('grey', 'red')
        break
      case 'PREPARE':
        setColorfrfp('grey', 'red')
        cross.setAttribute('visibility', 'visible')
        break
      case 'PREPARE2':
        setColorfrfp('#2dc937', 'grey') // #2dc937 is a shade of green
        cross.setAttribute('visibility', 'visible')
        break
      case 'GO_STRAIGHT':
      case 'GO_LEFT':
      case 'GO_RIGHT':
        setColorfrfp('#2dc937', 'grey') // #2dc937 is a shade of green
        break
      default:
        setColorfrfp('grey', 'grey')
    }
  } else if (fr === true || fp === true) {
    switch (lights) {
      case 'OFF':
        setColorfrfp('grey', 'grey')
        break
      case 'STOP':
        setColorfrfp('grey', 'red')
        break
      case 'GO_STRAIGHT':
      case 'GO_LEFT':
      case 'GO_RIGHT':
        setColorfrfp('#2dc937', 'grey') // #2dc937 is a shade of green
        break
      default:
        setColorfrfp('grey', 'grey')
    }
  } else if (fa === true) {
    switch (lights) {
      case 'OFF':
        setColorfafx('grey')
        break
      case 'STOP':
        setColorfafx('red')
        break
      default:
        setColorfafx('grey')
    }
  } else if (f01 === true) {
    switch (lights) {
      case 'OFF':
        setColorfafx('grey')
        break
      case 'STOP':
        setColorfafx('red')
        break
      default:
        setColorfafx('grey')
    }
  } else if (fx === true) {
    switch (lights) {
      case 'OFF':
        setColorfafx('grey')
        break
      case 'GO_STRAIGHT':
      case 'GO_LEFT':
      case 'GO_RIGHT':
        setColorfafx('white')
        break
      default:
        setColorfafx('grey')
    }
  } else if (tip === true) {
    switch (lights) {
      case 'OFF':
        go.setAttribute('visibility', 'hidden')
        stop.setAttribute('visibility', 'hidden')
        turn.setAttribute('visibility', 'hidden')
        break
      case 'STOP':
        go.setAttribute('visibility', 'hidden')
        stop.setAttribute('visibility', 'visible')
        turn.setAttribute('visibility', 'hidden')
        break
      case 'GO_STRAIGHT':
        go.setAttribute('visibility', 'visible')
        stop.setAttribute('visibility', 'hidden')
        turn.setAttribute('visibility', 'hidden')
        break
      case 'GO_LEFT':
        go.setAttribute('visibility', 'hidden')
        stop.setAttribute('visibility', 'hidden')
        turn.setAttribute('visibility', 'visible')
        break
      case 'GO_RIGHT':
        go.setAttribute('visibility', 'hidden')
        stop.setAttribute('visibility', 'hidden')
        turn.setAttribute('visibility', 'visible')
        break
      default:
        go.setAttribute('visibility', 'hidden')
        stop.setAttribute('visibility', 'hidden')
        turn.setAttribute('visibility', 'hidden')
    }
  } else if (fb === true) {
    switch (lights) {
      case 'OFF':
        setColorfafx('grey')
        break
      case 'STOP':
        setColorfafx('white')
        cross.setAttribute('visibility', 'visible')
        cross.setAttribute('fill', 'black')
        break
      default:
        setColorfafx('grey')
    }
  }
}
