<template>
  <div class="replay" id="replay"
    ref="replay"
    v-show="modalReplayShow"
    @mousedown="startDragging"
    @mouseup="stopDragging"
    @mousemove="dragging">
    <div class="replay_content">
      <div class="replay_header">
        <v-container>
          <v-row align=baseline justify="space-between">
            <h1 class="replay_title">Replay</h1>
            <input type="file" @change="handleFileUpload">
            <div v-if="closestTimestampData">{{ closestTimestampData.numberedTimestamp}} / {{ Object.keys(this.timelineData).length }}</div>
          </v-row>
        </v-container>
      </div>
      <div class="replay_body">
        <v-container>
          <v-row justify="space-around" align="baseline">
            <v-col cols="12" sm="4">
              <date-time-picker :label="$t('from')" clearable="true" v-model="since"></date-time-picker>
            </v-col>
            <v-col cols="12" sm="4">
              <date-time-picker :label="$t('to')" clearable="true" v-model="until"></date-time-picker>
            </v-col>
            <div v-if=!loading>
              <v-btn @click="getLog">Load</v-btn>
            </div>
            <div v-else-if=loading>
              <v-progress-circular
                :width="5"
                :size="40"
                color="#1E88E5"
                indeterminate
                >
              </v-progress-circular>
            </div>
          </v-row>
          <v-row justify="center">
            <v-btn icon
              :disabled="playing || !showLogs"
              @click="start"
              >
              <v-icon>mdi-play</v-icon>
            </v-btn>
            <v-btn icon
              :disabled="!playing || !showLogs"
              @click="pause"
              >
              <v-icon>mdi-pause</v-icon>
            </v-btn>
            <v-btn icon
              :disabled="time === from || !showLogs"
              @click="moveToPreviousTimestamp"
              >
              <v-icon>mdi-skip-previous</v-icon>
            </v-btn>
            <v-btn icon
              :disabled="!moveToNextTimestamp || !showLogs"
              @click="moveToNextTimestamp"
              >
              <v-icon>mdi-skip-next</v-icon>
            </v-btn>
            <v-menu bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon
                  :disabled="!showLogs"
                  v-on="on"
                  >
                  <v-icon>mdi-speedometer</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="item in speeds"
                  :key="item.value"
                  :disabled="item.value === speed  "
                  @click="speed = item.value"
                  >
                  {{ item.text }}
                </v-list-item>
              </v-list>
            </v-menu>
          </v-row>
          <v-row>
            <v-slider v-if="showLogs"
                      v-model="time"
                      color="#1E88E5"
                      track-color="#BBDEFB"
                      show-ticks step="1"
                      :min="from"
                      :max="to"
                      @click="replayResetTime">
            </v-slider>
          </v-row>
          <v-divider/>
          <v-row v-if="showLogs" align=baseline>
            <v-btn icon small disabled>
              <v-icon small>mdi-clock-outline</v-icon>
            </v-btn>
            <span>{{ sliderTime }} | {{ timeToNextTimestamp }}</span>
          </v-row>
        </v-container>
      </div>
    </div>
    <div class="logs-container scroll" v-if="closestTimestampData">
      <div class="logs-wrapper">
        <div class="logs">
          <h3>Changed Assets:</h3>
          <ul>
            <li v-for="(assetChanges, shvPath) in closestTimestampData.changes" :key="shvPath">
              <strong>{{ shvPath }}</strong>
              <ul>
                <div v-for="(change, index) in assetChanges" :key="index">
                  <template v-for="(value, key) in change.changes">
                    <li :key="key">{{ key }}: {{ value }} </li>
                  </template>
                </div>
                <p></p>
              </ul>
            </li>
          </ul>
        </div>
       </div>
    </div>
  </div>
</template>
<script>

import DateTimePicker from './date-time-picker.new.vue'
import moment from 'moment'
import { mapState } from 'vuex'
import { parseStatusNode } from '../../siteware-lib/src/components/property-sheets/shv-3/utils.js'
import { getDeviceAndAttributePath } from '../../siteware-lib/src/services/shv-parser/device-parser'
import { updateSvg } from '../../siteware-lib/src/helpers/svg-interaction'
import lodash from 'lodash'

const tickDuration = 250

export default {
  name: 'Replay',
  data () {
    const now = new Date().getTime()
    return {
      speeds: [
        { text: '1/16x', value: 1 / 16 },
        { text: '1/8x', value: 1 / 8 },
        { text: '1/4x', value: 1 / 4 },
        { text: 'normal', value: 1 },
        { text: '4x', value: 4 },
        { text: '8x', value: 8 },
        { text: '16x', value: 16 }
      ],
      loop: null,
      playing: false,
      speed: 1,
      modalReplayShow: false,
      since: now - 20 * 60 * 1000,
      until: now,
      time: 0,
      showLogs: false,
      logs: [],
      loading: false,
      from: 1712403679000,
      to: 1712407982000,
      nextItemTime: null,
      timelineData: Object,
      closestTimestampData: null,
      timestamps: null
    }
  },
  watch: {
    time (newTime) {
      this.$emit('time', this.time)
    },
    currentTimestampData (timestamp) {
      if (timestamp) {
        this.replayUpdate(timestamp)
        this.closestTimestampData = timestamp
        console.log('closestTimestampData', this.closestTimestampData.changes)
      }
    }
  },
  methods: {
    downloadLogs () {
      const logsString = JSON.stringify(this.logs, null, 2)
      const blob = new Blob([logsString], { type: 'text/plain' })
      const a = document.createElement('a')
      const url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = 'logs.txt'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)
    },
    handleFileUpload (event) {
      const file = event.target.files[0]
      const reader = new FileReader()
      this.showLogs = true

      reader.onload = () => {
        const logsData = JSON.parse(reader.result)
        this.timelineData = this.timelineDataCalculation(logsData)
      }
      reader.readAsText(file)
    },
    show () {
      const replay = document.getElementById('replay')
      replay.classList.add('show')
      this.modalReplayShow = true
      this.$tygroshka.service.sendMessage('.broker/app', 'unsubscribe', '{"method":"chng", "path": "shv/test/bel/cha/tram/anderlues" }')
    },
    hide () {
      const replay = document.getElementById('replay')
      replay.classList.remove('show')
      this.$tygroshka.service.sendMessage('.broker/app', 'subscribe', '{"method":"chng", "path": "shv/test/bel/cha/tram/anderlues" }')
      this.$tygroshka.service.getAllValues()
      this.modalReplayShow = false
    },
    startDragging (event) {
      if (event.target.classList.contains('replay_header') || event.target.closest('.replay_header')) {
        this.draggingActive = true
        this.startX = event.clientX - this.$refs.replay.offsetLeft
        this.startY = event.clientY - this.$refs.replay.offsetTop
        this.$refs.replay.classList.add('grabbing')
      }
    },
    stopDragging () {
      this.draggingActive = false
      this.$refs.replay.classList.remove('grabbing')
    },
    dragging: lodash.debounce(function (event) {
      if (this.draggingActive) {
        const x = event.clientX - this.startX
        const y = event.clientY - this.startY
        this.$refs.replay.style.left = `${x}px`
        this.$refs.replay.style.top = `${y}px`
      }
    }, 10),
    async getLog () {
      this.loading = true
      const systemRoot = this.shvPath.replace('shv', 'history')
      const formatDate = (time) => moment(time).utc().toISOString()
      const params = `{
        "withSnapshot": true
        "withPathsDict": false,
        "since":d"${formatDate(this.since)}",
        "until":d"${formatDate(this.until)}",
        "recordCountLimit": 5000
      }`
      const result = await this.$tygroshka.service.sendMessage(systemRoot, 'getLog', params, 'gettingLog')
      this.logs = result.result().value
      // console.log('loggs', this.logs)
      this.showLogs = true
      this.loading = false
      this.from = this.since
      this.to = this.until
      this.timelineData = this.timelineDataCalculation(result.result().value)
      this.timestamps = Object.keys(this.timelineData)
    },
    getTranslation (asset, node, variableName) {
      // return { node: this.node, name: variableName, deviceType: this.deviceType }
      let translationString
      if (node.parentType) {
        translationString = `type.${node.parentType}.fields.${variableName}.label`
      } else {
        translationString = `device.${asset.assetType.deviceType}/${variableName}.label`
      }
      return this.translations[translationString] ? this.translations[translationString] : translationString
    },
    timelineDataCalculation (logs) {
      const sortedLogs = logs.slice().sort((a, b) => a.value[0].value.epochMsec - b.value[0].value.epochMsec)
      let timestampCount = 1
      const result = sortedLogs.reduce((result, row, index) => {
        const timestamp = moment(row.value[0].value.epochMsec).format('DD.MM. YYYY - HH:mm:ss.SSS')
        const numberedTimestamp = `${timestampCount}`
        const shvPath = row.value[1].value
        const assetAndAttribute = getDeviceAndAttributePath(shvPath, this.shvData.assetsByPath)
        if (assetAndAttribute) {
          const asset = assetAndAttribute[0]
          const attribute = assetAndAttribute[1]
          const shValue = row.value[2].value
          const assetNode = asset.nodes.find(n => n.name === attribute)
          if (assetNode) {
            const variables = parseStatusNode(attribute, { typeName: assetNode.typeName, value: shValue }, this.shvData.types)
            if (variables.length > 0) {
              const variableMap = new Map()
              for (const [variableName, variableNode] of variables) {
                variableMap.set(`Status${asset.assetType.deviceType}/${variableName}`, variableNode.value)
              }

              let existingAsset = result[timestamp]?.assets.find(a => a.shvPath === shvPath)
              if (!existingAsset) {
                existingAsset = {
                  variables: variableMap,
                  shvPath: asset.shvPath,
                  deviceType: asset.assetType.deviceType
                }
                if (!result[timestamp]) {
                  result[timestamp] = {
                    numberedTimestamp,
                    previousTimestamp: index > 0 ? moment(sortedLogs[index - 1].value[0].value.epochMsec).format('DD.MM. YYYY - HH:mm:ss.SSS') : null,
                    nextTimestamp: index < sortedLogs.length - 1 ? moment(sortedLogs[index + 1].value[0].value.epochMsec).format('DD.MM. YYYY - HH:mm:ss.SSS') : null,
                    assets: [existingAsset]
                  }
                  timestampCount++
                } else {
                  result[timestamp].assets.push(existingAsset)
                }
              } else {
                variableMap.forEach((value, key) => {
                  if (!existingAsset.variables.has(key)) {
                    existingAsset.variables.set(key, value)
                  }
                })
              }
            }
          }
        } else {
          console.log('no device with shv path', shvPath)
        }
        return result
      }, {})
      // console.log('timelineFirstresult', result)
      this.fillMissingAsset(result)
      this.addChangesToTimelineData(result)
      this.time = moment(Object.keys(result)[0], 'DD.MM. YYYY - HH:mm:ss.SSS').valueOf()
      console.log('timelineData', result)
      return result
    },
    fillMissingAsset (result) {
      const allAssetsEverSeen = []
      for (const timestamp in result) {
        const currentAssets = result[timestamp].assets

        currentAssets.forEach(asset => {
          const existingIndex = allAssetsEverSeen.findIndex(a => a.shvPath === asset.shvPath)
          if (existingIndex === -1) {
            allAssetsEverSeen.push({
              variables: new Map(asset.variables),
              shvPath: asset.shvPath,
              deviceType: asset.deviceType
            })
          } else {
            const existingAsset = allAssetsEverSeen[existingIndex]
            asset.variables.forEach((value, key) => {
              existingAsset.variables.set(key, value)
            })
          }
        })

        allAssetsEverSeen.forEach(asset => {
          const existingAsset = currentAssets.find(a => a.shvPath === asset.shvPath)
          if (!existingAsset) {
            currentAssets.push({
              variables: new Map(asset.variables),
              shvPath: asset.shvPath,
              deviceType: asset.deviceType
            })
          }
        })
      }
      // console.log('timelinefillMA', result, allAssetsEverSeen)
      return result
    },
    addChangesToTimelineData (result) {
      const timestamps = Object.keys(result)

      for (let i = 1; i < timestamps.length; i++) {
        const currentTimestamp = timestamps[i]
        const previousTimestamp = timestamps[i - 1]
        const currentData = result[currentTimestamp].assets
        const previousData = result[previousTimestamp].assets

        const changes = {}

        currentData.forEach(currentAsset => {
          const previousAsset = previousData.find(asset => asset.shvPath === currentAsset.shvPath)

          if (previousAsset) {
            const assetChanges = {}

            currentAsset.variables.forEach((currentValue, variableName) => {
              const previousValue = previousAsset.variables.get(variableName)

              if (currentValue !== previousValue) {
                assetChanges[variableName] = `${previousValue} -> ${currentValue}`
              }
            })

            if (Object.keys(assetChanges).length > 0) {
              if (!changes[currentAsset.shvPath]) {
                changes[currentAsset.shvPath] = []
              }

              changes[currentAsset.shvPath].push({
                shvPath: currentAsset.shvPath,
                changes: assetChanges
              })
            }
          }
        })

        result[currentTimestamp].changes = changes
      }

      return result
    },
    replayUpdate (data) {
      const assets = data.assets
      for (const asset of assets) {
        updateSvg(asset)
      }
    },
    replayResetTime () {
      const timestamps = Object.keys(this.timelineData)
      const currentTimestamp = moment(this.time)
      // this.index = timestamps.findIndex(timestamp => this.timelineData[timestamp] === this.closestTimestampData)
      const previousTimestamps = timestamps.filter(
        t => moment(t, 'DD.MM. YYYY - HH:mm:ss.SSS').isBefore(currentTimestamp)
      )
      if (previousTimestamps.length > 0) {
        const closestTimestamp = previousTimestamps[previousTimestamps.length - 1]
        // console.log('Closest previous timestamp:', closestTimestamp)
        this.closestTimestampData = this.timelineData[closestTimestamp]
        this.replayUpdate(this.closestTimestampData)
      } else {
        console.log('No previous timestamp found.')
      }
    },
    start () {
      if (!this.playing) {
        this.playing = true
        this.tick()
      }
    },
    pause () {
      this.playing = false
      clearTimeout(this.loop)
    },
    moveToNextTimestamp () {
      const timestamps = Object.keys(this.timelineData)
      const nextTimestamps = timestamps.filter(
        t => moment(t, 'DD.MM. YYYY - HH:mm:ss.SSS').valueOf() > this.time
      )
      if (nextTimestamps.length > 0) {
        const nextTimestamp = nextTimestamps[0]
        const nextTimestampParsed = moment(nextTimestamp, 'DD.MM. YYYY - HH:mm:ss.SSS').valueOf()
        // console.log('Moving to next timestamp:', this.time, '=>', nextTimestampParsed)
        this.time = nextTimestampParsed
      }
    },
    moveToPreviousTimestamp () {
      const timestamps = Object.keys(this.timelineData)
      const previousTimestamps = timestamps.filter(
        t => moment(t, 'DD.MM. YYYY - HH:mm:ss.SSS').valueOf() < this.time
      )
      if (previousTimestamps.length > 0) {
        const previousTimestamp = moment(previousTimestamps[previousTimestamps.length - 1], 'DD.MM. YYYY - HH:mm:ss.SSS').valueOf()
        // console.log('Moving to previous timestamp:', previousTimestamp)
        this.time = previousTimestamp
      }
    },
    tick () {
      const { speed, from, to } = this
      this.loop = setTimeout(() => {
        this.time += speed * tickDuration

        if (this.time >= to) {
          this.playing = false
          this.time = from
        }
        if (this.time >= moment(this.nextItemTime, 'DD.MM. YYYY - HH:mm:ss.SSS').valueOf()) {
          this.time = moment(this.nextItemTime, 'DD.MM. YYYY - HH:mm:ss.SSS').valueOf()
        }
        this.replayResetTime()
        if (this.playing) {
          this.tick()
        }
      }, tickDuration)
    }
  },
  computed: {
    timeToNextTimestamp () {
      const timestamps = Object.keys(this.timelineData)
      const currentTimestamp = moment(this.time)
      const currentIndex = timestamps.findIndex(timestamp => moment(timestamp, 'DD.MM. YYYY - HH:mm:ss.SSS').isSameOrAfter(currentTimestamp, 'millisecond'))
      // console.log('currrentIndex', currentIndex)
      if (currentIndex !== -1 && currentIndex < timestamps.length - 1) {
        const nextTimestamp = moment(timestamps[currentIndex + 1], 'DD.MM. YYYY - HH:mm:ss.SSS')
        // console.log('nextTimestamp', nextTimestamp)
        const duration = moment.duration(nextTimestamp.diff(currentTimestamp))
        const durationMilliseconds = duration.asMilliseconds()
        const durationSeconds = duration.asSeconds()
        const durationMinutes = duration.asMinutes()
        if (durationMilliseconds < 1000) {
          return `Next event in ${durationMilliseconds} milliseconds`
        } else if (durationSeconds < 60) {
          return `Next event in 0:${Math.floor(durationSeconds).toString().padStart(2, '0')}`
        } else if (durationMinutes < 60) {
          const minutes = Math.floor(durationMinutes)
          const seconds = Math.floor(durationSeconds % 60)
          return `Next event in ${minutes}:${seconds.toString().padStart(2, '0')}`
        } else {
          return `${Math.floor(durationMinutes / 60)} hours`
        }
      } else if (currentIndex === timestamps.length - 1) {
        return 'You have reached last event'
      } else {
        return 'You are past last event'
      }
    },
    sliderTime () {
      return moment(this.time).format('DD.MM.YYYY HH:mm:ss.SSS')
    },
    currentTimestampData () {
      const timestamp = moment(this.time).format('DD.MM. YYYY - HH:mm:ss.SSS')
      return this.timelineData[timestamp]
    },
    ...mapState('tygroshka', [
      'shvPath',
      'translations',
      'shvData'
    ])
  },
  components: {
    DateTimePicker
  }
}
</script>
<style scoped>
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.replay {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  max-height: 800px;
}

.replay_content {
  border-radius: 20px;
  min-width: 400px;
  min-height: 280px;
  width: 500px;
  height: 280px;
  background: rgb(255, 255, 255);
  border-color: #1E88E5;
  border-style: solid;
  border-width: thin;
}

.grabbing {
  cursor: grabbing;
}
.replay.show{
  visibility: visible;
  opacity: 1;
}
.replay.show .replay_content {
  visibility: visible;
  opacity: 1;
  transform: scaleY(1);
}
.replay_header {
  cursor: grab;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 5px 10px;
  border-bottom: 1px solid;
}
.replay_title {
  font-weight:bold;
  font-size: 25px;

}
.replay_body {
  padding: 10px;
  text-align: justify;
}
.logs-container {
  font-size: small;
  height: 200px;
  width: 500px;
  overflow-y: auto;
  background-color: white; /* Nontransparent background */
  border-color: #1E88E5;
  border-style: solid;
  border-width: thin;
  border-radius: 20px;
}

.logs-wrapper {
  padding: 10px;
}

.scroll::-webkit-scrollbar {
  width: 15px;
}

.logs {
  padding: 10px; /* Optional: Add some padding for better appearance */
  border-radius: 5px; /* Optional: Add rounded corners */
}
</style>
