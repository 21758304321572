<template>
  <div class="visual-panel">
    <div class="visual" v-if="svgImage">
      <div class="stage-parent">
        <div v-html="convertedSVG()"></div>
      </div>
    </div>
    <div v-if="!svgImage">
      NO SVG LOADED
    </div>

    <transition v-if="selectedAsset && showAssetDetail">
      <v-col
        sm="12"
        md="9"
        lg="9"
        xl="6"
        class="ret-alarms-panel ma-0 pa-0"
      >
        <v-card>
          <v-toolbar class="primary">
            <v-toolbar-title style="color: white;"><strong>{{ selectedAsset.name }}</strong></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closeAssetDetail()"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar>
          <v-card-text class="py-0">
            <v-container>
              <asset-detail-shv3></asset-detail-shv3>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </transition>
    <div id="click-simulator"></div>
  </div>
</template>

<style scoped lang="scss">
  div.stage-parent {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .ret-alarms-panel {
    position: fixed;
    top: 3.5em;
    right: 0.3em;
    min-height: 30%;
    max-height: 85vh;
    overflow-y: auto;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    opacity: 1;
  }

  @keyframes symbolBlinking {
    0%{opacity: 0}
    50%{opacity: 1}
    100%{opacity: 1}
  }

  .symbol-blinking {
    animation: symbolBlinking 1s infinite step-end;
  }
</style>

<script>
import Vue from 'vue'
import Hammer from 'hammerjs'
import svgPanZoom from 'svg-pan-zoom'
import { updateSvg, updateAlarmIndicator } from '../helpers/svg-interaction'
// import { sleep } from '../helpers/utils'

import AssetDetailShv3 from '../assets-tree/asset-detail.vue'

import { mapState } from 'vuex'

export default Vue.extend({
  name: 'visual-panel',

  components: {
    AssetDetailShv3
  },

  props: {
    visual: {
      type: String,
      default: 'optsite.svg'
    },
    details: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      showAssetDetail: true,
      selectedAssetShvPath: null,
      selectedAsset: undefined,
      subscriptions: [],
      svgObject: undefined,
      ready: false,
      panZoomObject: undefined
    }
  },

  computed: {
    ...mapState('tygroshka', [
      'assets',
      'shvData',
      'alarms',
      'shvPath',
      'svgPath',
      'svgImage',
      'activeAsset',
      'assetsLoaded',
      'shvVersion',
      'plcDisconnected'
    ]),
    windowHeight () {
      return this.$vuetify.breakpoint.height
    },
    showCustomCommands () {
      return ['TunnelHel002', 'TunnelHel002D'].includes(this.selectedAsset.deviceType)
    },
    windowWidth () {
      return this.$vuetify.breakpoint.width
    },
    sidebar () {
      return this.$store.state.sidebar
    }
  },

  async mounted () {
    console.log('LOADING SVG................')
    this.$tygroshka.service.assetChange.clearSubscriptions()
    this.$tygroshka.service.assetChange.subscribe((assetWithAttribute) => {
      const { asset, attribute } = assetWithAttribute
      // console.log('drawing asset from sub')
      this.drawAsset(asset, attribute)
    })
    const svg = document.getElementById('svg-container')
    if (svg) {
      this.prepareSVG()
    }
  },

  methods: {
    prepareSVG () {
      const eventsHandler = {
        hammer: {},
        haltEventListeners: ['touchstart', 'touchend', 'touchmove', 'touchleave', 'touchcancel', 'pinch', 'doubletap'],
        init: function (options) {
          const instance = options.instance
          let initialScale = 1
          let pannedX = 0
          let pannedY = 0

          // Init Hammer
          // Listen only for pointer and touch events
          this.hammer = new Hammer(options.svgElement, {
            inputClass: (Hammer).SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput
          })

          // Enable pinch
          this.hammer.get('pinch').set({ enable: true, passive: false })

          // Handle pan
          this.hammer.on('panstart panmove', function (event) {
            // On pan start reset panned variables
            if (event.type === 'panstart') {
              pannedX = 0
              pannedY = 0
            }

            // Pan only the difference
            instance.panBy({
              x: event.deltaX - pannedX,
              y: event.deltaY - pannedY
            })
            pannedX = event.deltaX
            pannedY = event.deltaY
          })

          // Handle pinch
          this.hammer.on('pinchstart pinchmove', function (event) {
            // On pinch start remember initial zoom
            if (event.type === 'pinchstart') {
              initialScale = instance.getZoom()
              instance.zoomAtPoint(initialScale * event.scale, { x: event.center.x, y: event.center.y })
            }

            instance.zoomAtPoint(initialScale * event.scale, { x: event.center.x, y: event.center.y })
          })

          // Prevent moving the page on some devices when panning over SVG
          options.svgElement.addEventListener('touchmove', function (event) {
            event.preventDefault()
          }, { pasive: false })
        },
        destroy: function () {
          this.hammer.destroy()
        }
      }
      const _this = this

      // document.getElementById('svg-container').addEventListener('load', () => {
      // const svgContainer = document.getElementById('svg-object')
      // await sleep(1000)
      const svg = document.getElementById('svg-container')

      this.setSVGsize()
      _this.draw()
      // dynamically add blinking class to SVG
      const styleToAdd = `
        @keyframes symbolBlinking {
          0%{opacity: 0}
          50%{opacity: 1}
          100%{opacity: 1}
        }
        @keyframes symbolBlinkingInvert {
          0%{opacity: 1}
          50%{opacity: 0}
          100%{opacity: 0}
        }
        @keyFrames symbolBlinkingBlackBlue {
          0%{fill:#000000}
          50%{fill:#0000EE}
          100%{fill:#0000BB}
        }
        @keyFrames redYellowBlinking {
          0%{fill: red}
          50%{fill: yellow}
        }
        @keyFrames redGreenBlinking {
          0%{fill: red}
          50%{fill: green}
        }
        @keyFrames yellowGreenBlinking {
          0%{fill: yellow}
          50%{fill: green}
        }
        @keyFrames redYellowGreenBlinking {
          0%{fill: red}
          33%{fill: yellow}
          66%{fill: green}
        }

        .blinking-red-yellow-green {
          animation: redYellowGreenBlinking 3s infinite step-end;
        }

        .blinking-red-yellow {
          animation: redYellowBlinking 2s infinite step-end;
        }
        .blinking-red-green {
          animation: redGreenBlinking 2s infinite step-end;
        }
        .blinking-yellow-green {
          animation: yellowGreenBlinking 2s infinite step-end;
        }

        .symbol-blinking-black-blue {
          animation: symbolBlinkingBlackBlue 1s infinite step-end;
        }
        .symbol-blinking {
          animation: symbolBlinking 1s infinite step-end;
        }
        .symbol-blinking-invert {
          animation: symbolBlinkingInvert 1s infinite step-end;
        }
      `
      const style = document.createElementNS('http://www.w3.org/2000/svg', 'style')
      style.textContent = styleToAdd

      svg.insertBefore(style, svg.firstChild)

      _this.addElementEvents()
      _this.$nextTick(() => {
        const controls = document.getElementById('svg-container').querySelector('[id="svg-pan-zoom-controls"]')
        controls.setAttribute('transform', 'translate(5 5)')
      })

      this.panZoomObject = svgPanZoom('#svg-container', {
        fit: true,
        zoomEnabled: true,
        dblClickZoomEnabled: false,
        mouseWheelZoomEnabled: false,
        center: true,
        controlIconsEnabled: true,
        customEventsHandler: eventsHandler
      })

      _this.ready = true
    },

    setSVGsize () {
      // const svgContainer = document.getElementById('svg-object')
      const svg = document.getElementById('svg-container')
      // console.log('svg incoming', svg)
      const width = this.sidebar ? this.windowWidth - 120 : this.windowWidth - 40
      svg.setAttribute('height', this.windowHeight - 100)
      svg.setAttribute('width', width)
      if (this.panZoomObject) {
        this.panZoomObject.resize()
        this.panZoomObject.fit()
        this.panZoomObject.center()
        this.panZoomObject.resetZoom()
        const controls = svg.querySelector('[id="svg-pan-zoom-controls"]')
        controls.setAttribute('transform', 'translate(5 5)')
      }
    },

    fullShvPath (relativeShvpath) {
      return `${this.shvPath}/${relativeShvpath}`
    },

    draw () {
      // console.log('trying to draw', this.shvData)
      const assets = Object.entries(this.shvData.assetsByPath).map(([shvPath, asset]) => {
        return {
          deviceType: asset.assetType.deviceType,
          shvPath: asset.shvPath,
          variables: asset.variables
        }
      })
      Object.values(assets).forEach(asset => {
        this.drawAsset(asset)
      })
    },

    convertedSVG () {
      return this.svgImage
        .replace(/font-size=".+"/g, '')
        .replace(/style="(?<style>.+)"/g, (g0, s, offset, input, { style }) => style.split(';')
          .map(a => {
            return a.replace(/(?<attribute>.+):(?<value>.+)/g, (g0, s, offset, input, { attribute }) => {
              return `${s}="${offset}"`
            })
          }).join(' '))
    },

    drawAsset (asset, attribute) {
      console.log('draw asset', asset, this.shvVersion)
      let assets
      if (this.shvVersion[0] === '3') {
        assets = this.shvData.assetsByPath
      } else {
        assets = this.assets
      }
      // console.log('drawing asset.....', assets, this.shvData.assetsByPath)
      updateSvg(asset, assets, this.alarms, attribute)

      const existingAlarmsForAsset = this.alarms.filter(a => {
        // const regexx = new RegExp(`${a.shvPath}.*`)
        // return regexx.test(asset.shvPath)
        return asset.shvPath === a.shvPath
      })
      updateAlarmIndicator(asset.shvPath, existingAlarmsForAsset, attribute)
    },

    sendCommand (commandObject) {
      if (this.selectedAsset) {
        this.sendMessage(
          `${this.selectedAsset.shvPath}/${commandObject.attribute}`,
          commandObject.method,
          commandObject.params
        )
      }
    },

    closeAssetDetail () {
      this.selectedAsset = undefined
      this.showAssetDetail = false
      this.deselectElement()
    },

    addElementEvents () {
      // const svgObject = document.getElementById('svg-object')
      // if (!svgObject) {
      // return
      // }

      const svg = document.getElementById('svg-container')
      if (!svg) {
        return
      }

      svg.querySelectorAll('[shvpath]')
        .forEach((element) => {
          const shvPath = element.getAttribute('shvpath')
          if (this.shvVersion[0] === '2') {
            const asset = this.assets[this.fullShvPath(shvPath)]
            if (!asset) {
              // console.log('no asset yet')
              return
            }
            if (asset.variables && asset.variables.get('elementDisabled')) {
              // do not use click event on disabled element
              return
            }
            // click: select element
            element.addEventListener('click', (event) => {
              event.stopPropagation()

              if (event.shiftKey && asset.deviceType === 'TC') {
                this.$tygroshka.service.sendMessage(`${this.fullShvPath(asset.shvPath)}/sim`, 'toggleOccupied')
              } else {
                const { selectedAssetShvPath } = this
                if (selectedAssetShvPath === asset.shvPath) {
                  return
                }
                if (selectedAssetShvPath && selectedAssetShvPath !== asset.shvPath) {
                  this.deselectElement()
                }
                // console.log('selecting asset', asset, element)
                this.selectElement(asset, element)
              }
            })
          } else {
            const assets = this.shvData.assetsByPath
            const asset = assets[shvPath]

            if (!asset) {
              // console.log('no asset for', `${shvPath}`)
              return
            }
            if (asset.variables && asset.variables.get('elementDisabled')) {
              // do not use click event on disabled element
              return
            }
            element.addEventListener('click', (event) => {
              event.stopPropagation()

              if (event.shiftKey && asset.deviceType === 'TC') {
                this.$tygroshka.service.sendMessage(`${this.fullShvPath(shvPath)}/sim`, 'toggleOccupied')
              } else {
                const { selectedAssetShvPath } = this
                if (selectedAssetShvPath === shvPath) {
                  return
                }
                if (selectedAssetShvPath && selectedAssetShvPath !== shvPath) {
                  this.deselectElement()
                }
                this.selectElement({ shvPath, ...asset }, element)
              }
            })
          }
          // mouseover: show glow
          // element.addEventListener('mouseover', onElementSelect.bind(element))
          // mouseout: hide glow
          // element.addEventListener('mouseout', onElementDeselect.bind(element))
        })
    },

    selectElement (asset, element) {
      element.setAttribute('selected', true)
      this.showAssetDetail = true
      this.selectedAssetShvPath = asset.shvPath
      this.$store.commit('tygroshka/currentAssetShvPath', asset.shvPath)
      this.selectedAsset = asset
      const clickSimulator = document.getElementById('click-simulator')
      clickSimulator.click()

      this.$emit('selected', asset)
    },

    deselectElement () {
      console.log('trying to deselect element!!!')
      // const svgObject = document.getElementById('svg-object')
      const svg = document.getElementById('svg-container')
      svg
        .querySelectorAll('[observer-shvpath][selected]')
        .forEach((element) => {
          element.removeAttribute('selected')
          element.removeAttribute('filter')
        })
      this.selectedAssetShvPath = null
      this.selectedAsset = undefined
    }
  },

  watch: {
    assetsLoaded: {
      handler () {
        this.draw()
        this.addElementEvents()
      }
    },
    plcDisconnected: {
      handler () {
        console.log('plcDisconnected happened')
        this.draw()
        this.$store.commit('tygroshka/plcDisconnected', false)
      }
    },
    svgImage (val) {
      if (val) {
        this.$nextTick(() => {
          this.prepareSVG()
        })
      }
    },
    windowHeight: {
      handler (value) {
        console.log('new height', value)
        this.setSVGsize()
      }
    },
    windowWidth: {
      handler (value) {
        console.log('new width', value)
        this.setSVGsize()
      }
    }
  }
})
</script>
