import { getInnerElement, solveRadioBeamsG3 } from './tools'

export const awaG3 = (element, state, assetsData, options, attribute) => {
  const directionEl = getInnerElement(element, 'direction')

  // blinkOnVehicleDetected(element, state, 'vehicleId', attribute)
  solveRadioBeamsG3(element, state)

  if (directionEl && state.get('vehicleDetected')) {
    directionEl.setAttribute('visibility', 'hidden')
    if ((state.get('vehicleDetected').direction || state.get('vehicleDetected').direction === 0)) {
      directionEl.textContent = state.get('vehicleDetected').direction
      directionEl.setAttribute('visibility', 'visible')
    }
  }
}
