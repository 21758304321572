import { RpcMessage } from '../../../libshv-js/modules/rpcmessage'
import { RpcValue } from '../../../libshv-js/modules/rpcvalue'
import { ChainPackWriter, ChainPackReader, ChainPack } from '../../../libshv-js/modules/chainpack'
import { CponReader, Cpon } from '../../../libshv-js/modules/cpon'
import { UnpackContext } from '../../../libshv-js/modules/cpcontext'
import { EventEmitter } from '../helpers/event-emitter'

import Vue from 'vue'

const HEARTBEAT_INTERVAL = 15000

const decodeMsgLength = (data: any) => {
  if (data.byteLength === 0) {
    return { specifiedLength: 1, lengthOffset: 0 }
  }
  const dataView = new DataView(data.buffer ? data.buffer : data)
  // read first byte which contains information about length (and/or info about how many more bytes are needed to read)
  const firstByte = dataView.getUint8(0)
  // console.log('first byte to read', firstByte)
  let specifiedLength = firstByte
  const lengthBitString = specifiedLength.toString(2)

  // if (lengthBitString.length <= 7) {
  // return { specifiedLength: specifiedLength, lengthOffset: 1 } // 1 byte default for length under 7 bits
  // }
  // if bitstring starts with '1' it means that number for length is higher than 127
  // and thus needs to be decoded according to:
  // https://github.com/silicon-heaven/libshv/wiki/ChainPack-RPC
  // more bytes are needed than first one

  // const bytesToReadInfo = lengthBitString.substring(0, 4)

  let num = 0
  const head = firstByte // this.ctx.getByte();
  let bytesToReadCnt: any
  if ((head & 128) === 0) {
    bytesToReadCnt = 0; num = head & 127
  } else if ((head & 64) === 0) {
    bytesToReadCnt = 1
    num = head & 63
  } else if ((head & 32) === 0) {
    bytesToReadCnt = 2
    num = head & 31
  } else if ((head & 16) === 0) {
    bytesToReadCnt = 3
    num = head & 15
  } else {
    bytesToReadCnt = (head & 0xf) + 4
  }
  const bytesToRead = bytesToReadCnt + 1

  // number of 1s before first 0 at the beginning of string means how many additional bytes are needed
  // const additionalBytesToRead = bytesToReadInfo.indexOf('0')
  // const bytesToRead = 1 + additionalBytesToRead // first byte is always needed

  const lengthBytes: string[] = []
  // [...Array(bytesToRead).keys()] returns array like [0, 1, 2] defined by bytes to read
  // create array with bitstrings for length
  for (const index of [...Array(bytesToRead).keys()]) {
    // pad start to fill leading 0
    lengthBytes.push(dataView.getUint8(index).toString(2).padStart(8, '0'))
  }

  // bitstring with all bytes containing message length
  // including first bits defining how many additional bytes were needed
  const fullLengthBitString = lengthBytes.join('')

  // remove leading 1s
  // parseInt(f)
  const finalBitstring = fullLengthBitString.slice(bytesToRead, fullLengthBitString.length)
  specifiedLength = parseInt(finalBitstring, 2)

  // logger.info(`LENGTH: ${specifiedLength}`)
  // console.log('read length', specifiedLength, bytesToRead)
  return { specifiedLength: specifiedLength, lengthOffset: bytesToRead }
}

export class SHVClient {
  public client: WebSocket|null = null
  public connected: EventEmitter<void> = new EventEmitter<void>()
  public received: EventEmitter<RpcMessage> = new EventEmitter<RpcMessage>()

  private requestId = 0
  private promises: any = {}
  private readData = new Uint8Array(new ArrayBuffer(0))

  private isUseCpon: boolean = false
  private wsUri: string = ''
  public state: string = 'UNKNOWN'
  private messageReceived = false

  constructor (wsUri: string,
               private user: string,
               private password: string,
               private subscribePath: string,
               private store: any) {
    this.wsUri = wsUri
    this.openSocket(wsUri)
  }

  private openSocket (wsUri: string) {
    this.client = new WebSocket(wsUri)
    // this.checkSocket()
    this.client.binaryType = 'arraybuffer'

    this.client.onopen = (evt) => {
      this.connect()
    }

    this.client.onmessage = (evt) => {
      this.receiveMessage(evt)
    }

    this.client.onerror = (evt) => {
      // console.log('ERROR: ', evt)
    }

    this.client.onclose = (evt) => {
      this.checkSocket()
    }
  }

  closeSocket () {
    if (this.client) {
      this.client.close()
    }
  }

  checkSocket () {
    // console.log('checking socket', this.state, this.client)
    switch (this.client.readyState) {
      case 0: this.state = 'CONNECTING'; break
      case 3: this.state = 'DISCONNECTED'; break
      case 1: this.state = 'CONNECTED'; break
      case 2: this.state = 'CLOSING'; break
      default: this.state = 'UNKNOWN'
    }
    // console.log('client state', this.client.readyState, this.state)
    // if ((this.client.readyState === 3 || this.state === 'DISCONNECTED') && this.state !== 'CONNECTING') {
    // console.log('trying to connect ')
    // this.closeSocket()
    // this.openSocket(this.wsUri)
    // }
  }

  async sendMessage (shvPath, method, params): Promise<RpcMessage> {
    // this.checkSocket()

    if (this.client) {
      // console.log('asking shv for stuff', shvPath, method, params)
      return this.callRpcMethod(shvPath, method, params)
    } else {
      // throw { msg: 'missing connection' }
    }
  }

  private async connect () {
    this.requestId = 0

    const hello = await this.callRpcMethod(null, 'hello', null)

    // if (hello.requestId().value !== 1) { return }

    // <T:RpcMessage,id:2,method:"login">i{params:{"login":{"password":"lub42DUB","type":"PLAIN","user":"iot"},"options":{"device":{"mountPoint":"test/agent1"},"idleWatchDogTimeOut":0}}}
    const params = `{"login":{"password":"${this.password}","type":"PLAIN","user":"${this.user}"}}`
    const login = await this.callRpcMethod(null, 'login', params)

    if (login.requestId().value !== 2) { return }

    if (login.result() && login.result().value.clientId) {
      this.connected.emit()

      setInterval(() => {
        // to keep connection alive
        console.log('sending ping')
        this.messageReceived = false
        this.sendMessage('shv', 'dir', undefined)
        // console.log('this client readystate', this.client.readyState)
        setTimeout(() => {
          // console.log('checking if msg received', this.messageReceived)
          if (this.messageReceived === false) {
            console.log('set discoonected')
            this.state = 'DISCONNECTED'
          }
        }, 12000)
      }, HEARTBEAT_INTERVAL)

      this.callRpcMethod('.broker/app', 'subscribe', `{"method":"chng", "path": "${this.subscribePath}" }`)
    }
  }

  private parseRpcMessage (rawData) {
    // const enc = new TextDecoder('utf-8')
    // console.log('starting to parase rawData', rawData, enc.decode(rawData))
    const rpcVal = this.datatoRpcValue(rawData)
    // console.log('RAW data', rpcVal)
    const rpcMsg = new RpcMessage(rpcVal)

    // console.log('raw data received', rpcMsg)
    if (rpcMsg.isRequest()) {
      return this.processRequestMessage(rpcMsg)
    }

    // resolve stored promise
    const promise = this.promises[rpcMsg.requestId()]

    // console.log('received message', rpcVal, enc.decode(this.readData), promise)
    if (!promise) { return this.received.emit(rpcMsg) }

    delete this.promises[rpcMsg.requestId()]

    if (rpcMsg.error()) {
      promise.reject({ msg: 'error', error: rpcMsg.error().toString(), result: rpcMsg.result() })
      const errorObj = { error: rpcMsg.error(), result: rpcMsg.result(), requestId: rpcMsg.requestId().value }
      // console.log('there is error', rpcMsg.result(), rpcMsg.error().toString(), rpcMsg.requestId().value)
      this.store.commit('tygroshka/shvErrorMsg', errorObj)
    } else {
      // console.log('resolving promise', rpcMsg)
      promise.resolve(rpcMsg)
    }
  }

  private receiveMessage (event: any) {
    // this.checkSocket()
    // console.log('received msg', event.data, this.messageReceived)
    if (!event.data) { return }
    this.messageReceived = true
    this.state = 'CONNECTED'
    let workingData = this.readData.byteLength > 0 ? this.readData : event.data
    const { specifiedLength, lengthOffset } = decodeMsgLength(workingData) // reader.readUIntData()
    const msgLength = specifiedLength
    // console.log('msgLength existed', msgLength)
    const lengthToCheck = msgLength
    // const enc = new TextDecoder('utf-8')
    if (lengthToCheck + lengthOffset > workingData.byteLength) {
      // console.log('msg shorter than expected')
      const convertedArray = new Uint8Array(event.data)
      const newArray = new Uint8Array([...this.readData, ...convertedArray])
      this.readData = newArray
    }
    workingData = this.readData.byteLength > 0 ? this.readData : event.data

    // console.log('MESSAGE RAW...........', workingData.byteLength, lengthToCheck, lengthOffset, workingData, enc.decode(event.data))
    if (workingData.byteLength >= lengthToCheck + lengthOffset) {
      // console.log('MSG longer than expected', workingData.byteLength, lengthToCheck)
      let msgIndex = lengthToCheck
      let multipleMsgs = workingData

      while (multipleMsgs.byteLength > 0) {
        // console.log('get msg length', multipleMsgs)
        const { specifiedLength, lengthOffset } = decodeMsgLength(multipleMsgs) // reader.readUIntData()
        const offset = lengthOffset // firstByte.toString(2).substring(0, 4).indexOf('0')
        msgIndex = specifiedLength
        // console.log('OFFSET FOUND', msgIndex, offset, multipleMsgs)
        // console.log('slicing msgs ', msgIndex, offset, msgIndex + offset)
        const message = multipleMsgs.slice(0, msgIndex + offset)
        // console.log('sent messaget to parse', message, multipleMsgs)
        const rest = multipleMsgs.slice(msgIndex + offset, multipleMsgs.byteLength)
        // console.log('trying to decode rest', multipleMsgs, multipleMsgs.byteLength, rest.byteLength)
        const restSpecifiedLength = decodeMsgLength(rest).specifiedLength
        const restOffset = decodeMsgLength(rest).lengthOffset
        // console.log('rest check', multipleMsgs.byteLength, restSpecifiedLength)
        // console.log('putting uncomplete rest and try to parse message', rest)
        this.parseRpcMessage(message)
        // console.log('msg parsed', message)
        if (rest.byteLength < restSpecifiedLength + restOffset) {
          // console.log('really putting uncomplete rest and breaking while', rest.byteLength)
          this.readData = new Uint8Array(rest)
          break
        }
        multipleMsgs = rest
      }
    }
  }

  private processRequestMessage (rpcMsg: RpcMessage) {
    const method = rpcMsg.method().asString()
    const resp = new RpcMessage(undefined)

    if (method === 'dir') {
      resp.setResult(['ls', 'dir', 'appName'])
    } else if (method === 'ls') {
      resp.setResult([])
    } else if (method === 'appName') {
      resp.setResult(`tygroshka-${Vue.prototype.$config.projectName}`)
    }

    this.sendRpcMessage(this.client, resp)
  }

  private callRpcMethod (shvPath, method, params): Promise<RpcMessage> {
    const rq = new RpcMessage(undefined)
    const id = ++this.requestId

    rq.setRequestId(this.requestId)
    if (shvPath) {
      rq.setShvPath(shvPath)
    }
    rq.setMethod(method)
    if (params) {
      rq.setParams(RpcValue.fromCpon(params))
    }

    this.sendRpcMessage(this.client, rq)
    // console.log('send request', rq)

    return new Promise<RpcMessage>((resolve, reject) => {
      // save promise for future resolve
      this.promises[id] = { resolve: resolve, reject: reject }

      // remove from array if not receive response
      setTimeout(() => this.promises[id] && delete this.promises[id], 60000)
    })
  }

  private sendRpcMessage (socket, rpcMsg) {
    if (socket) {
      // console.log('sending rpc message:', rpcMsg.toString())
      let msgData = new Uint8Array()
      if (this.isUseCpon) {
        msgData = new Uint8Array(rpcMsg.toCpon())
      } else {
        msgData = new Uint8Array(rpcMsg.toChainPack())
      }

      const wr = new ChainPackWriter()
      wr.writeUIntData(msgData.length + 1)
      const dgram = new Uint8Array(wr.ctx.length + 1 + msgData.length)
      let ix = 0
      for (let i = 0; i < wr.ctx.length; i++) {
        dgram[ix++] = wr.ctx.data[i]
      }

      if (this.isUseCpon) {
        dgram[ix++] = Cpon.ProtocolType
      } else {
        dgram[ix++] = ChainPack.ProtocolType
      }

      for (let i = 0; i < msgData.length; i++) {
        dgram[ix++] = msgData[i]
      }
      // console.log('sending ' + dgram.length + ' bytes of data')
      socket.send(dgram.buffer)
    }
  }

  private datatoRpcValue (buff) {
    const reader = new ChainPackReader(new UnpackContext(buff))
    reader.readUIntData()
    // console.log('msg length', msgLength, buff)
    // if (msgLength > buff.byteLength) {
    //   return
    // }

    if (reader.ctx.getByte() === Cpon.ProtocolType) {
      // reader.ctx.resetIndex()
      return new CponReader(reader.ctx).read()
    } else {
      // reader.ctx.resetIndex()
      return new ChainPackReader(reader.ctx).read()
    }
  }
}
