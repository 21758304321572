<template>
  <div>
    LOGIN HERE
    <div class="login-panel">
      <v-card-title class="ret-card">
        <v-card-title>
          <h3 class="headline">{{ $t('sign-in-caps') }}</h3>
        </v-card-title>
        <v-card-text>
          <form>
            <input-with-sw-keyboard
              :label="$t('username')"
              :cleanAfterSent="cleanAfterSent"
              uniqueInputName="app.shvUsername"
              layout="alphabetic">
            </input-with-sw-keyboard>
            <input-with-sw-keyboard
              :label="$t('password')"
              :cleanAfterSent="cleanAfterSent"
              uniqueInputName="app.shvPassword"
              layout="alphabetic"
              :inputType="'password'">
            </input-with-sw-keyboard>
          </form>
          <v-btn
            @click="shvConnectWithUser()"
            color="primary"
          >{{ $t('sign-in') }}</v-btn>
        </v-card-text>
      </v-card-title>
    </div>
  </div>
</template>

<style scoped>
.active-link {
  background-color: #12602C
}
</style>

<script>
import Vue from 'vue'
import InputWithSwKeyboard from '../../siteware-lib/src/components/input-with-sw-keyboard.vue'

export default Vue.extend({
  name: 'alarms',
  props: {
  },

  data () {
    return {
      keyboardPosition: 'bottom',
      shvUser: '',
      shvPassword: '',
      shvURI: '',
      shvPath: '',
      svgPath: '',
      zoneName: '',
      cleanAfterSent: false
    }
  },

  components: {
    InputWithSwKeyboard
  },

  mounted () {
    this.shvUser = localStorage.getItem('userName') || ''
    this.shvPassword = localStorage.getItem('password') || ''
    this.shvURI = localStorage.getItem('shvURI') || this.$config.shvURI
    this.shvPath = localStorage.getItem('shvPath') || this.$config.shvUser
    this.zoneName = localStorage.getItem('zoneName') || this.$config.zoneName
  },

  computed: {
    inputs () {
      return this.$store.state.inputs
    }
  },

  methods: {
    async shvConnectWithUser () {
      this.shvUser = this.inputs['app.shvUsername'] || localStorage.getItem('userName') || this.$config.shvUser
      this.shvPassword = this.inputs['app.shvPassword'] || localStorage.getItem('password') || this.$config.shvPassword
      this.$tygroshka.service.connect(
        this.shvPath,
        this.shvUser,
        this.shvPassword,
        this.shvURI
      )
      console.log('setting user', this.shvUser)
      localStorage.setItem('userName', this.shvUser)
      localStorage.setItem('password', this.shvPassword)
      localStorage.setItem('shvURI', this.shvURI)
      localStorage.setItem('shvPath', this.shvPath)
      localStorage.setItem('svgPath', this.svgPath)
      localStorage.setItem('zoneName', this.zoneName)
      this.$store.commit('tygroshka/svgPath', this.svgPath)
      this.shvUser = ''
      this.shvPassword = ''
      this.loginPanel = false
      this.$router.push('/')
      // location.reload()
    }
  }
})

</script>
