export class Subscription<T> {
  constructor (private callback: (param: T) => void,
              private observers: Subscription<T>[]) {
    this.observers.push(this)
  }

  public unsubscribe (): void {
    this.observers.splice(this.observers.indexOf(this), 1)
  }

  public run (param: T): void {
    this.callback(param)
  }
}

export class EventEmitter<T> {
  private subscriptions: Subscription<T>[] = []

  public emit (param: T): void {
    this.subscriptions.forEach(subscription => subscription.run(param))
  }

  public subscribe (observer: (param: T) => void): Subscription<T> {
    return new Subscription<T>(observer, this.subscriptions)
  }

  public clearSubscriptions () {
    this.subscriptions = []
  }
}
