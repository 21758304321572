import { getInnerElement } from './tools'

export const pdG3 = (element, state) => {
  const detector = getInnerElement(element, 'detector')
  detector.setAttribute('fill-opacity', 0.75)
  if (detector) {
    if (state.get('occupied')) {
      detector.setAttribute('fill', 'yellow')
    } else if (state.get('claimed')) {
      detector.setAttribute('fill', 'orange')
    } else {
      detector.setAttribute('fill', 'white')
    }
  }
}
