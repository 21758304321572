import { getInnerElement } from './tools'
import i18n from '../../../../src/i18n'
import store from '../../services/tygroshka-store'

export const heatingG3 = (element, state) => {
  const tempAir = getInnerElement(element, 'tempAir')
  const tempMeteo = getInnerElement(element, 'tempMeteo')
  const tempRail = getInnerElement(element, 'tempRail')
  const wetConditions = getInnerElement(element, 'wetConditions')
  const heatingState = getInnerElement(element, 'heatingState')

  if ((state.get('tempAir') || state.get('tempAir') === 0) && tempAir) {
    tempAir.textContent = `${state.get('tempAir')} °C`
    tempAir.setAttribute('visibility', 'visible')
  }

  if (state.get('heatingState') && heatingState) {
    // TODO fix heating to use translations from CSV
    const translations = store.state.translations
    heatingState.textContent = translations[`type.EnumHeatingState.fields.${state.get('heatingState')}.label`]
    heatingState.setAttribute('visibility', 'visible')
  }

  if (wetConditions) {
    const humidity = state.get('wetConditions') === true ? i18n.t('general.YES') : i18n.t('general.NO')
    wetConditions.textContent = humidity
    wetConditions.setAttribute('visibility', 'visible')
  }

  if ((state.get('tempMeteo') || state.get('tempMeteo') === 0) && tempMeteo) {
    tempMeteo.textContent = `${state.get('tempMeteo')} °C`
    tempMeteo.setAttribute('visibility', 'visible')
  }

  if ((state.get('tempRail') || state.get('tempRail') === 0) && tempRail) {
    tempRail.textContent = `${state.get('tempRail')} °C`
    tempRail.setAttribute('visibility', 'visible')
  }
}
