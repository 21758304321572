<template>
  <tr :key="name">
    <TooltipTd
      :variableName="name"
      :description="description"
      :deviceType="deviceType"
      :node="node"
      >
    </TooltipTd>
    <td class="text-right">
      <span :style="{ color: node.value ? 'red' : 'black' }">
        {{ resolvedValue }}
      </span>
      <i
        v-show="booleanValue"
        :style="{ fontSize: '30px', color: alarmColor}"
        class="v-icon mdi mdi-alert-outline">
      </i>
    </td>
  </tr>
</template>

<script>
import Vue from 'vue'
import TooltipTd from '../../tooltip-td.vue'
import { mapState } from 'vuex'
import { readAttributeValue } from '../../../helpers/utils.ts'

export default Vue.extend({
  name: 'default-row',
  props: {
    name: {},
    node: {},
    types: {},
    variables: {},
    description: {},
    deviceType: {},
    unit: {}
  },

  components: {
    TooltipTd
  },

  computed: {
    ...mapState('tygroshka', [
      'styles'
    ]),

    nodeType () {
      return this.types[this.node.typeName]
    },

    booleanValue () {
      return readAttributeValue(this.node, this.name, this.variables)
    },

    resolvedValue () {
      const value = readAttributeValue(this.node, this.name, this.variables)
      if (this.node.typeName === 'boolean' || this.node.typeName === 'Bool') {
        return value ? this.$i18n.t('general.YES') : this.$i18n.t('general.NO')
      }
      if (this.unit) {
        return `${value} ${this.unit}`
      }
      return value
    },

    alarmColor () {
      const alarmColors = this.styles.visuStyle.alarm
      return this.node.alarm === 'error' ? alarmColors.error.background : alarmColors.warning.background
    }
  }
})
</script>
