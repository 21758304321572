<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12">
        <v-card>
          <v-card-title>
            Enter PIN: {{ safetyPin }}
            <v-spacer></v-spacer>
            <v-btn icon white @click="closeWindow()"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <input-with-sw-keyboard
                    label="PIN"
                    :autofocus="true"
                    :cleanAfterSent="cleanAfterSent"
                    uniqueInputName="enteredSafetyPin"
                    @onSendClick="commitSafetyRequest"
                    >
                  </input-with-sw-keyboard>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.bigger {
  font-size: 20px;
}
.oval {
  width: 200px;
  height: 100px;
  background: red;
  border-radius: 100px / 50px;
}
</style>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import InputWithSwKeyboard from '../../siteware-lib/src/components/input-with-sw-keyboard'

export default Vue.extend({
  name: 'safety-pin',
  props: {
    assetPath: {
      default: true
    }
  },

  components: {
    InputWithSwKeyboard
  },

  data () {
    return {
      cleanAfterSent: true
    }
  },

  mounted () {
  },

  computed: {
    ...mapState('tygroshka', [
      'safetyPin',
      'activeSafetyCommand',
      'activeAsset',
      'shvPath'
    ]),

    opcVersion () {
      return this.$store.state.opcVersion
    },

    inputs () {
      return this.$store.state.inputs
    }
  },

  watch: {
  },

  methods: {
    fullShvPath (relativeShvpath) {
      return `${this.shvPath}/${relativeShvpath}`
    },

    closeWindow () {
      this.$store.commit('tygroshka/safetyPin', undefined)
    },

    async commitSafetyRequest (value) {
      const { safetyManager } = this.activeSafetyCommand
      const setSafetyPinPath = `${this.shvPath}/${safetyManager}/authorization/authKeyInserted`
      await this.$tygroshka.service.sendMessage(setSafetyPinPath, 'set', value)
      this.$store.commit('tygroshka/safetyPin', undefined)
    }
  }
})
</script>
