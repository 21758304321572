import { getInnerElement } from '../tools'
import { zoneState } from '../../../helpers/property-decorators'
import store from '../../../services/tygroshka-store'

export const zoneCha = (element, state) => {
  const translations = store.state.translations
  const textElement = getInnerElement(element, 'statusText')
  if (state.get('allBlocked')) {
    textElement.textContent = 'ALL BLOCKED'
  } else if (state.get('emergencyInhibit')) {
    textElement.textContent = 'EMERGENCY'
  } else {
    textElement.textContent = 'NORMAL'
  }
}
