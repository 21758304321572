<template>
  <tr :key="name">
    <TooltipTd
      :variableName="name"
      :description="description"
      :deviceType="deviceType"
      :node="node"
      >
    </TooltipTd>
    <td v-if="editMode">
      <input-with-sw-keyboard
        :uniqueInputName="`${shvPath}/config/${name}`"
        inputType="text"
        :autofocus="true"
        :inRowEdit="editMode"
        @onSendClick="sendCommand(...arguments)"
        @onCancelClick="switchEdit()"
        >
      </input-with-sw-keyboard>
    </td>
    <td v-if="!editMode" class="text-right">
      {{ resolvedValue }}
      <v-icon v-if="writableRole" @click="switchEdit()">mdi-pencil</v-icon>
    </td>
  </tr>
</template>

<script>
import Vue from 'vue'
// import { sleep } from '../../../helpers/utils'
import InputWithSwKeyboard from '../../input-with-sw-keyboard.vue'
import { mapState } from 'vuex'
import TooltipTd from '../../tooltip-td.vue'
import { readAttributeValue } from '../../../helpers/utils.ts'

export default Vue.extend({
  name: 'default-row',
  props: {
    name: {},
    node: {},
    description: {},
    variables: {},
    fullShvPath: undefined,
    deviceType: {},
    unit: {}
  },

  data () {
    return {
      editMode: false,
      writableRole: false
    }
  },

  async mounted () {
    this.$store.commit('setInputValue', { inputName: `${this.fullPropertyPath}`, value: this.node.value })
    await this.checkWritableRole()
  },

  components: {
    TooltipTd,
    InputWithSwKeyboard
  },

  computed: {
    ...mapState('tygroshka', [
      'shvData',
      'shvPath',
      'currentAssetShvPath'
    ]),

    fullPropertyPath () {
      return `${this.shvPath}/${this.currentAssetShvPath}/${this.node.fullNodePath}`
    },

    resolvedValue () {
      const value = readAttributeValue(this.node, this.name, this.variables)
      if (typeof value === 'object') {
        return value
      }
      if (this.node.unit) {
        return `${value} ${this.node.unit}`
      }
      return value
    }
  },

  methods: {
    async sendCommand (value) {
      await this.$tygroshka.service.sendMessage(`${this.fullPropertyPath}`, 'set', value)
      this.editMode = false
    },

    switchEdit () {
      this.editMode = !this.editMode
      this.$store.commit('setKeyboard', { keyboardOn: this.editMode })
    },

    async checkWritableRole () {
      // TODO !!! unite with method in asset-detail `getRolesFromSHV`
      if (Vue.prototype.$config.userRolesEnabled) {
        const accessConverter = {
          bws: 0,
          rd: 1,
          wr: 2,
          cmd: 3,
          cfg: 4,
          srv: 5,
          ssrv: 6,
          dev: 7,
          su: 8
        }
        const currentAccess = await this.$tygroshka.service.sendMessage(
          '.broker/currentClient',
          'accessLevelForMethodCall',
          `["${this.fullPropertyPath}",
          "set"]`
        )
        this.writableRole = accessConverter[currentAccess.result().toString()] >= 2
      } else {
        this.writableRole = true
      }
    }
  }
})
</script>
