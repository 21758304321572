<template>
  <tr :key="name">
    <TooltipTd
      :variableName="name"
      :description="description"
      :deviceType="deviceType"
      :node="node"
      >
    </TooltipTd>
    <!-- <td> -->
    <!--   NUM: {{ $t(`variable.${name}`) }} -->
    <!-- </td> -->
    <td class="text-right">{{ resolvedValue }}</td>
  </tr>
</template>

<script>
import Vue from 'vue'
import TooltipTd from '../../tooltip-td.vue'
import { readAttributeValue } from '../../../helpers/utils.ts'

export default Vue.extend({
  name: 'default-row',
  props: {
    name: {},
    node: {},
    description: {},
    variables: {},
    fullShvPath: undefined,
    deviceType: {},
    unit: {}
  },

  components: {
    TooltipTd
  },

  computed: {
    resolvedValue () {
      const value = readAttributeValue(this.node, this.name, this.variables)
      const parsedValue = value && (value.mantisa || value.mantisa === 0) ? value.mantisa * 10 ** value.exponent : value
      const roundValue = Math.round(parsedValue * 100) / 100
      if (this.node.unit) {
        return `${roundValue} ${this.node.unit}`
      }
      return roundValue
    }
  }
})
</script>
