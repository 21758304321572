import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    logs: undefined,
    keyboardOn: false,
    keyboardInstance: undefined,
    inputs: {},
    externalWindow: false,
    externalWindowContent: '',
    sidebar: undefined,
    unsubscribedProperties: [],
    activeInputName: 'default',
    swVersion: process.env.PACKAGE_VERSION,
    opcVersion: process.env.VUE_APP_OPC_VERSION ? process.env.VUE_APP_OPC_VERSION : 'G2'
  },
  mutations: {
    toggleKeyboard (state) {
      // mutate state
      state.keyboardOn = !state.keyboardOn
    },
    setExternalWindow (state, val) {
      state.externalWindow = val
    },
    setExternalWindowContent (state, val) {
      state.externalWindowContent = val
    },
    hideKeyboard (state) {
      state.keyboardOn = false
    },
    sidebar (state, val) {
      state.sidebar = val
    },
    setKeyboardInstance (state, val) {
      state.keyboardInstance = val
    },
    setKeyboard (state, options) {
      const { keyboardOn, inputName } = options
      state.keyboardOn = keyboardOn
      state.activeInputName = inputName
    },
    setKeyboardProperty (state, { propertyName, value }) {
      state.keyboardInstance[propertyName](value)
    },
    setActiveInput (state, input) {
      state.inputs = { ...state.inputs, [state.activeInputName]: input }
    },
    setOpcVersion (state, opcVersion) {
      state.opcVersion = opcVersion
    },
    setInputValue (state, { inputName, value }) {
      state.inputs = { ...state.inputs, [inputName]: value }
    }
  },
  actions: {
    updateUnsubscribedProperties ({ commit, state }, { shvPath, sendMessage }) {
      const assetUnsubscribedProperties = [...this.state.unsubscribedProperties].filter((property: any) => property.match(new RegExp(`${shvPath}`, 'g')))
      for (const property of assetUnsubscribedProperties) {
        const fullPath = `${Vue.prototype.$config.shvSubscribePath}/${property}`
        sendMessage(fullPath, 'get', '0')
      }
    }
  },
  modules: {
  }
})
