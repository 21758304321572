import { getInnerElement } from '../tools'
import store from '../../../services/tygroshka-store'

export const routeCha = (element, state) => {
  const route = getInnerElement(element, 'route')
  const routelabel = getInnerElement(element, 'routelabel')
  // console.log('routef', element, state)
  const routeState = state.get('routeState')
  // console.log('routestatus', routeState)
  const setRouteAttributes = (visibility, stroke, opacity = '', labelvisibility) => {
    route.setAttribute('visibility', visibility)
    if (stroke) route.setAttribute('stroke', stroke)
    if (opacity) route.setAttribute('opacity', opacity)
    routelabel.setAttribute('visibility', labelvisibility)
  }

  switch (routeState) {
    case 'RES0':
      setRouteAttributes('visible', 'purple', '0.7', 'visible')
      break
    case 'RES1':
      setRouteAttributes('visible', '#6beb34', '0.7', 'visible')
      break
    case 'OCCPD':
    case 'OCCPD_RES1N':
    case 'OCCPD_RES2N':
      setRouteAttributes('visible', 'yellow', '0.7', 'visible')
      break
    case 'FREE':
      setRouteAttributes('hidden', '', '', 'hidden')
      break
    default:
      setRouteAttributes('hidden', '', '', 'hidden')
  }
}
