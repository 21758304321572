import { render, staticRenderFns } from "./shv-3-property-sheet.vue?vue&type=template&id=3fc7fab8&scoped=true"
import script from "./shv-3-property-sheet.vue?vue&type=script&lang=js"
export * from "./shv-3-property-sheet.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fc7fab8",
  null
  
)

export default component.exports