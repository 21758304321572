import colors from 'vuetify/es5/util/colors'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  breakpoint: {
    thresholds: {
      xs: 600,
      sm: 960,
      md: 1280,
      lg: 1600
    },
    scrollBarWidth: 24
  },
  theme: {
    themes: {
      light: {
        primary: '#12602C',
        secondary: '#4F7700',
        accent: '#004D4D',
        error: colors.red.base
      }
    }
  }
})
