export const signalSymbolG3 = (element, state, assets, options) => {
  const invertBlinking = element.getAttribute('shvblinkinvert')

  element.setAttribute('visibility', 'hidden')
  element.setAttribute('class', '')
  const symbolState = state.get('symbolState')
  if (symbolState && symbolState.toUpperCase() === 'SHINING') {
    element.setAttribute('visibility', 'visible')
  }
  if (symbolState && symbolState.toUpperCase() === 'BLINKING') {
    element.setAttribute('visibility', 'visible')
    if (invertBlinking) {
      element.setAttribute('class', 'symbol-blinking-invert')
    } else {
      element.setAttribute('class', 'symbol-blinking')
    }
  }
}
